import "chartist-plugin-tooltips";
import * as Chartist from "chartist";
import { Router } from "@angular/router";
import { NgBlockUI, BlockUI } from "ng-block-ui";
import { Component, OnInit } from "@angular/core";
import { ChartEvent, ChartType } from "ng-chartist";
import { APIService } from "../_services/api.service";
import { Subject } from "rxjs";
import { UtilsService } from "../_helpers/utils";
import { UserService } from "../../../src/app/_api/user.service";

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: "app-home",
  templateUrl: "./changelog.component.html",
  styleUrls: ["./changelog.component.css"],
})
export class ChangelogComponent implements OnInit {
  sms_queue_show = true;
  chat_queue_show = true;
  social_queue_show = true;
  currentTotalMonthYear:any;
  totalDateMonth:any;
  TotalnumberTenant:any;
  current_total_month = 'Current Year';
  current_total_month_for_tenant = 'Current Year'
  currentMonthYear:any;
  dateMonth:any;
  numberTenant:any;
  current_month = 'Current Year'
  current_month_for_tenant = 'Current Year';
  selected_Top_agent_by_number = 'Today';
  selected_Top_agent_by_Total = 'Today';
  AgentHandledCalls:any;
  TotalAgentHandledCalls:any;
  domain_data:any = '';
  productive_time: any;
  total_talk_times: any;
  disposition_data:any;
  prompt_data:any;
  total_callacks:any;
  @BlockUI("changelog") blockUIChangelog: NgBlockUI;
  availability_time: any;
  total_acw_time: any;
  public customCampaignType: Subject<any>;
  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true,
  };
  agent_camp_type: any;
  userType: any;
  tenantDashboard = false;
  agentDashboard = false;
  superVisorDashboard = false;
  superAdminDashboard = false;
  module_count: any;
  webchatQueues:any;
  dataOfUser: any;
  ExpenseschartOption: any;
  ExpenseschartOption2: any;
  HandledTimechartOption: any;
  quality_agent_count: any;
  agent_count: any;
  camp_count: any;
  queue_count: any;
  extension_count: any;
  job_count: any;
  mail_template_count: any;
  priority_number_count: any;
  prompt_count: any;
  sip_count: any;
  script_count: any;
  skill_count: any;
  team_count: any;
  crm_count: any;
  did_count: any;
  dnc_count: any;
  moh_count: any;
  outcome_template_count: any;
  breaks_count: any;
  email_camp_count: any;
  social_queues_count: any;
  chat_queues_count:any;
  ivr_count: any;
  sms_queues_count: any;
  tenant_count: any;

  agent_inbound_calls: any;
  agent_outbound_calls: any;
  Connected_Outbound_Calls:any;
  agent_total_calls: any;
  agent_total_hold_time: any;
  break_time_data: any;
  topAgentData: any;
  topHandledTimeAgent: any;
  tenantPermission:any;
  topTenantsInAgents: any;
  topTenantsInCalls: any;

  constructor(private router: Router, private apiService: APIService, private dataService: UserService,public utils: UtilsService ,) {
    this.customCampaignType = new Subject();

  }

  ngOnInit() {
    this.breadcrumb = {
      mainlabel: "Dashboard",
      links: [
        {
          name: "Account Dashboard",
          isLink: false,
          link: "#",
        },
      ],
    };
    this.dataOfUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userType = JSON.parse(localStorage.getItem("currentUser")).account_type;
    this.tenantPermission = JSON.parse(localStorage.getItem('tenantPermission'));
    this.showDashboard(this.userType);
  }

  // manage which view have to show
  showDashboard(userType) {
    switch (userType) {
      case "Admin":
        this.superAdminDashboard = true;
        this.getTenantModulesCount();
        this.topTenTenantInAgent();
        this.topTenTenantInCall();
        break;
      case "Superuser":
        this.superAdminDashboard = true;
        this.getTenantModulesCount();
        this.topTenTenantInAgent();
        this.topTenTenantInCall();
        break;
      case "Agent":
        this.agentDashboard = true;
        this.getAgentModulesCount();

        this.dataService.data.subscribe((data) => {
          if (data === "OUTBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
          } else if (data === "INBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
          }
          const params = {
            cc_agent: this.dataOfUser.agent_domain_uuid,
            data_type: ''
          };
          this.apiService.agentSupervisorCount(params).subscribe((response) => {
            if (response["Status"] === String(1)) {
              this.setAgentCounts(response["agent_calls"][0]);
            }
          });
        });
        break;
      case "Supervisor":
        this.agentDashboard = true;
        this.getAgentModulesCount();
        this.dataService.data.subscribe((data) => {
          if (data === "OUTBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
          } else if (data === "INBOUND") {
            this.agent_camp_type = data
            sessionStorage.setItem("get_agent_camp", this.agent_camp_type)
          }
          const params = {
            cc_agent: this.dataOfUser.agent_domain_uuid,
            data_type: this.agent_camp_type
          };
          this.apiService.agentSupervisorCount(params).subscribe((response) => {
            if (response["Status"] === String(1)) {
              this.setAgentCounts(response["agent_calls"][0]);
            }
          });
        });
        break;
      case "Tenant":
        this.tenantDashboard = true;
        this.getTenantModulesCount();
        this.topTenAgentByCall();
        this.topTenAgentByHandleTime();

        if(this.tenantPermission.items === undefined){
          return;
        }else{
          if(this.tenantPermission.items[4].submenu.items[2].show == 'true'){
            this.sms_queue_show = true;
          }else{
            this.sms_queue_show = false;
          }
          if(this.tenantPermission.items[4].submenu.items[4].show == 'true'){
            this.chat_queue_show = true;
          }else{
            this.chat_queue_show = false;
          }
          if(this.tenantPermission.items[10].submenu.items[0].show == 'true'|| this.tenantPermission.items[10].submenu.items[2].show == 'true' || 
          this.tenantPermission.items[10].submenu.items[3].show == 'true'){
            this.social_queue_show = true;
          } else{
            this.social_queue_show = false;
          }
        }
    }
  }

  // route to selected mmodule
  routeToselectedModule(route) {
    this.router.navigateByUrl(route);
  }

  reloadChangelog() {
    this.blockUIChangelog.start("Loading..");

    setTimeout(() => {
      this.blockUIChangelog.stop();
    }, 2500);
  }

  getSuperAdminModuleCount() {
    this.apiService.getSystemModuleCount().subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.module_count = response.object;
        this.setCounts();
      }
    });
  }

  getTenantModulesCount() {
    const param = {
      domain_uuid: this.dataOfUser.account_type ==='Superuser'? this.domain_data : this.dataOfUser.domain_uuid
    }
    this.apiService.getTenantModuleCount(param).subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.module_count = response['object'];
        this.setCounts();
      }
    });
  }

  getAgentModulesCount() {
    const params = {
      cc_agent: this.dataOfUser.agent_domain_uuid,
      data_type: ''
    };
    this.apiService.agentSupervisorCount(params).subscribe((response) => {
      if (response["Status"] === String(1)) {
        this.setAgentCounts(response["agent_calls"][0]);
      }
    });
  }

  setAgentCounts(agents_data) {
    setTimeout(()=>{
    this.agent_outbound_calls = agents_data.outbound_calls;
    this.Connected_Outbound_Calls = agents_data.connected_outbound_calls;
    this.agent_inbound_calls = agents_data.inbound_calls;
    this.agent_total_calls = agents_data.total_calls;
    this.productive_time = agents_data.productive_time;
    if(agents_data.disposition === ''){
      this.disposition_data = '00:00:00';
    }else{
      this.disposition_data = agents_data.disposition;
    }
    if(agents_data.total_hold_time === ''){
      this.agent_total_hold_time = '00:00:00';
    }else{
      this.agent_total_hold_time = agents_data.total_hold_time;
    }

    if(agents_data.break_time != ''){
      this.break_time_data = this.utils.basic_time_duration(agents_data.break_time);
    }else{
    }
    if(agents_data.availability_time != ''){
      this.availability_time = this.utils.basic_time_duration(agents_data.availability_time);
    }else{
    }
    if(agents_data.total_acw_time === ''){
      this.total_acw_time = '00:00:00';
    }else{
      this.total_acw_time = agents_data.total_acw_time;
    }
    if(agents_data.total_talk_time === ''){
      this.total_talk_times = '00:00:00';
    }else{
      this.total_talk_times = agents_data.total_talk_time;
    }

    if(agents_data.call_backs === ''){
      this.total_callacks = '0';
    }else{
      this.total_callacks = agents_data.call_backs;
    }
  })

  }

  setCounts() {
    this.tenant_count = this.module_count.tenant;
    this.agent_count = this.module_count.agent;
    this.quality_agent_count = this.module_count.quality_agent;
    this.camp_count = this.module_count.camp;
    this.queue_count = this.module_count.queue;
    this.extension_count = this.module_count.extension;
    this.job_count = this.module_count.job;
    this.mail_template_count = this.module_count.mail_template;
    this.priority_number_count = this.module_count.priority_number;
    this.prompt_count = this.module_count.prompt;
    this.sip_count = this.module_count.sip;
    this.script_count = this.module_count.script;
    this.skill_count = this.module_count.skill;
    this.team_count = this.module_count.team;
    this.crm_count = this.module_count.crm;
    this.did_count = this.module_count.did;
    this.dnc_count = this.module_count.dnc;
    this.moh_count = this.module_count.moh;
    this.outcome_template_count = this.module_count.outcome_template;
    this.breaks_count = this.module_count.breaks;
    this.email_camp_count = this.module_count.email_camp;
    this.social_queues_count = this.module_count.social_queues;
    this.chat_queues_count = this.module_count.chat_queues;
    this.ivr_count = this.module_count.ivr;
    this.sms_queues_count = this.module_count.sms;
    this.prompt_data = this.module_count.prompt;
    this.webchatQueues = this.module_count.webchat_queues
  }


  TopTenantByTotalHandledCalls(month){
    this.current_total_month = month;
    this.current_total_month_for_tenant = month;
     this.topTenTenantInCall();
  }
  topTenTenantInCall() {
    if(this.current_total_month === 'Current Year'){
      this.current_total_month = 'Current Year'
     }else if(this.current_total_month === 'January'){
      this.currentTotalMonthYear = '01';
     }else if(this.current_total_month === 'February'){
       this.current_total_month_for_tenant = 'February';
       this.currentTotalMonthYear = '02';
      }else if(this.current_total_month === 'March'){
       this.currentTotalMonthYear = '03';
      }else if(this.current_total_month === 'April'){
       this.currentTotalMonthYear = '04';
      }else if(this.current_total_month === 'May'){
       this.currentTotalMonthYear = '05';
      }else if(this.current_total_month === 'June'){
       this.currentTotalMonthYear = '06';
      }else if(this.current_total_month === 'July'){
       this.currentTotalMonthYear = '07';
      }else if(this.current_total_month === 'August'){
       this.currentTotalMonthYear = '08';
      }else if(this.current_total_month === 'September'){
       this.currentTotalMonthYear = '09';
      }else if(this.current_total_month === 'October'){
       this.currentTotalMonthYear = '10';
      }else if(this.current_total_month === 'November'){
       this.currentTotalMonthYear = '11';
      }else if(this.current_total_month === 'December'){
       this.currentTotalMonthYear = '12';
      }
      const year = new Date().getFullYear();
      this.totalDateMonth = this.currentTotalMonthYear + ',' + year;
      if(this.current_total_month === 'Current Year'){
       this.TotalnumberTenant = {
         domain_uuid: "",
         duration: String(year)
       };
      } else{
       this.TotalnumberTenant = {
         domain_uuid: "",
         duration: this.totalDateMonth
       };
      }
    this.apiService.getTopAgentByHandleTime(this.TotalnumberTenant).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topCallTenants = response['agent_calls'].map(
          obj => {
            return {
              name: obj.Tenant,
              value: obj.total_call_duration
            }
          }
        );
        this.topTenantsInCalls = topCallTenants;
        this.loadTopTenantInCalls();
      }
    });
  }
  loadTopTenantInCalls() {
    this.ExpenseschartOption2 = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: [],
      },
      // Add custom colors
      // color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557", "#48d095", "#f94557"],
        color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557","#30914D","#5499C7","#1643b5","#91ad6d","#00FFFF"],
      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },
      // Enable drag recalculate
      // calculable: true,
      // Add series
      series: [
        {
          
            name: "Browsers",
            type: 'pie',
            radius: ["50%", "70%"],
            center: ["50%", "57.5%"],
       
            label: {
              show: true,
              formatter(param) {
                // correct the percentage
                return param.name;
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
          data: this.topTenantsInCalls,
        },
        {
          name: "Browsers",
          type: 'pie',
          radius: ["50%", "70%"],
          center: ["50%", "57.5%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          
          emphasis: {
            label: {
              show: true,
              formatter: function (params) {
                        const result = new Date(params.value * 1000)
                        .toISOString()
                        .slice(11, 19);
                        return result+'\n\n'+'(' +params.percent+'%'+')'
                      },
              fontSize: 17,
              fontWeight: '500'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          labelLine: {
            show: false
          },
          data: this.topTenantsInCalls,
        },
      ],
    };
  }


  // Top 10 Tenants by Number of Agents start from here 

  TopTenantByHandledCalls(month){
   this.current_month = month;
   this.current_month_for_tenant = month;
    this.topTenTenantInAgent();
  }

  topTenTenantInAgent() {
    if(this.current_month === 'Current Year'){
     this.current_month = 'Current Year'
    }else if(this.current_month === 'January'){
     this.currentMonthYear = '01';
    }else if(this.current_month === 'February'){
      this.current_month_for_tenant = 'February';
      this.currentMonthYear = '02';
     }else if(this.current_month === 'March'){
      this.currentMonthYear = '03';
     }else if(this.current_month === 'April'){
      this.currentMonthYear = '04';
     }else if(this.current_month === 'May'){
      this.currentMonthYear = '05';
     }else if(this.current_month === 'June'){
      this.currentMonthYear = '06';
     }else if(this.current_month === 'July'){
      this.currentMonthYear = '07';
     }else if(this.current_month === 'August'){
      this.currentMonthYear = '08';
     }else if(this.current_month === 'September'){
      this.currentMonthYear = '09';
     }else if(this.current_month === 'October'){
      this.currentMonthYear = '10';
     }else if(this.current_month === 'November'){
      this.currentMonthYear = '11';
     }else if(this.current_month === 'December'){
      this.currentMonthYear = '12';
     }
     const year = new Date().getFullYear();
     this.dateMonth = this.currentMonthYear + ',' + year;
     if(this.current_month === 'Current Year'){
      this.numberTenant = {
        domain_uuid: "",
        duration: String(year)
      };
     } else{
      this.numberTenant = {
        domain_uuid: "",
        duration: this.dateMonth
      };
     }
  
    this.apiService.getTopAgentByCall(this.numberTenant ).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topAgentTenants = response['agent_calls'].map(
          obj => {
            return {
              value: Number(obj.total_agent),
              name: obj.Tenant
            }
          }
        );
        this.topTenantsInAgents = topAgentTenants;
        this.loadTopTenantInAgents();
      }
    });
  }

  loadTopTenantInAgents() {
    this.ExpenseschartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: [],
      },
      // Add custom colors
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557","#30914D","#5499C7","#1643b5","#91ad6d","#00FFFF"],
      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },
      // Enable drag recalculate
      // calculable: true,
      // Add series
      series: [
        {  
            name: "Browsers",
            type: 'pie',
            radius: ["50%", "70%"],
            center: ["50%", "57.5%"],
       
            label: {
              show: true,
              formatter(param) {
                // correct the percentage
                return param.name;
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
          data: this.topTenantsInAgents,
        },
        {
          name: "Browsers",
          type: 'pie',
          radius: ["50%", "70%"],
          center: ["50%", "57.5%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          
          emphasis: {
            label: {
              show: true,
              formatter: "{c} ({d}%)",
              fontSize: 17,
              fontWeight: '500'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          labelLine: {
            show: false
          },
          data: this.topTenantsInAgents,
        },
      ],
    };
  }
// Top 10 Tenants by Number of Agents end here

  // Top 10 Agent By Number of Handled calls start from here

  TopAgentByHandledCalls(data){
    this.selected_Top_agent_by_number = data;
    this.topTenAgentByCall();
  }

  topTenAgentByCall() {
  if(this.selected_Top_agent_by_number === 'This Week'){
    this.AgentHandledCalls ={
      domain_uuid: this.dataOfUser.domain_uuid,
      duration: "7 DAY"
    }
    }else if(this.selected_Top_agent_by_number === 'This Month'){
    this.AgentHandledCalls ={
      domain_uuid: this.dataOfUser.domain_uuid,
      duration: "30 DAY"
    }
    }else if(this.selected_Top_agent_by_number === 'Today'){
      this.AgentHandledCalls ={
        domain_uuid: this.dataOfUser.domain_uuid,
        duration: "0 DAY"
      }
    }

    this.apiService.getTopAgentByCall(this.AgentHandledCalls).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topAgentByCall = response['agent_calls'].map(
          obj => {
            return {
              value: Number(obj.total_handled_calls),
              name: obj.agent
            }
          }
        );
        this.topAgentData = topAgentByCall;
        this.renderTopAgentChart();
      }
    });
  }

  renderTopAgentChart() {
    this.ExpenseschartOption = {
      legend: {
        orient: "horizontal",
        x: "center",
        data: ['']
        // data: [this.topAgentData[0]["agent"], this.topAgentData[1]["agent"]],
      },

      // Add custom colors
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557","#30914D","#5499C7","#1643b5","#91ad6d","#00FFFF"],
      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },

      // Add series
      series: [
        {
            name: "Browsers",
            type: 'pie',
            radius: ["50%", "70%"],
            center: ["50%", "51.5%"],
       
            label: {
              show: true,
              formatter(param) {
                // correct the percentage
                return param.name;
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
          data: this.topAgentData
        },
        {
          name: "Browsers",
          type: 'pie',
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          
          emphasis: {
            label: {
              show: true,
              formatter: "{c} ({d}%)",
              fontSize: 17,
              fontWeight: '500'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          labelLine: {
            show: false
          },
          data: this.topAgentData
        },
      ],
    };
  }
  // Top 10 Agent By Number of Handled calls end here


  // Top 10 Agent By Total Handle Time start from here

  TopAgentByTotalHandledCalls(data){
    this.selected_Top_agent_by_Total = data;
    this.topTenAgentByHandleTime();
  }
  topTenAgentByHandleTime() {
    if(this.selected_Top_agent_by_Total === 'This Week'){
      this.TotalAgentHandledCalls ={
        domain_uuid: this.dataOfUser.domain_uuid,
        duration: "7 DAY"
      }
      }else if(this.selected_Top_agent_by_Total === 'This Month'){
      this.TotalAgentHandledCalls ={
        domain_uuid: this.dataOfUser.domain_uuid,
        duration: "30 DAY"
      }
      }else if(this.selected_Top_agent_by_Total === 'Today'){
        this.TotalAgentHandledCalls ={
          domain_uuid: this.dataOfUser.domain_uuid,
          duration: "0 DAY"
        }
      }
    this.apiService.getTopAgentByHandleTime(this.TotalAgentHandledCalls).subscribe((response) => {
      if (response["Status"] === String(1)) {
        let topAgentByHandleTime = response['agent_calls'].map(
          obj => {
            return {
              value: Number(obj.total_call_duration),
              name: obj.agent
            }
          }
        );
        this.topHandledTimeAgent = topAgentByHandleTime;
        this.renderTopHandledTimeAgent();
      }
    });
  }
  renderTopHandledTimeAgent() {
    this.HandledTimechartOption = {

      // Add custom colors
      // color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557"],
      color: ["#1e9ff2", "#fd9148", "#48d095", "#ba68c8", "#f94557","#30914D","#5499C7","#1643b5","#91ad6d","#00FFFF"],

      // Display toolbox
      toolbox: {
        show: true,
        orient: "vertical",
      },
      // Enable drag recalculate
      // calculable: true,

      // Add series
      series: [
       
        {
          name: "Browsers",
          type: 'pie',
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],
     
          label: {
            show: true,
            formatter(param) {
              // correct the percentage
              return param.name;
            }
          },        
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
          data: this.topHandledTimeAgent
        },
        {
          name: "Browsers",
          type: 'pie',
          radius: ["50%", "70%"],
          center: ["50%", "51.5%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          
          emphasis: {
            label: {
              show: true,
              formatter: function (params) {
                        const result = new Date(params.value * 1000)
                        .toISOString()
                        .slice(11, 19);
                        return result+'\n\n'+'(' +params.percent+'%'+')'
                      },
              fontSize: 17,
              fontWeight: '500'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          labelLine: {
            show: false
          },
          data: this.topHandledTimeAgent
        },
      ],
    };
  }

  // Top 10 Agent By Total Handle Time end here
}
