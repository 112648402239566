import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class APIService {

    API_URL = environment.API_URL;
    SMS_URL = environment.SMS_URL;
    AGENT_QUEUE_URL = environment.AGENT_QUEUE_URL;
    TELEGRAM_BASE_URL = environment.TELEGRAM_BASE_URL;
    VIBER_BASE_URL = environment.VIBER_BASE_URL;
    SOCIAL_MEDIA_URL = environment.SOCIAL_MEDIA_URL;
    TWITTER_BASE_URL = environment.TWITTER_BASE_URL;
    WHATSAPP_BASE_URL = environment.WHATSAPP_BASE_URL;
    FACEBOOK_BASE_URL = environment.FACEBOOK_BASE_URL;

    constructor(
        private httpClient: HttpClient
    ) { }
    externalCRMUrl(data){
        return this.httpClient.post(`https://apps.cexlabapps.com:20707/nexus-url`, data);
    }
    externalCRM(data){
        return this.httpClient.get(`https://www.nexusdmc.com/ivr-lookup?query=${data}`);
    }
    login(data) {
        return this.httpClient.post(`${this.API_URL}account/login/`, data);
    }
    uploadProfilePic(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}upload-profile-pic`,data);
    }
    removeProfilePic(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}remove-profile-pic`,data);
    }
    getAgentRole(data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-roles-by-role-uuid/${data}`);
    }
    updatePassword(data) {
        return this.httpClient.post(`${this.API_URL}tenant/update_password/`, data);
    }
    change_password(data) {
        return this.httpClient.post(`${this.API_URL}tenant/change_password`, data);
    }
    forgetPassword(data) {
        return this.httpClient.post(`${this.API_URL}tenant/forgot_password`, data);
    }
    otpVerify(data) {
        return this.httpClient.post(`${this.API_URL}tenant/verify_otp`, data);
    }
    resendOtp(data) {
        return this.httpClient.post(`${this.API_URL}tenant/resend_otp`, data);
    }
    changePassword(data, module) {
        return this.httpClient.post(`${this.API_URL}tenant/change_password/${data}`, module);
    }
    addTenant(tenantData) {
        return this.httpClient.post(`${this.API_URL}tenant/Add_tenant/`, tenantData);
    }
    getTenantList() {
        return this.httpClient.get<any>(`${this.API_URL}getall_tenant/`);
    }
    // getTenantList(param) {
    //     return this.httpClient.post(`${this.API_URL}getall_tenant/`,param);
    // }
    EnabledTenant(tenantData) {
        return this.httpClient.put(`${this.API_URL}tenant/tenant-active-deactive`, tenantData);
    }
    getSystemModuleCount() {
        return this.httpClient.get<any>(`${this.API_URL}admin/summary`);
    }
    updateAgentLoginSession(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-login-session/`, agentData);
    }
    updateTanent(updatedTanentData, tenant_id) {
        return this.httpClient.post(`${this.API_URL}tenant/update_tenant/${tenant_id}`, updatedTanentData);
    }
    deleteTenant(payload) {
        return this.httpClient.post(`${this.API_URL}tenant_delete_tenant`, payload);
    }
    addAgent(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-agent/`, agentData);
    }
    showAvailableAgent(tierData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-only-available-agent/`, tierData);
    }
    showAgentDetailByQueuqUUID(requestData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agents-in-camp/`, requestData);
    }
    deleteTiers(tierData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-tiers/`, tierData);
    }
    createTiers(tierData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-tiers/`, tierData);
    }
    deleteAgent(agent_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-agent-details-by-uuid/`, agent_id);
    }
    showAgentList(requestData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-list/`, requestData);
    }
    updateAgent(agentUpdatedData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-agent-details-by-uuid/`, agentUpdatedData);
    }
    getAgentById(queueUUID) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-details-by-uuid/`, { call_center_agent_uuid: queueUUID });
    }
    createQueue(queueData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-queue/`, queueData);
    }
    listQueues(tenantid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-list/`, tenantid);
    }
    QueuesListwithCampuuid(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/camp-based-queue-list/`, param); 
    }
    deleteQueues(tenantid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-queue-details-by-uuid/`, tenantid);
    }
    getQueueDetailByUuid(queue_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-details-by-uuid/`, { call_center_queue_uuid: queue_uuid });
    }
    editQueueByuuId(queue_updated_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-queue-details-by-uuid/`, queue_updated_data);
    }
    createExtension(extensionData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-extension/`, extensionData);
    }
    deleteExtension(requestedData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-extension/`, requestedData);
    }
    editExtension(extension_updated_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-extension/`, extension_updated_data);
    }
    getExtensionList(tanent_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-extensions-list/`, tanent_id);
    }
    onlyAvailableExtensionList(tanent_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-only-available-extensions/`, tanent_data);
    }
    createExtensionWithRange(extension_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-extension-in-range/`, extension_data);
    }
    createDid(newDidData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-DID/`, newDidData);
    }
    createBulkDid(dids_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-DID-with-file/`, dids_data);
    }
    deleteDid(request_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-DID/`, request_data);
    }
    updateDid(updated_did_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-DID/`, updated_did_data);
    }
    getAllDid(tenant_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-did-list/`, tenant_id);
    }
    createTicket(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-ticket/`, ticket_data);
    }
    ticketList(doamin_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-ticket-list/`, doamin_uuid);
    }
    ticketUpdate(ticketData) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-ticket/`, ticketData);
    }
    deleteTicket(deleted_ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-ticket/`, deleted_ticket_data);
    }
    getTicketComment(request_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/get-comments-on-ticket/`, request_data);
    }
    addCommentOnTicket(ticket_commment_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/add-comment-on-ticket/`, ticket_commment_data);
    }
    //new ticket
    createTickets(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-ticket`, ticket_data);
    }
    ticketCreates(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-create`, ticket_data);
    }
    getTicketByAgent(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-created-ticket-by-agent-uuid`, ticket_data);
    }
    closeTicket(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}close-ticket`, ticket_data);
    }
    openTicketChart(ticket_data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-top-open-agents`, ticket_data);
    }
    closeTicketChart(ticket_data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-top-closed-agents`, ticket_data);
    }
    yearCategory(ticket_data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-by-year`, ticket_data);
    }
    ticketUpdateAtCall(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-ticket-auto`, ticket_data);
    }
    //Category
    createcategoryList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-category-ticket`, ticket_data);
    }
    getcategoryList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-category-ticket`, ticket_data);
    }
    deletecategoryList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-category-ticket`, ticket_data);
    }
    // Due
    createdueList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-due-ticket`, ticket_data);
    }
    getdueList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-due-ticket`, ticket_data);
    }
    deletedueList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-due-ticket`, ticket_data);
    }
    updatedueList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-due-ticket`, ticket_data);
    }
    // Priority
    createpriorityList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-priority-ticket`, ticket_data);
    }
    getpriorityList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-priority-ticket`, ticket_data);
    }
    deletepriorityList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-priority-ticket`, ticket_data);
    }
    updatepriorityList(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-priority-ticket`, ticket_data);
    }




    //inbound emails Inbox

    getAllInboxEmail(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-inbox-email-by-agent`, agentData);
    }



    // All Ticket List Count
    getTicketListCount(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-count`, ticket_data);
    }

    getTicketListAgent(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-get`, ticket_data);
    }
    
    getTicketListRequestAgent(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}list-requests`, ticket_data);
    }
    getTicketModuleCount(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-ticket-module-count`, ticket_data);
    }
    getDueCount(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-due-today`, ticket_data);
    }
    closeTickets(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-close`, ticket_data);
    }
    transferTickets(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}request-transfer`, ticket_data);
    }
    categoryChangeRequestTickets(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}request-category-change`, ticket_data);
    }
    requestActionTickets(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}request_action`, ticket_data);
    }
    assignAgentToUnrespondedTickets(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}assign_agent_to_unresponded_tickets`, ticket_data);
    }
    viewAllTicketHistory(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}view-ticket-details`, ticket_data);
    }

    // Email chat
    getAllEmail(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-emails`, ticket_data);
    }
    replyEmail(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}reply-ticket-email`, ticket_data);
    }
    favouriteEmail(ticket_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}favourite-emails`, ticket_data);
    }
    getEmailMessages(thread,agent) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-email/${thread}/${agent}`);
    }
    uploadAttachedFile(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}uploadFile`, params);
    }
    deleteAttachedFile(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteFile`, params);
    }
    

    //support
    getSupportTicktConversation(ticket_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-support-ticket-conversation`,ticket_id);
    }
    supportTicketReply(ticket_id) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}support-ticket-reply`,ticket_id);
    }
    
    closeSupportTicket(ticket_id) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}close-support-ticket`,ticket_id);
    }
    

    //api for ticket on agent side



    // new ticket end


    //webchat api start
    getwebchathistory(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-webchat-history`, chat_data);
    }
    //webchat campaign
    addWebchatcampaign(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-web-chat-campaign`, chat_data);
    }
    getWebchatcampaign(chat_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-web-chat-campaign/${chat_data}`);
    }
    deleteWebchatcampaign(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-web-chat-campaign`, chat_data);
    }
    updateWebchatcampaign(chat_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-web-chat-campaign`, chat_data);
    }
    updateWebchatcampaignStatus(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-web-chat-campaignstatus`, chat_data);
    }
    // WebChat configuration
    addWebchatConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-webchat-configuration`, chat_data);
    }
    getWebchatConfig(chat_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-webchat-configuration/${chat_data}`);
    }
    deleteWebchatConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-webchat-configuration`, chat_data);
    }
    updateWebchatConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}edit-webchat-configuration`, chat_data);
    }
    updateWebchatStatusConfig(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-web-chat-confstatus`, chat_data);
    }

    //webchat queue
    addWebchatqueue(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-webchat-queue`, chat_data);
    }
    getWebchatqueue(chat_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-webchat-queue-list/${chat_data}`);
    }
    deleteWebchatqueue(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-webchat-queue`, chat_data);
    }
    updateWebchatqueue(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-webchat-queue`, chat_data);
    }
    updateWebchatqueueStatus(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-web-chat-queuestatus`, chat_data);
    }
    
    // Web chat for agent
    getAssignedWebChatQueue(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-webchat-queue-list-unassigned`, data);
    }
    getWebchatContact(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-webchat-contact-list`,chat_data);
    }
    sendWebchatMsg(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}send-webchat-message`,chat_data);
    }
    receiveWebchatMsg(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-webchat-messages`,chat_data);
    }
    sendMediaFile(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}send-webchat-media-message`, data);
    }


    //webchat-bot
    addWebchatbot(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-chatbot-case`, chat_data);
    }
    getWebchatbot(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-chatbot-case`,chat_data);
    }
    deleteWebchatbot(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-chatbot-case`, chat_data);
    }
    updateWebchatbot(chat_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-chatbot-case`, chat_data);
    }
    closeWebchat(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}close-webchat`, chat_data);
    }
    disposeWebchat(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}fill-webchat-disposition`, chat_data);
    }

    // Web chat Subscription
    addWebchatbotSubscription(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-chatbot-subscription`, chat_data);
    }
    getWebchatbotSubscription() {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-chatbot-subscription`);
    }
    deleteWebchatbotSubscription(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-chatbot-subscription`, chat_data);
    }
    updateWebchatbotSubscription(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-chatbot-subscription`, chat_data);
    }
    statusWebchatbotSubscription(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}status-chatupdate-subscription`, chat_data);
    }

    // webchat apis end


    addBreak(break_data) {
        return this.httpClient.post(`${this.API_URL}addBreak`, break_data);
    }
    getBreakList(tenant_data) {
        return this.httpClient.post(`${this.API_URL}getBreakList`, tenant_data);
    }
    deleteBreak(break_data) {
        return this.httpClient.post(`${this.API_URL}deleteBreakById`, break_data);
    }
    updateBreak(updated_break_data) {
        return this.httpClient.put(`${this.API_URL}updateBreakById`, updated_break_data);
    }
    applyBreak(break_applied_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-agent-status/`, break_applied_data);
    }
    removeBreak(agent_data) {
        return this.httpClient.put(`${this.API_URL}v1/callCenter/update-agent-break-state/`, agent_data);
    }
    requestBreak(agent_data) {
        return this.httpClient.post(`${this.API_URL}requestBreak`, agent_data);
    }
    applyAgentBreak(agent_data) {
        return this.httpClient.post(`${this.API_URL}assignAgentBreak`, agent_data);
    }
    checkAgentRequestedBreak(requested_data) {
        return this.httpClient.post(`${this.API_URL}checkAgentRequestedBreak`, requested_data);
    }
    addDisposition(diapostion_data) {
        return this.httpClient.post(`${this.API_URL}addDisposition`, diapostion_data);
    }
    getDispositionList(disposition_data) {
        return this.httpClient.get(`${this.API_URL}getDispositionList/${disposition_data}`);
    }
    deleteDispositionlist(disposition_uuid) {
        return this.httpClient.delete(`${this.API_URL}deleteDispositionById//${disposition_uuid}`);
    }
    updateDisposition(updated_disposition_data) {
        return this.httpClient.put(`${this.API_URL}updateDispositionById`, updated_disposition_data);
    }
    addSkill(skill_data) {
        return this.httpClient.post(`${this.API_URL}addSkill`, skill_data);
    }
    getSkillList(request_data) {
        return this.httpClient.post(`${this.API_URL}getSkillList`, request_data);
    }
    deleteSkillById(skill_id) {
        return this.httpClient.post(`${this.API_URL}deleteSkillById`, skill_id);
    }
    updateSkill(updated_skill_data) {
        return this.httpClient.put(`${this.API_URL}updateSkillById`, updated_skill_data);
    }
    addCampaign(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addCampaign`, campaign_data);
    }
    getCampaignList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaignList`, tenant_data);
    }
    getAllCampaignList(uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-all-campaigns-by-type`, uuid);
    }
    deleteCampaignById(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteCampaignById`, campaign_data);
    }
    updateCampaign(updated_campaign_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updateCampaign`, updated_campaign_data);
    }
    getCampaignCdr(updated_campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-camp-calls-history-by-activity-uuid/`, updated_campaign_data);
    }
    showQueueStatusList(tenent_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-status-list/`, tenent_uuid);
    }
    showAgentSessionMasterList(tenent_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-session-master-list/`, tenent_uuid);
    }
    createSipTrunk(trunk_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-sip-trunk/`, trunk_data);
    }
    sipTrunkList(requested_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-sip-trunk-list/`, requested_data);
    }
    deleteSipTrunk(requested_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-sip-trunk/`, requested_data);
    }
    updateSipTrunk(updatedTrunkData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-sip-trunk/`, updatedTrunkData);
    }
    TrunkForFlag(trunk_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}uac-flag-update`, trunk_uuid);
    }

    getDncList(requestedData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getDncList`, requestedData);
    }
    addDnc(dncData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addDnc`, dncData);
    }
    updateDnc(UpdatedncData) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-dnc`, UpdatedncData);
    }
    updateBlacklist(customer_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-block-number`, customer_data);
    }
    deleteDncById(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteDncById`, params);
    }
    addDncWithFile(dnc_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addDncWithFile`, dnc_data);
    }
    createCustomer(ticket_data) {
        return this.httpClient.post(`${this.API_URL}CRM/Add_CRM/`, ticket_data);
    }
    getAllCustomer(tenant_data) {
        return this.httpClient.get(`${this.API_URL}getall_CRM/${tenant_data}`);
    }
    deleteCustomer(doamin_uuid) {
        return this.httpClient.delete(`${this.API_URL}CRM_delete_CRM/${doamin_uuid}`);
    }
    modifyCustomer(modify_customer_id, updated_customer_data) {
        return this.httpClient.post(`${this.API_URL}CRM/update_CRM/${modify_customer_id}`, updated_customer_data);
    }
    importBulkCustomer(import_customers_data) {
        return this.httpClient.post(`${this.API_URL}CRM/import_crm_by_file/`, import_customers_data);
    }
    searchCustomer(searched_customer) {
        return this.httpClient.get(`${this.API_URL}search_crm/${searched_customer}`);
    }
    createBulkCustomer(customer_data) {
        return this.httpClient.post(`${this.API_URL}import_crm_by_file`, customer_data);
    }
    getListOfCRM(id: string): Observable<any> {
        return this.httpClient.get(`${this.API_URL}show-all-crms/${id}`);
    }
    updateCRM(params: any): Observable<any> {
        return this.httpClient.post(`${this.API_URL}CRM/update-crm/`, params);
    }
    newCRM(params: any): Observable<any> {
        return this.httpClient.post(`${this.API_URL}CRM/create-crm`, params);
    }
    getCRMById(id: string): Observable<any> {
        return this.httpClient.get(`${this.API_URL}CRM/get-crm-by-uuid/${id}`);
    }
    getSocial(url: string): Observable<any> {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}${url}`);
    }
    postAgent(url: string, params): Observable<any> {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}${url}`, params);
    }
    getCrmDumpByCamppUuid(camp_uuid) {
        return this.httpClient.get(`${this.API_URL}CRM/get-crm-details-by-camp-uuid/${camp_uuid}`);
    }
    getCrmDumpByGroupUuid(group_uuid) {
        return this.httpClient.get(`${this.API_URL}CRM/get-crm-details-by-group-uuid/${group_uuid}`);
    }
    getCreatedCustomerData(domain_uuid) {
        return this.httpClient.get(`${this.API_URL}CRM/get-customer-database-details-by-domain-uuid/${domain_uuid}`);
    }
    getCustomer(param) {
        return this.httpClient.post(`${this.API_URL}CRM/get-customer-database-details-by-contactno/`, param);
    }
    getCustomerScript(url: string): Observable<any> {
        return this.httpClient.get(`${this.API_URL}${url}`);
    }
    sendAttachmentInWhatsapp(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}reply-media-message`, params);
    }
    getScriptForCall(scriptID){
        return this.httpClient.get(`${this.API_URL}GetScriptPageList/${scriptID}`);
    }
    putCustomer(url: string, params: any): Observable<any> {
        return this.httpClient.put(`${this.API_URL}${url}`, params);
    }
    postCustomer(url: string, params: any): Observable<any> {
        return this.httpClient.post(`${this.API_URL}${url}`, params);
    }
    putAgent(url: string, params): Observable<any> {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}${url}`, params);
    }
    getAgent(url: string): Observable<any> {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}${url}`);
    }
    getScriptDump(camp_uuid) {
        return this.httpClient.get(`${this.API_URL}getScriptDumpDetailByCampUuid/${camp_uuid}`);
    }
    agentFeedbackDetail(script_id) {
        return this.httpClient.get(`${this.API_URL}get-agent-feedback-details-by-script_id/${script_id}`);
    }
    getCampaignOutcome(param) {
        return this.httpClient.post(`${this.API_URL}getOutComeCallDetailsByCampUuid`, param);
    }
    getOutComeLevelListByGroupId(tenant_uuid, group_id) {
        return this.httpClient.get(`${this.API_URL}getOutComeLevelListByGroupId/${tenant_uuid}/${group_id}`);
    }
    getOutComeLevelListByLevel(request_data) {
        return this.httpClient.post(`${this.API_URL}getOutComeLevelListByLevel`, request_data);
    }
    importAtrtributes(typification_data) {
        return this.httpClient.post(`${this.API_URL}importAttributesByDomainUuid`, typification_data);
    }
    tenantDashBoardData(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-dashboard-data/`, tenant_data);
    }
    agentDashBoardData(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-calls-dashboard-data/`, tenant_data);
    }

    // Email Dashboard start
    getEmailDashboardData(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-dashboard`, params);
    }
    getAvailableAgentDataForEmail(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-agent-data`, params);
    }
    

    // Blended Dashboard start
    blendedDashboardAgentStatus(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}campaign-agent-status`, data);
    }
    blendedDashboardDetails(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}blended-campaign-dashboard`, data);
    }
    // Blended Dashboard end
    
    emailDashboardData(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-dashboard`, data);
    }
    mediaDashBoardData(data) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}social-media-dashboard`, data);
    }

    QueueForMediaDashboard(data){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-chats-in-queue`, data);
    }
    LiveChatForMediaDashboard(data){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}live-chats`, data);
    }
    getMediaAgentDetailsLive(data){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-agent-in-campaign-team`, data);
    }
    getLiveChats(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}live-chats`, params);
    }
    getChatsInQueue(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-chats-in-queue`, params);
    }
    // Social Media dashboard end 

    showPromptList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-prompt-list/`, tenant_data);
    }
    createPrompt(prompt_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-prompt-with-file/`, prompt_data);
    }
    detetePrompt(prompt_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-prompt/`, prompt_data);
    }
    updatePrompt(prompt_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-prompt-with-file/`, prompt_data);
    }
    getJobList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getJobList`, tenant_data);
    }
    addJob(job_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addJob`, job_data);
    }
    updateJob(job_updated_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}updateJob`, job_updated_data);
    }
    deleteJob(job_data) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}deleteJobById/${job_data}`);
    }
    updateJobStatus(job_currrent_status_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-job-status/`, job_currrent_status_data);
    }
    resetJobStatus(job_reset_status_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}reset-job-status/ `, job_reset_status_data);
    }
    importGroupDataByJobId(import_job_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}import-group-data-by-job-id`, import_job_data);
    }
    addGroup(group_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addGroup`, group_data);
    }
    editGroup(group_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}editGroup`, group_data);
    }
    // getGroupList(tenant_data) {
    //     return this.httpClient.get(`${this.AGENT_QUEUE_URL}getGroupList/${tenant_data}`);
    // }
    getGroupList(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getGroupList`, tenant_data);
    }
    getGroupContacts(group_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getGrpDetailById/${group_uuid}`);
    }
    deleteGroup(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteGroupById`, params);
    }
    addGroupWithFile(group_numbers_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addGroupWithFile`, group_numbers_data);
    }
    deleteContact(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteGroupDetailsById`, params);
    }
    addSingleContct(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addToGroup`, params);
    }
    editGroupDetails(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}editGroupDetails`, params);
    }
    getTanentCallStaticsList(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getTanentCallStaticsList/${tenant_data}`);
    }
    getTanentCompletedCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-tanent-completed-call/${tenant_data}`);
    }
    getTanentTotalCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-tanent-total-call/${tenant_data}`);
    }
    getTanentAbandonedCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-tanent-abandoned-call/${tenant_data}`);
    }
    tenantInBoundOutBoundCall(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-inbound-outbound-total-call/${tenant_data}`);
    }
    getAgentCallStaticsList(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getCallStaticsList/${agent_data}`);
    }
    getAgentCompletedCall(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-completed-call/${agent_data}`);
    }
    getAgentTotalCall(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-total-call/${agent_data}`);
    }
    getAgentAbandonedCall(agent_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-abandoned-call/${agent_data}`);
    }
    getIvrList(tenant_data) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getIvrList/${tenant_data}`);
    }
    addIvr(group_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addIvr`, group_data);
    }
    availableIVR(domain_uuid){
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}ivr-for-camp/${domain_uuid}`);
    }
    notAssociatedIvrList(domain_uuid,camp_uuid){
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getIvrList/${domain_uuid}/${camp_uuid}`)
    }
    notAssociatedQueueList(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-list-camp/`, params)
    }
    deleteIvr(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteIvrById`, param);
    }
    updateIvrMenu(updated_ivr_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-ivr-by-ivr_menu_uuid`, updated_ivr_data);
    }
    addIvrOptionMenu(ivr_menu_option) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-ivr-option-menu`, ivr_menu_option);
    }
    getIvrMenuOptions(ivr_menu_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-ivr-option-menu-details-by-ivr-menu_uuid/${ivr_menu_uuid}`);
    }
    deleteIVROption(ivr_menu_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}deleteIvrOptionById/${ivr_menu_uuid}`);
    }
    updateIVROption(updated_option_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-ivr-option-by-ivr_menu_option_uuid`, updated_option_data);
    }
    getallAppUserId(tenent_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getall_appUserId/${tenent_uuid}`);
    }
    getAllMessageOfContact(app_id, contact_id) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_allMessage_byAppUserId/${app_id}/${contact_id}`);
    }
    send_message_to_customer(app_id, contact_id, message_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}post_message/${app_id}/${contact_id}`, message_data);
    }
    send_attachment_to_customer(app_id, contact_id, attachment) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}upload_attachments/${app_id}/${contact_id}`, attachment);
    }
    configurSocailPlateform(configuration_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}add_smoochConfiguration`, configuration_data);
    }
    getSmoochConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_smoochConfiguration/${tenant_uuid}`);
    }
    deleteSmoochConfiguration(id, tenant_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_smoochConfiguration/${id}/${tenant_uuid}`);
    }
    updateSocailPlateform(id, tenant_uuid, updated_configuration_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_smoochConfiguration/${id}/${tenant_uuid}`, updated_configuration_data);
    }
    getFacebookConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_facebookConfiguration/${tenant_uuid}`);
    }
    getTwitterConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.TWITTER_BASE_URL}get_twitter_configuration/${tenant_uuid}`);
    }
    addTwitterConfiguration(twitter_config_data) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}add_twitter_configuration`, twitter_config_data);
    }
    deleteTwitterConfiguration(row_id, tenant_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_twitter_configuration/${row_id}/${tenant_uuid}`);
    }
    updateTwitterConfiguration(row_id, tenant_uuid, updated_twitter_config_data) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}update_twitter_configuration/${row_id}/${tenant_uuid}`, updated_twitter_config_data);
    }
    addFacebookConfiguration(facebook_config_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}add_facebookConfiguration`, facebook_config_data);
    }
    deleteFacebookConfiguration(facebook_id, tenant_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_facebookConfiguration/${facebook_id}/${tenant_uuid}`);
    }
    updateFacebookConfiguration(facebook_id, tenant_uuid, updated_facebook_config_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_facebookConfiguration/${facebook_id}/${tenant_uuid}`, updated_facebook_config_data);
    }
    getFacebookAccounts(tenant_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_facebook_auth_list/${tenant_uuid}`);
    }
    getFacebookWall(tenant_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}show-facebook-application/${tenant_uuid}`);
    }
    addFacebookAccount(fb_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}save_facebook_auth`, fb_account_data);
    }
    addFacebookWall(fb_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}add-facebook-application `, fb_account_data);
    }
    deleteFacebookAccount(flac_uuid, domain_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete-facebook-application/${flac_uuid}/${domain_uuid}`);
    }
    // start facebook api 
    createFacebookBot(params){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}add-chatbot-case`, params);
    }
    updateFacebookBot(params) {
        return this.httpClient.put(`${this.FACEBOOK_BASE_URL}update-chatbot-case`, params);
    }
    createFacebookTemplate(params){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}save-template`, params);
    }
    updateFacebooktemplate(params) {
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}update-template`, params);
    }
    deleteFacebookBot(params){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}delete-chatbot-case`, params);
    }
    getFacebookBot(domain_uuid){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}get-chatbot-case`,domain_uuid);
    }
    StatusUpdateFacebookBotSubscription(param){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}status-update`,param);
    }
    deleteFacebookBotSubscription(param){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}delete-Bot`,param);
    }
    getFacebookBotSubscription(){
        return this.httpClient.get(`${this.FACEBOOK_BASE_URL}get-bot`)
    }
    createFacebookBotSubscription(param){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}add-bot`,param)
    }
    updateFacebookBotSubscription(param){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}update-bot`,param)
    }
    getTenantListForFacebookBot(){
        return this.httpClient.get(`${this.API_URL}getTenantList`)
    }
    sendAttachmentInFacebook(params){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}send-media-message`, params);
    }
    // end facebook apis
    disableChatUser(chat_user_id) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}disable_appUser/${chat_user_id}`);
    }
    showAllCallForTenant(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-all-calls-for-tanent/`, tenant_data);
    }
    showQueueCalls(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-queue-calls/`, tenant_data);
    }
    showAgentCalls(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-calls/`, tenant_data);
    }
    showAllCallsOfAgent(agent_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-all-calls-for-an-agent/`, agent_data);
    }
    createNewMoh(new_moh_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-moh/`, new_moh_data);
    }
    editMoh(new_moh_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-moh/`, new_moh_data);
    }
    getMohGroup(tenant_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-moh-group/`, tenant_data);
    }
    getMohGroupData(request_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-moh-group-data/`, request_data);
    }
    deleteMohGroup(moh_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-moh-group/`, moh_data);
    }
    deleteMohGroupData(moh_group_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-moh-group-data/`, moh_group_data);
    }
    addScript(script_data) {
        return this.httpClient.post(`${this.API_URL}addScript`, script_data);
    }
    getScriptList(tenant_data) {
        return this.httpClient.post(`${this.API_URL}getScriptList`, tenant_data);
    }
    deleteScriptById(script_id) {
        return this.httpClient.delete(`${this.API_URL}deleteScriptById/${script_id}`);
    }
    updateScriptForm(params) {
        return this.httpClient.post(`${this.API_URL}addScriptDump`, params);
    }
    agentSupervisorCount(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-dashboard/`, params);
    }
    getScriptForm(params) {
        return this.httpClient.post(`${this.API_URL}getScriptDumpDetailById`, params);
    }
    uploadFile(params) {
        return this.httpClient.post(`${this.API_URL}uploadScriptGallery`, params);
    }
    getFiles(id: string) {
        return this.httpClient.get(`${this.API_URL}getScriptFileListByUuid/${id}`);
    }
    getScriptDetailsById(script_id) {
        return this.httpClient.get(`${this.API_URL}getScriptDetailById/${script_id}`);
    }
    updateScript(script_data) {
        return this.httpClient.put(`${this.API_URL}updateScriptById`, script_data);
    }
    getCustomerCallHistory(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-customer-calls-history-by-number/`, params);
    }
    getAvailableCampaignForScript(tenant_uuid) {
        return this.httpClient.get(`${this.API_URL}getAvailableCampaignNotWithScript/${tenant_uuid}`);
    }
    getTicketCamp(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-campaign-ticket`, chat_data);
    }
    updateTeam(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-supervisor-team/`, data);
    }
    createTeam(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-supervisor-team/`, team_data);
    }
    showTeams(tenant_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-supervisor-teams/`, tenant_uuid);
    }
    deleteTeam(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/remove-supervisor-team/`, team_data);
    }
    showTeamMembers(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-supervisor-team-tiers/`, team_data);
    }
    asigneSupervisorTeam(team_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/add-agent-to-supervisor-team/`, team_data);
    }
    removeTeamMember(remove_member_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/remove-agent-from-supervisor-team/`, remove_member_data);
    }
    supervisorTeamMember(supervisor_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-supervisor-team-for-supervisor/`, supervisor_data);
    }
    showAvailableSupervisorAgent(supervisor_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-available-supervisors-or-agents/`, supervisor_data);
    }
    getChatQueueList(queue_name) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_queue_list/${queue_name}`);
    }
    getSocialChatQueueList(supervisor_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/camp-based-social-queue-list/`, supervisor_data);
    }
    createSoicalQueue(chat_queue_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}create_queue`, chat_queue_data);
    }
    asigneAgentToChatQueue(agent_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}assign_agent_to_queue`, agent_data);
    }
    agentsInSocialQueue(queue_info) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_assigned_agent_list/${queue_info}`);
    }
    removeAgentFromChatQueue(agent_info) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}remove_agent_from_queue/${agent_info}`);
    }
    deleteChatQueue(queue_info) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-chatqueue`, queue_info);
    }
    updateChatQueue(queue_uuid, updated_queue_info) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_queue/${queue_uuid}`, updated_queue_info);
    }
    addOutComeGroup(outcome_group_data) {
        return this.httpClient.post(`${this.API_URL}addOutComeGroupByDomainUuid`, outcome_group_data);
    }
    addOutComeAttributes(outcome_attribute_data) {
        return this.httpClient.post(`${this.API_URL}addAttributesByDomainUuid`, outcome_attribute_data);
    }
    getOutComeAttributes(tenant_id) {
        return this.httpClient.get(`${this.API_URL}getOutComeAttributesByDomainUuid/${tenant_id}`);
    }
    outcomeGroupList(tenent_domain_uuid) {
        return this.httpClient.get(`${this.API_URL}getOutComeGroupListByDomainUuid/${tenent_domain_uuid}`);
    }
    deleteOutcomeGroup(group_uuid) {
        return this.httpClient.post(`${this.API_URL}deleteOutComeGroupByID`, group_uuid);
    }
    createOutcome(outcome_data) {
        return this.httpClient.post(`${this.API_URL}createOutcomeByDomainUuid`, outcome_data);
    }
    getOutComeListByUuid(params) {
        return this.httpClient.post(`${this.API_URL}getOutComeListByUuid`, params);
    }
    deleteOutComeAttribute(attribute_id) {
        return this.httpClient.post(`${this.API_URL}deleteOutComeAttributeByID`, attribute_id);
    }
    deleteOutComeByID(outcome_id) {
        return this.httpClient.post(`${this.API_URL}deleteOutComeByID`, outcome_id);
    }
    saveOutComeDetails(outcome_data) {
        return this.httpClient.post(`${this.API_URL}saveOutComeDetailsByAgentUuid`, outcome_data);
    }
    getAsignedCampaignsToAgent(fetch_camp_params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}campWithQueues`, fetch_camp_params);
    }
    mapGrpWithFile(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}mapGroupWithFile`, params);
    }
    getAgentWithCampaign(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}campWithTiers`, params);
    }
    availableQueues(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}queue-for-camp/${domain_uuid}`);
    }
    getSocialCampaignData(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getsocialcampaignbycampuuid/`, domain_uuid);
    } 
    getCampaignActivity(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaign`, param);
    }
    requestReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}report_request`, param);
    }
    getTransferAgentList(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-transfer-call-data`, param);
    }
    updateOutComeDetails(outcome_data) {
        return this.httpClient.post(`${this.API_URL}updateOutComeDetailsByAgentUuid`, outcome_data);
    }
    getSystemOutcomeDataForCallBack(camp_uuid) {
        return this.httpClient.get(`${this.API_URL}getSystemOutComeCauseDetailsByCampUuid/${camp_uuid}`);
    }
    socialDispositionReport(domain_uuid) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}social-disposition-report`, domain_uuid);
    }
    facebookReport(domain_uuid) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}facebook-report`, domain_uuid);
    }
    whatsappReport(domain_uuid) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}whatsapp-report`, domain_uuid);
    }
    ViberReport(domain_uuid) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}viber-report`, domain_uuid);
    }
    webchatreport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}webchat-report`, domain_uuid);
    }
    userProductivityReport(domain_uuid){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}user-productivity-report/`, domain_uuid);
    }
    availableAgentForChatQueue(domain_uuid, queue_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}available_agent_list/${domain_uuid}/${queue_uuid}`);
    }
    loginInSocialPage(client_id, client_secret, flac_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}uvoice-facebook-login/${client_id}/${client_secret}/${flac_uuid}`);
    }
    getAgentAssignedFacebook(agent_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}show-agent-facebook-application/${agent_uuid}`);
    }
    asigneAgentToFacebookCampaign(agent_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}assign-agent-to-facebook-application`, agent_data);
    }
    agentsInConfiguration(flac_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}agent-list-assigned-to-facebook-application/${flac_uuid}`);
    }
    
    // ticket_data Start here
    ticketbysource(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-by-source`,param);
    }
    weeklyticket(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}weekly-ticket`,param);
    }
    ticketbymonths(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-by-months`,param);
    }
    ticketbyyear(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ticket-by-year`,param);
    }
    // ticket_data End here
    agentNotAssignedInApplication(flac_uuid, domain_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}agent-list-not-in-facebook-application/${flac_uuid}/${domain_uuid}`);
    }
    removeAssignedAgentFromAccount(agent_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}remove-agent-from-facebook-application/${agent_uuid}`);
    }
    updateFacebookAccoundConfiguration(domain_uuid, flac_uuid, updated_fb_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update-facebook-application/${flac_uuid}/${domain_uuid}`, updated_fb_account_data);
    }
    updateFacebookAccoundwall(domain_uuid, flac_uuid, updated_fb_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update-facebook-application/${flac_uuid}/${domain_uuid}`, updated_fb_account_data);
    }
    getPageAccessTokenByAccessToken(accessToken) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_page_id/${accessToken}`);
    }
    getPagePostByPageId(pageId, accessToken) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getall_post_of_page/${pageId}/${accessToken}`);
    }
    getCommentPostByPageIdAndAccessToken(pagePostId, accessToken) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_comments_of_post/${pagePostId}/${accessToken}`);
    }
    addCommentOnPost(postCommentArg) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}comment_on_post`, postCommentArg);
    }
    likeDislikePostORCommentById(postCommentArg) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}likes_and_unlike_post_and_comments`, postCommentArg);
    }
    getAgentCampaignWrapUpTime(campaign_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getAgentCampaignTierAtCall/${campaign_uuid}`);
    }
    getSimpleReports(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-simple-reports/`, params);
    }
    addWallboardTemplate(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}AddWallboard`, params);
    }
    getWallboardTemplate(params) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}GetWallboardList?DomainUUID=${params}`);
    }
    deleteWallboardTemplate(params) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}DeleteWallboardByID/${params}`);
    }
    getSmotchCustomerByAgentUuid(agent_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getcustomerbyagent_uuid/${agent_uuid}`);
    }
    getOutcomeTemplateList(domain_uuid) {
        return this.httpClient.get(`${this.API_URL}getOutComeListByDomainUuid/${domain_uuid}`);
    }
    updateOutComeTemplate(template_name) {
        return this.httpClient.post(`${this.API_URL}updateOutComeByDomainUuid`, template_name);
    }
    getSmsCampaigns(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getSmsCampaignList?domainUUID=${domain_uuid}`);
    }
    addSmsCampaign(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addSmsCampaign`, campaign_data);
    }
    deleteSmsCampaign(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deleteSmsCampaignByID`, params);
    }
    updateSmsCampaign(update_camp_date) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updateSmsCampaign`, update_camp_date);
    }
    getSmsApisList(tenant_uuid) {
        return this.httpClient.get(`${this.SMS_URL}/sms/gateway/get?domainUUID=${tenant_uuid}`);
    }
    deleteSmsGateway(gateway_uuid) {
        return this.httpClient.post(`${this.SMS_URL}/sms/gateway/delete-by-id`, gateway_uuid);
    }
    addSmsApis(sms_api_data) {
        return this.httpClient.post(`${this.SMS_URL}/sms/gateway/add`, sms_api_data);
    }
    updateSmsApis(updated_sms_api_data) {
        return this.httpClient.put(`${this.SMS_URL}/sms/gateway/update`, updated_sms_api_data);
    }
    getSmsTemplatelist(templist) {
        return this.httpClient.post(`${this.SMS_URL}/sms/template/get`,templist);
    }
    deleteSmsTemp(temp_uuid) {
        return this.httpClient.post(`${this.SMS_URL}/sms/template/delete`, temp_uuid);
    }
    updateSmstempstatus(param) {
        return this.httpClient.post(`${this.SMS_URL}/sms/template/update-status`, param);
    }
    createSMSTemp(params){
        return this.httpClient.post(`${this.SMS_URL}/sms/template/create`, params);
    }
    updateSMSTemp(params) {
        return this.httpClient.post(`${this.SMS_URL}/sms/template/update`, params);
    }
    getSmsCampaignHistory(tenant_uuid, camp_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getSmsCampaignHistoryList?domainUUID=${tenant_uuid}&campUUID=${camp_uuid}`);
    }
    getEmailBroadcast(tenant_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}email-campaigns?domain_uuid=${tenant_uuid}`);
    }
    createEmailBroadcast(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-campaign`, campaign_data);
    }
    deleteMailBroadcast(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-email-campaign`, params);
    }
    updateEmailBroadcast(campaign_data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}email-campaign`, campaign_data);
    }
    changeEmailBroadcastStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-emailcampaign-status`, params);
    }
    getEmailCampaigns(tenant_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-email-campaigns`,{domain_uuid:tenant_uuid});
    }
    createEmailCampaigns(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-email-campaigns`, campaign_data);
    }
    deleteMailCampaigns(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-email-campaigns`, params);
    }
    updateEmailCampaigns(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-email-campaigns`, campaign_data);
    }
    saveTwitterPostAccount(twitter_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}save_twitter_auth`, twitter_account_data);
    }
    saveTwitterWallPost(twitter_account_data) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}save_twitter_auth`, twitter_account_data);
    }
    getTwitterPostAccount(tenant_uuid) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_twitter_auth_list/${tenant_uuid}`);
    }
    getTwitterWallPost(tenant_uuid) {
        return this.httpClient.get(`${this.TWITTER_BASE_URL}get_twitter_auth_list/${tenant_uuid}`);
    }
    deleteTwitterAccount(id, tenant_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_twitter_auth/${id}/${tenant_uuid}`);
    }
    deleteTwitterWallPost(id, tenant_uuid) {
        return this.httpClient.delete(`${this.TWITTER_BASE_URL}delete_twitter_auth/${id}/${tenant_uuid}`);
    }
    updateTwitterAccount(id, tenant_uuid, updated_twitter_account_data) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_twitter_auth/${id}/${tenant_uuid}`, updated_twitter_account_data);
    }
    updateTwitterWallPost(updated_twitter_account_data) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}update_twitter_auth`, updated_twitter_account_data);
    }
    updateLeadContactTableOptions(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}leadmanagement`, params);
    }
    updateLeadContactTable(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}leadmanagement`, params);
    }
    getTwitterTimeline(params) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}twitter_apis`, params);
    }
    getSingleTweet(params) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}twitter_apis`, params);
    }
    getTweetLikes(params) {
        return this.httpClient.post(`${this.TWITTER_BASE_URL}twitter_apis`, params);
    }
    recordingsList(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getRecordings/`, params);
    }
    getTrunkPrefix(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaign`, params);
    }
    getSmtpDetail(data) {
        return this.httpClient.post(`${this.API_URL}showMailDetails`, data);
    }
    addMailDetails(mail_data) {
        return this.httpClient.post(`${this.API_URL}addMailDetails`, mail_data);
    }
    updateMailDetails(updated_smtp_config) {
        return this.httpClient.put(`${this.API_URL}updateMailDetails`, updated_smtp_config);
    }
    deleteMailDetails(params) {
        return this.httpClient.post(`${this.API_URL}deleteMailDetails`, params);
    }
    getCallBackList(domain_uuid, camp_uuid) {
        return this.httpClient.get(`${this.API_URL}getCallbackList/${domain_uuid}/${camp_uuid}`);
    }
    deleteTwitterList(id, domain_uuid) {
        return this.httpClient.delete(`${this.TWITTER_BASE_URL}delete_twitter_configuration/${id}/${domain_uuid}`);
    }
    deleteFacebookList(id, domain_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_facebookConfiguration/${id}/${domain_uuid}`);
    }
    deleteFacebookWall(id, domain_uuid) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete-facebook-application/${id}/${domain_uuid}`);
    }
    addCustomerToBlackList(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-block-number`, params);
    }
    addCustomerToBlackListByFile(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}addBlocklistWithFile`, params);
    }
    getBackListNumber(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}block-numbers/${domain_uuid}`);
    }
    daleteBackListNumber(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-block-number`, params);
    }
    getQueueMapWithCampaign(domain_uuid, camp_uuid, agent_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getReadyQueuesFromCampaign/${domain_uuid}/${camp_uuid}/${agent_uuid}`);
    }
    getPriorityNumbers(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}high-priority-numbers/${domain_uuid}`);
    }
    deletePriorityNumbers(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-high-priority-number/`, params);
    }
    addPriorityNumbers(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-high-priority-number`, params);
    }
    scheduleCallback(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}schedule-callback`, params);
    }
    dalateScheduledCallbackList(callback_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-scheduled-callback`, callback_uuid);
    }
    searchCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/search-calls/`, params);
    }
    mailTemplates(data) {
        return this.httpClient.post(`${this.API_URL}mail-templates`, data);
    }
    createMailTemplates(params) {
        return this.httpClient.post(`${this.API_URL}mail-template/create`, params);
    }
    updateMailTemplates(params) {
        return this.httpClient.put(`${this.API_URL}mail-template/update`, params);
    }
    deleteMailTemplates(params) {
        return this.httpClient.post(`${this.API_URL}mail-template/delete/`, params);
    }
    // attachment & signature api
    getAttachmentSign(params){
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-mailtemplate-attachment/${params}`);
    }
    createAttachmentSign(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}upload-mailtemplate-attachment`, params);
    }
    deleteAttachmentSign(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-mailtemplate-attachment`, params);
    }
    addQualityFeedback(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}call-quality/tenant`, params);
    }
    updateQualityFeedback(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}call-quality/tenant`, params);
    }
    getQualityFeedback(domain_uuid, is_accepted_by_agent) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}call-quality-with-status/${domain_uuid}/${is_accepted_by_agent}`);
    }
    deleteQualityFeedback(call_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}call-quality/${call_uuid}`);
    }
    agentFeedback(agent_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}call-quality/agent/${agent_uuid}`);
    }
    updateAuditByAgent(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}call-quality/agent`, params);
    }
    activeCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}active-calls`, params);
    }
    campaignCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-camp-calls/`, params);
    }
    scheduledReports(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}scheduled-report/${domain_uuid}`);
    }
    scheduleNewReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}scheduled-report`, params);
    }
    getAllCallbacksList(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-scheduled-callbacks/`, params);
    }
    getScheduledCallbacksList(domain_uuid,agent_uuid,camp_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-scheduled-callbacks/${domain_uuid}/${agent_uuid}/${camp_uuid}`);
    }
    updateReportSchedule(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}scheduled-report`, params);
    }
    deleteScheduledReport(domain_uuid, Scheduled_report_id) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}scheduled-report/${domain_uuid}/${Scheduled_report_id}`);
    }
    voicemailReports(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voice-mailsearch`, params);
    }
    AgentCdr(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-cdr/`, params);
    }
    ringGroupsList(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}ring-groups/${domain_uuid}`);
    }
    createRingGroup(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ring-group`, params);
    }
    getAgentRoles(domain_uuid) {
        return this.httpClient.post<any>(`${this.AGENT_QUEUE_URL}getAgentRolesByDomaiUUID`, domain_uuid);
    }
    createAgentRoles(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-role`, params);
    }
    deleteAgentRoles(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-role/`, params);
    }
    updateAgentRoles(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}agent-role`, params);
    }
    getTenantModuleCount(param) {
        return this.httpClient.post(`${this.API_URL}tenant/summary/`, param);
    }
    getCampaignListByType(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getCampaignListByType`, params);
    }
    duplicateCampaign(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}duplicate-campaign`, params);
    }
    resetLeadList(param) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}resetLeadListById/${param}`);
    }
    changeLeadListStatus(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updateGroupStatus`, params);
    }
    getTotalLead(param) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getTotalLead/${param}`);
    }
    savethecronjobs(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}global-cron-jobs`, data);
    }
    deletejobs(data) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}global-cron-jobs//${data}`);
    }
    getGlobalJobsList(data) {
        return this.httpClient.get<any>(`${this.AGENT_QUEUE_URL}global-cron-jobs/${data}`);
    }
    updatecronjobs(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}global-cron-jobs`, params);
    }
    addedplans(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}planning`, data);
    }
    updateplans(data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}planning`, data);
    }
    deleteplans(data) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}planning//${data}`);
    }
    getPlanningsList(data) {
        return this.httpClient.get<any>(`${this.AGENT_QUEUE_URL}planning/${data}`);
    }
    addadmin(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}admin`, data);
    }
    updateadmin(data) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}admin`, data);
    }
    deleteAdmins(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete_admin`, data);
    }
    getadminList(data) {
        return this.httpClient.get<any>(`${this.AGENT_QUEUE_URL}admin/${data}`);
    }
    createVoiceBroadcast(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voice-broadcasts/`, params);
    }
    updateVoiceBroadcast(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}voice-broadcasts/`, params);
    }
    getVoiceBroadcast(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}voice-broadcasts/${domain_uuid}`);
    }
    DeleteVoiceBoradcast(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-voice-broadcasts`, params);
    }
    changeSmsCampaignStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-smscampaign-status`, params);
    }
    changeSalesTeamStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-sales-team-status`, params);
    }
    changeEmailCampaignStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-email-campaigns-status`, params);
    }
    createAdmin(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}admin`, params);
    }
    UpdateAdmin(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}admin`, params);
    }
    getAdmin(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}admin/${domain_uuid}`);
    }
    deleteAdmin(data) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}admin/${data}`);
    }
    createBackupSetting(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}recording-backup`, params);
    }
    UpdateBackupSetting(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}recording-backup`, params);
    }
    GetBackupSetting(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}recording-backup/${domain_uuid}`);
    }
    DeleteBackupSetting(record_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-recording-config`, record_uuid);
    }
    createEmailNotify(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-notification`, params);
    }
    UpdateEmailNotify(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}email-notification`, params);
    }
    GetEmailNotify(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}email-notification/${domain_uuid}`);
    }
    DeleteEmailNotify(email_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}email-notification//${email_uuid}`);
    }
    emailnotificationstatus(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-notification-status`,param);
    }
    createSalesTeam(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sales-team`, params);
    }
    UpdateSalesTeam(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}sales-team`, params);
    }
    GetSalesTeam(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}sales-team/${domain_uuid}`);
    }
    DeleteSalesTeam(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-sales-team`, params);
    }
    createTimeCondition(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}time-condition`, params);
    }
    UpdateTimeCondition(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}time-condition`, params);
    }
    GetTimeCondition(domain_uuid) {
        return this.httpClient.post(`${this.API_URL}get-condition-by-uuid`, { uuid: domain_uuid });
    }
    DeleteTimeCondition(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}time-condition/`, params);
    }
    saverolespermission(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}roles-and-permissions`, data);
    }
    rolespermissionList(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-roles-and-permissions-by-domain-uuid/${domain_uuid}`);
    }
    deleteRolesandPermission(roles_permission_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}roles-and-permissions//${roles_permission_uuid}`);
    }
    UpdateRolesandPermission(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}roles-and-permissions`, params);
    }
    createFilter(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}filter`, params);
    }
    UpdateFilter(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}filter`, params);
    }
    GetFilter(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}filter/${domain_uuid}`);
    }
    updatefilterstatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-filter-status`, params);
    }
    // DeleteFilter(filter_uuid) {
    //     return this.httpClient.delete(`${this.AGENT_QUEUE_URL}filter//${filter_uuid}`);
    // }

    //support

    

    DeleteFilter(filter_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}filter/`,filter_uuid);
    }
    createSupportTicket(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}support`, params);
    }
    UpdateSupportTicket(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updatesupport`, params);
    }
    GetSupportTicket(ticket_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getbydomain/${ticket_uuid}`);
    } 
    GetTenantSupportTicket(ticket_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-support-ticket-by-domainuuid`,ticket_uuid);
    } 
    DeleteSupportTicket(ticket_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}deletesupport/${ticket_uuid}`);
    }
    UpdateAdminSupportTicket(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-support-ticket`, params);
    }
    GetAdminSupportTicket(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}getall-support-ticket`,param);
    }
    ChangeSupportPriority(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}change-priority-on-superadmin`,param);
    }
    addConference(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-conference/`, agentData);
    }
    updateConference(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}v1/callCenter/update-conference/`, params);
    }
    getConference(requestData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/get-conference/`, requestData);
    }
    deleteConference(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-conference/`, param);
    }
    createProxy(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}createproxy/database`, agentData);
    }
    availableAgentsInConfrence(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-available-conferenceagent`, params);
    }
    getProxyList() {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getproxy/database`);
    }
    deleteProxy(proxyUuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}delete/proxydatabase/${proxyUuid}`);
    }
    createAccessLog(agentData) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}createaccesslog/database`, agentData);
    }
    getAccessLog() {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getaccesslog/database`);
    }
    deleteAccessLog(accessUuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}delete/accesslogdatabase/${accessUuid}`);
    }
    createDatabaseManagement(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create/databasemanagement`, params);
    }
    GetdbData() {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get/databasemanagement`);
    }
    deleteAdvancesettData(tierData) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}delete/databasemanagement/${tierData}`);
    }
    createChatQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}chatqueue`, params);
    }
    createSMSQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}smsqueue`, params);
    }
    getchatQueue(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}chatqueue/${domain_uuid}`);
    }
    updateChattQueue(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}chatqueue`, params);
    }
    deleteCHATQueues(tierData) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}chatqueue//${tierData}`);
    }
    deleteSocialQueue(tierData) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_queue/${tierData}`);
    }
    UpateQueueStatus(data){
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update-queue-status`, data);
    }
    getSMSQueue(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}smsqueue/${domain_uuid}`);
    }
    UpdateSMSQueue(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}smsqueue`, params);
    }
    deletesmsQueues(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}smsqueue-delete`, params);
    }
    createEmailQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}create-email-queue`, params);
    }
    updateEmailQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-email-queue`, params);
    }
    getEmailQueue(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-email-queue`,param);
    }
    // getEmailQueue(domain_uuid) {
    //     return this.httpClient.get(`${this.AGENT_QUEUE_URL}emailqueue/${domain_uuid}`);
    // }
    deleteEmailQueue(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-email-queue`,param);
    }
    updateEmailQueueStatus(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-email-queue-status`,param);
    }
    createCliNumber(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}climanager`, params);
    }
    updateCliNumber(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}climanager`, params);
    }
    getCliNumber(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}climanager/${domain_uuid}`);
    }
    deleteCliNumber(email_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}climanager/${email_uuid}`);
    }
    createCliRules(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}clirule`, params);
    }
    updateCliRules(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}clirule`, params);
    }
    getCliRules(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}clirule/${domain_uuid}`);
    }
    deleteCliRules(email_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}clirule/${email_uuid}`);
    }
    surveyWeb(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}surveycampaign`, params);
    }
    getWebSurvey(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}surveycampaign/${domain_uuid}`);
    }
    createFail2ban(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}createglobal/setting/security`, params);
    }
    deleteSurveyCampaign(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-surveycampaign`, domain_uuid);
    }
    surveyUpdateWeb(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}surveycampaign`, params);
    }
    updateWallboardTable(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}tseperate`, params);
    }
    agentActivityReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-activity-report/`, params);
    }
    outboundSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}outbound-summary-report/`, params);
    }
    callDetailsReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}call-detail-report/`, params);
    }
    inboundSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}Inbound-summary-report/`, params);
    }
    CampaignSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}camp-report/`, params);
    }
    scheduledCallbackList(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}scheduled-callbacks/${domain_uuid}`);
    }
    ScheduledCallReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}schedule-call-report/`, params);
    }
    QueueReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}queue-report/`, params);
    }
    ivrReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ivr-report/`, params);
    }
    smsReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sms-report/`, params);
    }
    didReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}did-summary-report/`, params);
    }
    orderReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}order-report`, params);
    }
    agentDispositionReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-disposition-report/`, params);
    }
    agentPerfomanceReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-perfomance-report/`, params);
    }
    agentSessionReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-session-report/`, params);
    }
    agentSummaryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-summary-report/`, params);
    }
    completedCallbackReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}completed-calls-report/`, params);
    }
    interactionHisoryReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}interaction-report/`, params);
    }
    slaAnsweredReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sla-answered-report/`, params);
    }
    slaAbandonedReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sla-abandoned-report/`, params);
    }
    voiceBroadcastReport(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}voice-broadcast-report/`, params);
    }
    getCampaignDisposition(camp_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getDispositionDatasByCampUUID/${camp_uuid}`);
    }
    getEmailFromQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-email-from-queue`, params);
    }
    contactSearch(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}GetContactListByKeyName`, params);
    }
    createFaxServer(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}fax/createfserver`, params);
    }
    getFaxServer(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}fax/getfserver/${domain_uuid}`);
    }
    updateFaxServer(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}fax/updatefserver`, params);
    }
    deleteFaxServer(Fax_uuid) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}fax/deletefserver/${Fax_uuid}`);
    }
    storeDispositionOutcome(request_data) {
        return this.httpClient.post(`${this.API_URL}store-disposition-outcome`, request_data);
    }
    publishPost(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}publish_post`, params);
    }
    getFbPost(page_id, token) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}getpost/${page_id}/${token}`);
    }
    deleteFbPost(page_post_id, token) {
        return this.httpClient.delete(`${this.SOCIAL_MEDIA_URL}delete_post/${page_post_id}/${token}`);
    }
    updateFbPost(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}update_post`, params);
    }
    getCommentOfPost(page_post_id, token) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_comments_of_post/${page_post_id}/${token}`);
    }
    disposeEmail(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}dispose-contacts`, chat_data);
    }
    inboundEmailHistory(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}inbound-email-report-history`,data);
    }
    emailCampaignReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}inbound-email-report`, param);
    }
    getLikeOfPost(page_post_id, token) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_likes_of_post/${page_post_id}/${token}`);
    }
    storeColumns(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}store-columns`, params);
    }
    showStoreColumns(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}show-store-columns`, params);
    }
    emailBlastingReport(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-report/`, param);
    }
    liveActiveAgents(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}active-agents/`, params);
    }
    liveActiveCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}active-calls/`, params);
    }
    getLiveCalls(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}live-calls/`, params);
    }
    getCallWithCallTypes(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-call-with-call-types/`, params);
    }
    getCallsInQueue(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-calls-in-queue/`, params);
    }
    getLiveCallWithCallTypes(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-live-call-with-call-types/`, params);
    }
    getLiveCallByMonthly(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-live-call-by-monthly/`, params);
    }
    getTopAgentByCall(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-by-call/`, params);
    }
    getTopAgentByHandleTime(params) {
        return this.httpClient.post(`${this.API_URL}tenant/agent-by-hendle-time/`, params);
    }
    getTenantAssignedFeature(data) {
        return this.httpClient.get(`${this.API_URL}tenant/get-tenant-role-permission-by-domain-uuid/${data}`);
    }
    forceLogout(param) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-agent-current-status`, param);
    }
    getTenantCurrentAuthToken(params) {
        return this.httpClient.post(`${this.API_URL}tenant/get-auth-token-by-domain-name`, params);
    }
    checkAgentCurrentStatus(param) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-agent-current-status/${param}`);
    }
    getPreviewDialerDetails(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-preview-dialer-details/`, params);
    }
    updatePreviewDialerState(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-preview-dialer-state`, params);
    }
    updateTeamStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-team-status`, params);
    }
    updateAgentStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-agent-status`, params);
    }
    updateTrunkStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-sip-status`, params);
    }
    updateDidStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-DID-status`, params);
    }
    updateMohStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-moh-status`, params);
    }
    updatePromptStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-prompt-status`, params);
    }
    updateFaxStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}fax/update-fserver-status`, params);
    }
    updateConfrenceStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-conference-status/`, params);
    }
    updateHighPriorityStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-high-priority-number`, params);
    }
    updatePriorityNumber(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-high-priority-number`, params);
    }
    updateTimeConditiionStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-time-condition-status`, params);
    }
    getAgentDetailsLive(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-agent-team-tier-details-by-uuid/`, params);
    }
    updateIvrStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-ivr-status`, params);
    }
    updateQueueStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/update-queue-status/`, params);
    }
    updateVoiceBroadcastStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-broadcasts-status`, params);
    }
    updateCampaignStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-campaign-status`, params);
    }
    ChangeDialerStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}tenant/campaign/update-dialer-status`, params);
    }
    updateChatStatus(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-chat-status`, params);
    }
    updateSmsGatewaystatus(param) {
        return this.httpClient.post(`${this.SMS_URL}/sms/gateway/update-status`, param);
    }
    updateBroadcastStatus(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-broadcasts-status`, param);
    }
    updateSalesTeamStatus(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-sales-team-status`, param);
    }
    updateSalesPlanningStatus(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-planning-status`, param);
    }
    updateSmtpstatus(param) {
        return this.httpClient.post(`${this.API_URL}update-mail-details-status`, param);
    }
    updateMailTemplatestatus(param) {
        return this.httpClient.post(`${this.API_URL}update-mail-template-Status`, param);
    }
    updateAdminStatus(params) {
        return this.httpClient.put(`${this.API_URL}admin-active-deactive`, params);
    }
    changCrmTemplateStatus(params) {
        return this.httpClient.post(`${this.API_URL}update-crm-status`, params);
    }
    removeCRMById(params): Observable<any> {
        return this.httpClient.post(`${this.API_URL}delete-crm/`, params);
    }
    // Facebook start from here
    
    getFacebookAuth(domain_id) {
        return this.httpClient.get(`${this.SOCIAL_MEDIA_URL}get_facebook_auth_list/${domain_id}`);
    }
    get_message_from_fb(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}get_message`, params);
    }
    socialMediaLogout(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}deassign_agent`, params);
    }
    WhatsappDeassignAgent(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}deassign_agent`, params);
    }
    getFacebookContact(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}contact_lists`, params);
    }
    send_messages_on_fb(param) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}send_message`, param);
    }
    closefacebookChat(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}close_chat`, params);
    }
    FillDispositionForFacebook(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}fill_disposition`, params);
    }
    getFacebookMessages(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}get_messages`, params);
    }
    facebookCronFirst(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}get_message_split`, params);
    }
    facebookCronSecond(params) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}contact_list`, params);
    }
    getChatContact(params) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}liveagentchat/${params}`);
    }
    getHistoryData(param) {
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}getchathistory`, param);
    }
    sendMessageWithAttachment(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sendAttachment`, param);
    }
    sendMessageOnChat(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}sendMessage`, param);
    }
    receiveMessageFromChat(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}receiveMessage`, param);
    }
    closeChatContact(params,domain_name) {
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}liveagentchat/${params}/${domain_name}`);
    }
    createDropbox(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}drop-box-recording-backup`, params);
    }
    DeleteDrop(record_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-drop-box-recording-backup`, record_uuid);
    }
    getFtpSftp(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}ftp-recording-backup/${domain_uuid}`);
    }
    UpdateFtpSftp(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-ftp-recording-backup`, params);
    }
    UpdateGdrive(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-drive-recording-backup`, params);
    }
    UpdateDropBox(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-drop-box-recording-backup`, params);
    }
    UpdateOnedrive(params) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-one-drive-recording-backup`, params);
    }
    createDrive(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}drive-recording-backup`, params);
    }
    createOneDrive(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}one-drive-recording-backup`, params);
    }
    getDrive(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}drive-recording-backup/${domain_uuid}`);
    }
    getOneDrive(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}one-drive-recording-backup/${domain_uuid}`);
    }
    getDropBox(domain_uuid) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}drop-box-recording-backup/${domain_uuid}`);
    }
    createFtpSftp(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}ftp-recording-backup`, params);
    }
    DeleteDrive(record_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-drive-recording-backup`, record_uuid);
    }
    DeleteOneDrive(record_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-one-drive-recording-backup`, record_uuid);
    }
    Deleteftp(record_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-ftp-recording-backup`, record_uuid);
    }
    AvailableAgentList(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-available-agent-list`, params);
    }
    getParkedCall(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-call-park-details-by-domain-uuid`, domain_uuid);
    }
    parkedCallExtension(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-callpark-extensions`, params);
    }
    deleteParkedCall(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-call-park-details-by-park-uuid`, domain_uuid);
    }
    updateParkedCall(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-callpark-status`, domain_uuid);
    }
    addWarmTransferCall(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}attended-call-transfer`, domain_uuid);
    }
    updateWarmTransferCall(domain_uuid) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}update-warm-transfer-status-by-agent-uuid`, domain_uuid);
    }
    addConferenceNumberToCall(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}make-conference-call`, param);
    }
    removeActiveCalls(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}remove-active-calls/`, param);
    }
    createSocialCampaign(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}createsocialcampaign`, param);
    }
    getsocialcampaign(param) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getsocialcampaign/${param}`);
    }
    updateSocailCampStatus(param) {
        return this.httpClient.put(`${this.AGENT_QUEUE_URL}updatesocialcampaign`, param);
    }
    updateSocailCampOnOffStatus(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}updatesocialcampaignstatus`, param);
    }
    deleteSocialCampaign(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}deletesocialcampaign`, param);
    }
    createQualityAgent(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/create-quality-agent/`, param);
    }
    getQualityAgent(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/show-quality-agent-list/`, param);
    }
    updateQualityAgent(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/edit-quality-agent/`, param);
    }
    deleteQualityAgent(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}v1/callCenter/delete-quality-agent/`, param);
    }


    // Viber APi start from here
    // Viber bot subscription
    createViberBotSubscription(param){
        return this.httpClient.post(`${this.VIBER_BASE_URL}add-bot`,param)
    }
    updateViberBotSubscription(param){
        return this.httpClient.post(`${this.VIBER_BASE_URL}update-bot`,param)
    }
    getViberBotSubscription(){
        return this.httpClient.get(`${this.VIBER_BASE_URL}get-bot`)
    }
    deleteViberBotSubscription(param){
        return this.httpClient.post(`${this.VIBER_BASE_URL}delete-Bot`,param)
    }

    // Viber Configuration
    addViberConfiguration(whatsapp_configuration_data) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}add_viberConfiguration`, whatsapp_configuration_data);
    }
    getViberConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.VIBER_BASE_URL}get_viberConfiguration/${tenant_uuid}`);
    }
    deleteViberConfiguration(id, tenant_uuid) {
        return this.httpClient.delete(`${this.VIBER_BASE_URL}delete_viberConfiguration/${id}/${tenant_uuid}`);
    }
    updateViberConfiguration(telegram_id, tenant_uuid, updated_whatsapp_configuration_data) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}update_viberConfiguration/${telegram_id}/${tenant_uuid}`, updated_whatsapp_configuration_data);
    }

    // Viber bot
    createViberBot(params){
        return this.httpClient.post(`${this.VIBER_BASE_URL}add-chatbot-case`, params);
    }
    updateViberBot(params) {
        return this.httpClient.put(`${this.VIBER_BASE_URL}update-chatbot-case`, params);
    }
    getViberBot(domain_uuid){
        return this.httpClient.post(`${this.VIBER_BASE_URL}get-chatbot-case`,domain_uuid);
    }
    deleteViberBot(params){
        return this.httpClient.post(`${this.VIBER_BASE_URL}delete-chatbot-case`, params);
    }

    // Viber chat

    getViberMessages(params) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}get_messages`, params);
    }
    replyViberMessage(params) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}reply_message`, params);
    }
    closeViberChat(params) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}close_chat`, params);
    }
    sendNewViberChat(params) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}send_template`, params);
    }
    FillDispositionForViber(params) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}fill_disposition`, params);
    }
    getViberContacts(params) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}contact_lists`, params);
    }

    getViberHistoryData(param) {
        return this.httpClient.post(`${this.VIBER_BASE_URL}getchathistory`, param);
    }

    getOrderHistoryData(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}order-history`, param);
    }
    // Telegram Apis start from here
    addTelegramConfiguration(whatsapp_configuration_data) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}add_telegramConfiguration`, whatsapp_configuration_data);
    }
    getTelegramConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.TELEGRAM_BASE_URL}get_telegramConfiguration/${tenant_uuid}`);
    }
    deleteTelegramConfiguration(id, tenant_uuid) {
        return this.httpClient.delete(`${this.TELEGRAM_BASE_URL}delete_telegramConfiguration/${id}/${tenant_uuid}`);
    }
    updateTelegramConfiguration(viber_id, tenant_uuid, updated_viber_configuration_data) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}update_telegramConfiguration/${viber_id}/${tenant_uuid}`, updated_viber_configuration_data);
    }

    getTelegramMessages(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}get_messages`, params);
    }
    replyTelegramMessage(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}reply_message`, params);
    }
    closeTelegramChat(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}close_chat`, params);
    }
    sendNewTelegramChat(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}send_template`, params);
    }
    FillDispositionForTelegram(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}fill_disposition`, params);
    }
    getTelegramContacts(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}contact_lists`, params);
    }
    getTemplateTelegram(domain_uuid){
        return this.httpClient.get(`${this.TELEGRAM_BASE_URL}get-template/${domain_uuid}`);
    }
    createTelegramBotSubscription(param){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}add-bot`,param)
    }
    updateTelegramBotSubscription(param){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}update-bot`,param)
    }
    getTelegramBotSubscription(){
        return this.httpClient.get(`${this.TELEGRAM_BASE_URL}get-bot`)
    }
    deleteTelegramBotSubscription(param){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}delete-Bot`,param)
    }
    StatusUpdateOFTelegramBotSubscription(param){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}status-update`,param)
    }
    mapForTelegramBot(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}catch `, params);
    }
    conditionForTelegramBot(params){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}get-bot-message `, params);
    }
    createTelegramBot(params){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}add-chatbot-case`, params);
    }
    updateTelegramBot(params) {
        return this.httpClient.put(`${this.TELEGRAM_BASE_URL}update-chatbot-case`, params);
    }
    getTelegramBot(domain_uuid){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}get-chatbot-case`,domain_uuid);
    }
    deleteTelegramBot(params){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}delete-chatbot-case`, params);
    }
    updateTelegramtemplate(params) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}update-template`, params);
    }
    telegramReport(domain_uuid) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}telegram-report`, domain_uuid);
    }
    internalchatReport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}internalchat-report`, domain_uuid);
    }
    getinternalhistorychatReport(domain_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}internalchat-history`, domain_uuid);
    }
  
    // Telegram API end here
    // whatsapp apis start here 
    getWhatsappHistoryData(param) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}getchathistory`, param);
    }
    getTelegramHistoryData(param) {
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}getchathistory`, param);
    }
    addWhatsappConfiguration(whatsapp_configuration_data) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}add_whatsappConfiguration`, whatsapp_configuration_data);
    }
    getWhatsappConfiguration(tenant_uuid) {
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get_whatsappConfiguration/${tenant_uuid}`);
    }
    deleteWhatsappConfiguration(id, tenant_uuid) {
        return this.httpClient.delete(`${this.WHATSAPP_BASE_URL}delete_whatsappConfiguration/${id}/${tenant_uuid}`);
    }
    updateWhatsAppConfiguration(whatsapp_id, tenant_uuid, updated_whatsapp_configuration_data) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}update_whatsappConfiguration/${whatsapp_id}/${tenant_uuid}`, updated_whatsapp_configuration_data);
    }
    getWhatsAppContacts(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}contact_lists`, params);
    }
    getwhatsappMessages(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get_messages`, params);
    }
    replyWhatsappMessage(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}reply_message`, params);
    }
    closeWhatsappChat(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}close_chat`, params);
    }
    sendNewWhatsappChat(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}send_template`, params);
    }
    FillDispositionForWhatsapp(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}fill_disposition`, params);
    }
    getWhatsappBroadcasts(domain_uuid) {
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get-whatsapp-campaigns?domain_uuid=${domain_uuid}`);
    }
    createWhatsappBroadcast(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}create-whatsapp-campaign`, params);
    }
    updateWhatsappBroadcast(params) {
        return this.httpClient.put(`${this.WHATSAPP_BASE_URL}update-whatsapp-campaign`, params);
    }
    deleteWhatsappBroadcast(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-whatsapp-campaign`, params);
    }
    whatsappBroadcastStatus(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}status-whatsapp-campaign`, params);
    }
    createWhatsappTemplate(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}save-template`, params);
    }
    updateWhatsapptemplate(params) {
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}update-template`, params);
    }
    deleteWhatsappTemplate(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-template`, params);
    }
    getTemplateWhatsapp(domain_uuid){
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get-template/${domain_uuid}`);
    }
    createWhatsappBot(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}add-chatbot-case`, params);
    }
    updateWhatsappBot(params) {
        return this.httpClient.put(`${this.WHATSAPP_BASE_URL}update-chatbot-case`, params);
    }
    getWhatsappBot(domain_uuid){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-chatbot-case`,domain_uuid);
    }
    deleteWhatsappBot(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-chatbot-case`, params);
    }
    createWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}add-bot`,param)
    }
    updateWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}update-bot`,param)
    }
    getWhatsappBotSubscription(){
        return this.httpClient.get(`${this.WHATSAPP_BASE_URL}get-bot`)
    }
    deleteWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}delete-Bot`,param)
    }
    StatusUpdateOFWhatsappBotSubscription(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}status-update`,param)
    }
    whatsappBroadcastReport(param){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}whatsapp-broadcast-report`,param)
    }
    getTenantListForWhatsappBot(){
        return this.httpClient.get(`${this.API_URL}getTenantList`)
    }

    // whatsapp apis ends here
    getTotalContactInContactList(list_info) {
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}getTotalLead/${list_info}`);
    }



    // Internal Chat API start 
    getAvailableChatAgent(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get_live_agent`,param);
    }
    getInternalChatAgent(param) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}live_internal_agentchat`,param);
    }
    sendInternalChat(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}send_internal_Message`,params);
    }
    initiateInternalChat(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add_live_internal_agentchat`,params);
    }
    receiveInternalChat(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}receive_internal_Message`,params);
    }
    deleteInternalChat(thread_id,domain_name){
        return this.httpClient.delete(`${this.AGENT_QUEUE_URL}live_internal_agentchat/${thread_id}/${domain_name}`);
    }
    mediaInternalChat(params){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}send_internal_Attachment`,params);
    }
    // Internal chat end here
    // Disposition tier start 
    availableDispostionTier(params){
        return this.httpClient.post(`${this.API_URL}show-available-disposition`,params);
    }
    createDispostionTier(params){
        return this.httpClient.post(`${this.API_URL}create-disposition-tiers`,params);
    }
    deleteDispositionTier(dispositionDel_uuid) {
        return this.httpClient.delete(`${this.API_URL}delete-disposition-tiers/${dispositionDel_uuid}`);
    }
    // Disposition tier end
    // Break star here
    availablebreakstier(params){
        return this.httpClient.post(`${this.API_URL}show-available-breaks-tier`,params);
    }
    createbreaktier(param){
        return this.httpClient.post(`${this.API_URL}create-break-tier`,param);
    }
    deletebreaktier(BreakDel){
        return this.httpClient.delete(`${this.API_URL}delete-break-tier/${BreakDel}`)
    }
    // Break End here
    //show-contact-list
    showcontactlist(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}show-contact-list`,param);
    }
    // \\\\\\ Script report \\\\\\
    scriptreport(param){
        return this.httpClient.post(`${this.API_URL}script-report`,param);
    }
    scriptreportHistory(param){
        return this.httpClient.post(`${this.API_URL}script-fill-details`,param);
    }
    ///////////// Dashboard Metrics Super-Admin ///////////////
    NumberoFOmnichannel(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}number-of-omnichannel`,param);
    }
    FrequentlyUsedChannelsbyCustomer(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}frequently-used-channels-by-customer`,param);
    }
    InteractionHandledByAgentOmnichannel(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}interaction-handled-by-agent-omnichannel`,param);
    }
    TopPerformingAgentOmnichannel(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}top-performing-agent-omnichannel`,param);
    }
    AgentEvents(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-events`,param);
    }
    agentdetails(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-details`,param);
    }
    totaltenant(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}total-tenant`,param);
    }
    AgentCall(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-call`,param);
    }
    QualityOfResponseByAgentOmnichannel(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}quality-of-response-by-agent-omnichannel`,param);
    }
    BreakTakingAgent(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}break-taking-agent`,param);
    }
    agentsession(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}agent-session`,param);
    }
    AvghandletimeByAgentOmniChannel(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}avghandletime-by-agent-omnichannel`,param);
    }
    UpdateTelegramStatus(param){
        return this.httpClient.post(`${this.TELEGRAM_BASE_URL}status-update`,param)
    }
    UpdateviberStatus(param){
        return this.httpClient.post(`${this.VIBER_BASE_URL}status-update`,param)
    }
    UpdatewebchatStatus(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}status-update`,param)
    }
    updatesurveyStatus(chat_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-survey-status`, chat_data);
    }
    // Sentiment Start here
    GetSentiment(){
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}get-sentiment`)
    }
    StatusUpdateSentiment(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}status-update-sentiment`,param)
    }
    AddSentiment(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-sentiment`,param);
    }
    DeleteSentiment(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-sentiment`,param);
    }
    getTenantListForSentiment(){
        return this.httpClient.get(`${this.API_URL}getTenantList`)
    }

    // all campaigns data
    allTypeOfCampaign(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-all-campaigns-by-type`,param);
    }
    ResetContactList(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}reset-contact-list`,param);
    }
    GetGroupListCont(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-group-list-unassigned`,param);
    }
    GetContactHangupCauseList(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-contact-hangup-cause-list`,param);
    }
    ContactResethungupCasuse(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}contact-reset-hangup-cause`,param);
    }
    EmailTransfer(param){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}email-transfer`,param);
    }
    
    //ticket_campaigns
    getTicketCampaigns(tenant_uuid) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-campaign-ticket`, { domain_uuid: tenant_uuid });
    }
    createTicketCampaigns(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}add-campaign-ticket`, campaign_data);
    }
    deleteTicketCampaigns(params) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}delete-campaign-ticket`, params);
    }
    updateTicketCampaigns(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-campaign-ticket`, campaign_data);
    }
    updateTicketCampaignsStatus(campaign_data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}update-campaign-ticket-status`, campaign_data);
    }

    //All Campaigns Omnichannel APIs
    getAssignedEmailQueue(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}get-inbound-email-queue-list-unassigned`, data);
    }
    getAssignedSocialQueue(data){
        return this.httpClient.post(`${this.SOCIAL_MEDIA_URL}get-social-queue-list-unassigned`, data);
    }
    dialerDisposition(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}dashboard/dialer-disposition`, data)
    }
    conditionForWhatsappBot(params){
        return this.httpClient.post(`${this.WHATSAPP_BASE_URL}get-bot-message`, params);
    }
    conditionForFacebookBot(params){
        return this.httpClient.post(`${this.FACEBOOK_BASE_URL}get-bot-message`, params);
    }
    facebookwallConfiList(params){
    return this.httpClient.post(`${this.FACEBOOK_BASE_URL}fb-wall/configurations`,params);
   }
}