import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class InputPopoverService {
  common_date_time = {
    start_date: 'Start Date',
    start_date_d: '',
    end_date: 'End Date',
    end_date_d: '',
    start_time: 'Start Time',
    start_time_d: ''
  }
  // --------------------------SUPERADMIN----------------
  //  ----Account----
  //Roles & Permission
  role_permission = {
    position: 'right',
    name: 'Name',
    name_d: 'This is used to set the name of the admin.',
    description: 'Description',
    description_d: '',
    type: 'Type',
    type_d: '',
    status: 'Status',
    status_d: '',
  }

  // Admin
  admin_info = {
    position: 'right',
    name: 'Name',
    name_d: 'This shows the name of Admin',
    email: 'Email',
    email_d: 'Enter required email  ',
    role: 'Role',
    role_d: 'Enter role ',
    password: 'Password',
    password_d: 'Password must be atleast 6 characters and should not be greater than 12',
    status: 'Status',
    status_d: 'Status can be selected from this option.',
    permission_d : 'Permission can be selected from these options.',
    permission : 'Permission',
  }

  // Tenant
  tenant_info = {
    position: 'top',
    tenant_name: 'Tenant Name',
    tenant_name_d: 'Enter tenant name',
    phone_num: 'Phone Number',
    phone_num_d: 'Enter phone number',
    acc_email: 'Account Email',
    acc_email_d: 'Enter account email ',
    city: 'City',
    city_d: 'Enter you city',
    state: 'State',
    state_d: 'Enter your state',
    country: 'Country',
    country_d: 'Select country',
    address: 'Address',
    address_d: 'Enter address',
    password: 'Password',
    password_d: 'Password must be atleast 6 characters and should not be greater than 20.',
    confirm_pass: 'Confirm Password',
    confirm_pass_d: 'Please confirm password',
    agent_limit: 'Agent Limit',
    agent_limit_d: 'Enter age limit',
    domain_name: 'Domain Name',
    domain_name_d: 'Enter domain name',
    role: 'Role',
    role_d: 'Enter role ',
    time_zone: 'Time Zone',
    time_zone_d: 'Enter time zone',
    domain_desc: 'Domain Description',
    domain_desc_d: 'Enter domain description',
    create_mail_d : 'Enter mail smtp',
    create_mail : 'Mail SMTP'
  }

  // Email SMTP 
  email_smtp_info = {
    position: 'top',
    mail_id: 'Mail ID',
    mail_id_d: '',
    mail_from: 'From',
    mail_from_d: '',
    mail_serve: 'Mail Serve',
    mail_serve_d: '',
    password: 'Password',
    password_d: '',
    port: 'Port',
    port_d: '',
  }


  email_notification_info = {
    position: 'top',
    subject: 'Subject',
    subject_d: 'Mail Subject',
    body: 'Body',
    body_d: 'Mail Body',
    name: 'Name',
    mail_id: 'Email ID',
    mail_id_d: 'This is used for defining the name of the template.',
  }

  backup_setting = {
    position: 'right',
    backup_on: 'Backup On',
    backup_on_d: '',
    duration: 'Duration',
    duration_d: ''
  }
  // Global Cron Job
  global_cron_job_info = {
    position: 'right',
    schedule_for: 'Schedule For',
    schedule_for_d: '',
    duration: 'Duration',
    duration_d: '',
  }

  // Recording
  recording_info = {
    position: 'top',
    positionRigt: 'right',
    start_date_time: 'Start Date & Time',
    start_date_time_d: 'This shows starting date and time.',
    end_date_time: 'End Date & Time',
    end_date_time_d: 'This shows end date and time.',
    tenant: 'Tenant',
    tenant_d: 'User can select tenant from the list of  tenants ',
    call_direction: 'Call Direction',
    call_direction_d: 'This show direction of the call i.e. inbound, outbound.',
    trunk: 'Trunk',
    trunk_d: 'These are SIP trunks, users can select trunk from here.',
    campaign: 'Campaign',
    campaign_d: 'This shows list of campaigns and users can select from it.',
    mail_campaign: 'Email Campaign',
    mail_campaign_d: 'Email campaign can be selected from here.',
    mail_broadcast: 'Email Broadcast',
    mail_broadcast_d: 'Email broadcast can be selected from here.',
    agent_id: 'Agent ID',
    agent_id_d: 'User can select ID from the list of Agent ID',
    user_name: 'User Name',
    user_name_d: 'This shows name of the user.',
    queue: 'Queue',
    queue_d: 'This shows different queue names.',
    ivr: 'IVR',
    ivr_d: 'User can select ivr from here.',
    contact_list: 'Contact List',
    contact_list_d: 'This shows DID number.',
    agent: 'Agent',
    age_position:'right',
    agent_d: 'User can select agent from the list of agents.',
    extension: 'Extension',
    extension_d: 'User can select the required extension from the list.',
    hang_up_cause: 'Hang Up Cause',
    hang_up_cause_d: 'User can select the cause for call hang up.',
    hang_up_by: 'Hang Up By',
    hang_up_by_d: 'Users can select hang up by i.e. remote or local.',
    disposition_type: 'Disposition Type',
    disposition_type_d: '',
    disposition: 'Disposition',
    disposition_d: 'User can select disposition type from this option.',
    call_duration: 'Call Duration',
    call_duration_d: 'This shows the total duration of the call.',
    ring_time: 'Ring Time',
    ring_time_d: 'This shows how long does the call rang.',
    talk_time: 'Talk Time',
    talk_time_d: 'This shows the total time an agent talks to a customer.',
    wait_time: 'Wait Time',
    wait_time_d: 'This shows customer wait time before call is answered.',
    hold_time: 'Hold Time',
    hold_time_d: 'This shows the time customer was on hold.',
    acw_time: 'ACW Time',
    acw_time_d: "'This shows after call work time i.e. post processing after call.'  '*ACW Time should be mentioned in seconds'",
    caller_num: 'Caller Number',
    caller_num_d: 'This shows the calling party number.',
    called_num: 'Called Number',
    called_num_d: 'This shows the called number.',
    break_code: 'Break Code',
    break_code_d: 'This shows break code of an agent.',
    did_num: 'DID Number',
    did_num_d: 'This shows DID number.',
    dtmf: 'DTMF',
    dtmf_d: 'Dual tone multi frequency.',
    voice_broadcast: 'Voice Broadcast',
    voice_broadcast_d: 'This will display voice broadcast details.',
    wBrostatus_pos: 'right',
    status: 'Status',
    status_d: 'Status can be selected from this option.',
    contact: 'Contact',
    contact_d: '',
    contact_id: 'Contact ID',
    contact_id_d: '',
    phone_num: 'Phone Number',
    phone_num_d: 'This shows phone number.',
    interaction_type: 'Interaction Type',
    interaction_type_d: 'This shows type of interaction.',
    duration: 'Duration',
    duration_d: 'This allows you to fetch report for particular duration/time period.',
    report_type: 'Report Type',
    report_type_d: 'What is the type of report and who does it belong to, what does it contain.',
    twitter_id: 'Twitter ID',
    twitter_id_d: 'Twitter ID',
    whatsapp_id: 'Whatsapp ID',
    whatsapp_id_d: 'What’s app Id can be entered here.',
    facebook_id: 'Facebook ID',
    facebook_id_d: 'Facebook Id can be entered here.',
    customer_id: 'Customer ID',
    customer_id_d: 'This shows the customer ID.',
    callback_type: 'Callback Type',
    callback_type_d: '',
    assigned_to: 'Assigned To',
    assigned_to_d: 'User can select assigned agent from the list',
    inbound_num: 'Inbound Number',
    inbound_num_d: 'Select inbound number from the list of Inbound Numbers.',
    mobile_num: 'Mobile Number',
    mobile_num_d: '',
    Soc_Med_Typ:'Social Media Type',
    Soc_Med_Typ_d:'This shows the Social Media Type',
    Integr_id:'Integration ID ',
    Integr_id_d:'This shows the Integration ID ',
    team:'Team',
    team_d:'User can select team from the list of teams',
    bridge_d : ' It states the type of call for eg, Transfer, conference, Dialer etc',
    bridge : 'Bridge',
    group_odr :'Group Order',
    group_odr_d : 'Select group order',
    template_d :'User can select template from the list of templates',
    template :'Template',
    
  }

  ticket_report_info={
    position: 'top',
    start_date_time: 'Start Date & Time',
    start_date_time_d: 'This shows starting date and time.',
    end_date_time: 'End Date & Time',
    end_date_time_d: 'This shows end date and time.',
    status:'Status',
    status_d:'Status can be selected from this option.',
    channel:'Channel',
    channel_d:'User can select channel from the list of channels.',
    category:'Category',
    category_d:'User can select category  from here.',
    priority:'Priority',
    priority_d:'User can select Priority from the list depending on choice.',
    request_reason:'Request Reason',
    request_reason_d:'',
    assign_agent: 'Assign Agent',
    assign_agent_d : ''


  }
  // ------------Agent & team-------
  // Agent roles
  agent_role_info = {
    position: 'right',
    role_name: 'Role Name',
    role_name_d: 'Assign a role to the agent',
    description: 'Description',
    description_d: 'Write the brief description about the role name',
    status: 'Status',
    status_d: 'Status must be ON for assigning this role to agent '
  }

  // Extension & Email
  extension_email_info = {
    position: 'top',
    extension_id: 'Extension ID',
    extension_id_d: 'Enter the unique extension id and it must be 3 digit long and only numeric values are allowed',
    extension_pass: 'Extension Password',
    extension_pass_d: 'Password must be greater than 4 characters',
    description: 'Description',
    description_d: 'Write the complete description about extension',
    music_on_hold: 'Music on Hold',
    music_on_hold_d: 'Select a music when a agent puts a call on hold',
    extension_start_from: 'Extension Start From',
    extension_start_from_d: '',
    extension_end_to: 'Extension End To',
    extension_end_to_d: '',
    effective_caller_name: 'Effective Caller Name',
    effective_caller_name_d:'Name that is displayed on the recipient`s phone or caller ID when receiving a phone call.',
    effective_caller_num: 'Effective Caller Number',
    effective_caller_num_d: 'Phone number that is displayed on the recipient`s caller ID when a call is made.',
    outbound_caller_name: 'Outbound Caller Name',
    outbound_caller_name_d: 'Name associated with a specific phone number when it appears on the recipient`s caller ID display.',
    outbound_caller_num: 'Outbound Caller Number',
    outbound_caller_num_d: 'Phone number that is used as the calling party`s identifier when making an outgoing call.',
    call_timeout: 'Call Timeout',
    call_timeout_d: 'How long (in seconds) to ring the another leg of a call before terminating the call. ',
    record_status: 'Recording Status',
    record_status_d: 'Select the recording status as enable if you want to record the call otherwise disable the status',
    extension_status: 'Extension Status',
    extension_status_d: 'select the extension status as enable if you want to use extension otherwise disable the extenson',
    voicemail_status: 'Voicemail Status',
    voicemail_status_d: 'select the voicemail status as enable if you want to use voicemail otherwise disable the extenson',
    voicemail_email: 'Voicemail Email',
    voicemail_email_d: 'Required email for voice mail.',
    voicemail_pass: 'Voicemail Password',
    voicemail_pass_d: 'Required password for voice mail.',
    voicemail_file: 'Voicemail File',
    voicemail_file_d: '',
    voicemail_keep_local: 'Voicemail Keep Local',
    voicemail_keep_local_d: ''
  }

  // Agent
  agent_info = {
    position: 'top',
    agent_name: 'Agent Name',
    agent_name_d: 'Enter the agent name',
    agent_id: 'Agent ID', // agent id not found on UI
    agent_id_d: '',
    agent_call_timeout: 'Agent Call Timeout',
    agent_call_timeout_d: ' ',
    extension: 'Extension ID',
    extension_d: 'Select the extension for the agent',
    username: 'User Name',
    username_d: 'Enter the unique username and special characters are not allowed',
    email_id: 'Email ID',
    email_id_d: 'Enter the unique email id and the email id must be in the form of email',
    agent_role: 'Agent Role',
    agent_role_d: 'Select the role for the agent',
    agent_pass: 'Agent Password',
    agent_pass_d: 'Enter the password and the length of the password should be greater than 6 characters',
    agent_status: 'Agent Status',
    agent_status_d: '',
    agent_no_ans_delay_time: 'Agent No Answer Delay Time',
    agent_no_ans_delay_time_d: 'maximum ',
    agent_max_no_ans: 'Agent Max No Answer',
    agent_max_no_ans_d: '',
    agent_reject_delay_time: 'Agent Reject Delay Time',
    agent_reject_delay_time_d: '',
    agent_busy_delay_time: 'Agent Busy Delay Time',
    agent_busy_delay_time_d: '',
    agent_skills: 'Agent Skills',
    agent_skills_d: '',
    description:'Description',
    description_d:'Enter Description',
  }

  // Team
  team_info = {
    position: 'top',
    team_name: 'Team Name',
    team_name_d: 'Enter the team name where you can assign agents',
    supervisor: 'Supervisor',
    supervisor_d: 'Select the supervisor for the team',
    agent: 'Agent',
    agent_d: 'Select the Agent',
  }

  // Skill
  skill_info = {
    position: 'top',
    skill_name: 'Skill Name',
    skill_name_d: 'Write the skill agent possess',
    description: 'Description',
    description_d: 'Ellaborate the skills'
  }

  // Break
  break_info = {
    position: 'top',
    break_name: 'Break Name',
    break_name_d: 'Enter the break reason ',
    description: 'Description',
    description_d: 'Enter the complete description about break'
  }
  // -------------VOICE----------------

  // trunk
  trunk_info = {
    position: 'top',
    rightposition: 'right',
    name: 'Name',
    name_d: 'Enter the trunk name',
    description: 'Description',
    description_d: 'Enter the description about trunk',
    protocol: 'Protocol',
    protocol_p :'right',
    protocol_d: 'Select the protocol which will be used for the trunk ',
    transport_protocol: 'Transport Protocol',
    transport_protocol_d: 'Select the transport protocol used for the trunk',
    call_direction: 'Call Direction',
    call_direction_d: 'Select the call direction for the trunk',
    trunk_type: 'Trunk Type',
    trunk_type_d: 'Select the trunk type',
    domain_ip: 'Domain/IP:Port',
    domain_ip_d: 'Enter the domain address',
    outbound_proxy: 'Outbound Proxy',
    outbound_proxy_d: 'Enter the proxy no.',
    registration: 'Registration',
    registration_d: 'Select true for trunk registration otherwise select false',
    auth_id: 'Auth ID',
    auth_id_d: 'Enter the Auth ID',
    from_user: 'From User',
    from_user_d: '',
    expires: 'Expires',
    expires_d: '',
    max_concurrent_calls: 'Max Concurrent Calls',
    max_concurrent_calls_d: 'Concurrent calls is the total number of customer calls that your users are allowed to receive concurrently at the same time',
    max_cps: 'Max CPS',
    max_cps_d: 'Calls Per Second or CPS refers to how many telephone calls can be handled in a second.',
    dtmf_mode: 'DTMF Mode',
    dtmf_mode_d: 'Dual-Tone Multi-Frequency,Technology used with touch tone phones, best known to users as the sound made when pressing a number key.',
    cid_num: 'CID Number',
    cid_num_d: 'Caller I`d number.',
    scan_prefix: 'Scan Prefix',
    scan_prefix_d: 'To make calls through the outbound route, you need to add prefix 0 before the number.',
    strip: 'Strip',
    strip_p :'right',
    strip_d: 'Strip allows you to specify the number of digits that will be stripped from the front of the Caller ID before the call is displayed.',
    outbound_prefix: 'Outbound Prefix',
    outbound_prefix_d: 'To make calls through the outbound route, you need to add prefix  before the number.',
    outbound_suffix: 'Outbound Suffix',
    outbound_suffix_d: 'outbound suffix typically refers to a set of digits or characters appended to an outbound telephone number dialed by agents or automated systems',
    priority: 'Priority',
    priority_d: 'The destination number should start with digit 1.',
    failover: 'Failover',
    failover_p : 'right',
    failover_d: 'A method of protecting telephone numbers from ringing out of service, in which all calls can automatically be redirected to an alternate number during a system failure.',
    remote_party_id: 'Remote Party-ID',
    remote_party_id_d: ' "Remote Party ID" (RPID) refers to the information about the caller that is transmitted to the receiving party (such as another call center or phone system) during a call',
    p_asserted_identity: 'P-Asserted-Identity',
    p_asserted_identity_d: 'The address of who initiated the call from the point of view of the SIP server - aka the caller.',
    p_preferred_identity: 'P-Preferred-Identity',
    p_preferred_identity_d: 'this is used used among trusted SIP entities (typically intermediaries) to carry the identity of the user sending a SIP message as it was verified by authentication.',
    get_caller_id_from: 'Get Caller ID From',
    get_caller_id_from_d: 'Get Caller ID From',
    get_did_from: 'Get DID From',
    get_did_from_d: 'Get DID From',
    codecs: 'Codecs',
    codecs_d: 'Codecs',
    username: 'User Name',
    username_d: 'Enter User Name',
    password: 'Password',
    password_d: 'Enter Registration Password',
    caller_id_type_d:'',
    caller_id_type:'Caller ID Type',
  }

  // DID DaTA
  did_info = {
    position: 'top',
    did_number: 'DID Number',
    did_number_position : 'right',
    did_number_d: 'Enter the unique DID number ,it will accept only numeric characters',
    decription: 'Description',
    decription_d: 'Enter the description of DID numbers',
    did_status: 'Status',
    did_status_d: '',
    country: 'Country',
    country_d: 'Select country from which you belongs',
    city: 'City',
    city_d: 'Select city from which you belongs',
    provider_name: 'Provider Name',
    provider_name_d: 'Refers to the company name that supply and manages the phone number.',
    map_did_with: 'Map Number With',
    map_did_with_d: 'Whom do you want to map the number with. ',
    extension: 'Extension',
    extension_d: 'Select agent extension ',
    call_queue: 'Call Queue',
    call_queue_D: 'select queue from the option',
    ivr: 'IVR',
    ivr_d: 'Select IVR from the list',
    campaign: 'Campaign',
    campaign_d: 'Select campaign from the option',
    voicemail: 'Voicemail',
    voicemail_d: 'Select voicemail from the option',
    ring_time: 'Ring Time',
    ring_time_d: 'Call Ringing time',
    ring_strategy: 'Ring Strategy',
    ring_strategy_d: 'Ring Strategy',
    external_num: 'External Number',
    external_num_d: 'Enter phone number and only numeric values are allowed ',
    trunk: 'Trunk',
    trunk_d: 'Select the created trunk ',
    import_csv_file: 'Import Csv File',
    import_csv_file_d: 'Import Csv File and imported file format should be same as Download sample file ',

  }

  // MUSIC on HOLD
  music_on_hold = {
    position: 'top',
    name: 'Name',
    name_d: 'Enter the MOH name',
    audio_file: 'Audio File',
    audio_file_d: 'Select Audio file with 8000hz in WAV/MP3 format'
  }

  // PROMPT
  prompt_info = {
    position: 'top',
    name: 'Name',
    name_d: 'Enter the prompt name',
    type: 'Type',
    type_d: 'Enter the prompt type',
    description: 'Description',
    description_d: 'Enter the complete description of prompt',
    audio_file: 'Audio File',
    audio_file_d: 'Select Audio file with 8000hz in WAV/MP3 format'
  }
   // ##Conference###
  conference_pop_info ={
    position: 'top',
    start_date_time:'Start Date & Time',
    start_date_time_d: 'This shows starting date and time.',
    end_date_time:'End Date & Time',
    end_date_time_d: 'This shows end date and time.',
    campaign:'Campaign',
    campaign_d:'This shows list of campaigns and users can select from it.',
    did_number:'DID Number',
    did_number_d:'This shows DID number.',
    call_direction:'Call Direction',
    call_direction_d:'This show direction of the call i.e. inbound, outbound.',
    conference_number:'Conference Number',
    conference_number_d:'Conference Number',
  }
  // FAX
  fax_info = {
    position: 'top',
    name: 'Name',
    name_d: '',
    extension: 'Extension',
    extension_d: '',
    description: 'Description',
    description_d: '',
    acc_code: 'Account Code',
    acc_code_d: '',
    destination_num: 'Destination Number',
    destination_num_d: '',
    prefix: 'Prefix',
    prefix_d: '',
    email: 'Email',
    email_d: '',
    caller_id_name: 'Caller ID Name',
    caller_id_name_d: '',
    caller_id_number: 'Caller ID Number',
    caller_id_number_d: '',
    forward_number: 'Forward Number',
    forward_number_d: '',
    greeting: 'Greeting',
    greeting_d: '',
    number_of_channels: 'Number of Channels',
    number_of_channels_d: ''
  }

  // Conference
  conference_info = {
    position: 'top',
    start_date_time: 'Start Date & Time',
    start_date_time_d: '',
    end_date_time: 'End Date & Time',
    end_date_time_d: 'This shows end date and time.',
    campaign: 'Campaign',
    campaign_d: '',
    did_num: 'DID Number',
    did_num_d: '',
    call_direct: 'Call Direction',
    call_direct_d: '',
    conference_num: 'Conference_num',
    conference_num_d: '',
  }

  // priority
  priority_info = {
    position: 'top',
    priority_num: 'Priority Number',
    priority_num_d: 'Enter the priority number such that it will appear first on dialler',
  }

  // Time Condition
  time_condition = {
    position: 'right',
    name: 'Name',
    name_d: 'Enter the time condition name and it should contain only alphabets',
    description: 'Description',
    description_d: 'Enter the complete description of time condition ',
    status: 'Status',
    status_d: 'Select time condition status as active or inactive',
    holiday_name: 'Holiday Name',
    holiday_name_d: 'Select the holiday name',
    holiday_date: 'Holiday Date',
    holiday_date_id: 'Select the holiday start date', 
    repeat: 'Repeat',
    repeat_d: 'Repeat Holiday for particular date & time in future',
    holiday_status: 'Holiday Status',
    holiday_status_d: 'Select the holiday status as active or not',
    holiday_end_date:'Holiday End Date',
    holiday_end_date_d:'Select the holiday end date',
  }
  // -------------SMS & Email------------

  // SMS Gateway
  sms_gateway = {
    position: 'top',
    gateway_name: 'Gateway Name',
    gateway_name_d: 'Name of the Gateway.',
    gateway_url: 'Gateway URL',
    gateway_url_d: 'Path of the Sms Gateway.',
    gateway_phone_no_param: 'Gateway Phone Number Parameter',
    gateway_phone_no_param_d: 'Describe the phone number for the gateway',
    gateway_msg_param: 'Gateway Message Parameter',
    gateway_msg_param_d: 'Describe the message for the gateway.',
    gateway_from_num: 'Gateway From Number',
    gateway_from_num_d: 'Gateway From Number',
    gateway_from_num_param: 'Gateway From Number Parameter',
    gateway_from_num_param_d: 'Describe the phone number for the gateway.',
    sms_gateway_type: 'SMS Gateway Type',
    sms_gateway_type_d: 'Describe the type of gateway.',
    auth_username_nexmo: 'Auth User ame For Nexmo',
    auth_username_nexmo_d: 'Authentication username for sms gateway',
    auth_password_nexmo: 'Auth Password For Nexmo',
    auth_password_nexmo_d: 'Authentication password for sms gateway',
    auth_username_twilio: 'Auth User name For Twilio',
    auth_username_twilio_d: 'Authentication username for sms gateway',
    auth_password_twilio: 'Auth Password For Twilio',
    auth_password_twilio_d: 'Authentication password for sms gateway',
    auth_username_wesender: 'Auth User name For Wesender.co.ao',
    auth_username_wesender_d: 'Authentication username for sms gateway',
    auth_password_wesender: 'Auth Password For Wesender.co.ao',
    auth_password_wesender_d: 'Authentication password for sms gateway',
    auth_Username_For:'Auth User name For',
    auth_Username_For_d:'Authentication username for sms gateway',
    auth_password:'Auth Password For',
    auth_password_d:'Authentication password for sms gateway',
    queue_d:'',
    queue:'Queue',
    sub_account_id:'Sub Account ID',
    sub_account_id_d:'Enter sub account id',
    token:'Token',
    token_d:'Enter the secret code ',
    source:'Source',
    source_d:'Enter source name',
    description:'Description',
    description_d:'Enter some short description about sms gateway type',
    configuration_name_d:'',
    configuration_name:'Configuration Name',
    // gateway_from_num_d:'',
    auth_username_for:'Auth Username for',
    auth_username_for_d:'',
    auth_password_for:'Auth Password for',
    auth_password_for_d:'',
  }
  sms_temp={
    position: 'top',
    template_name_d:'',
    template_name:'Template Name',
    description_d:'',
    description:'Description',
    text_message_d:'',
    text_message:'Text Message',
    smsgateway:'SMS Gateway',
    smsgateway_d:'',
  }
  // Email SMTP
  mail_smtp = {
    position: 'right',
    mail_id: 'Mail ID',
    mail_id_d: 'SMTP Mail id',
    mail_server: 'Mail Server',
    mail_server_d: 'Mail Server id',
    password: 'Password',
    password_d: 'SMTP credentials.',
    port: 'Port',
    port_d: 'Port number of the email',
    from:'From',
    from_d:'Sender`s email.',
  }

  // Mail Template

  mail_template = {
    position: 'top',
    template_name: 'Template Name',
    template_name_d: 'Sets the template name.',
    subject: 'Subject',
    subject_d: 'Purpose for making the template.',
    body: 'Body',
    body_d: 'Defines the template description.',
    mail_id:'Mail ID',
    mail_id_d: 'Template Mail id',
    attachments_d:'',
    attachments : 'Attachments',
    template_d:'',
    template :'Template Body',
    signature_d:'',
    signature : 'Signature',
    attachment_type:'Attachments Type',
    attachment_type_d :'',
  }
  // -------------Appointments----------
  // Sales Team
  sales_team = {
    position: 'top',
    team_name: 'Team Name',
    team_name_d: '',
    status: 'Status',
    status_d: ''
  }

  // Planning
  planning_info = {
    position: 'top',
    event_title: 'Event Title',
    event_title_d: '',
    agent_name: 'Agent Name',
    agent_name_d: '',
    start_date_time: 'Start Date & Time',
    start_date_time_d: '',
    end_date_time: 'End Date & Time',
    end_date_time_d: ''
  }

  // ------------Queues-----

  // ivr
  ivr_info = {
    position: 'top',
    name: 'Name',
    name_d: 'Enter IVR name',
    extension: 'Extension',
    extension_d: 'Enter the IVR extension ',
    greet_long: 'Greet Long',
    greet_long_d: 'Enter the greeting and select the prompts',
    greet_short: 'Greet Short',
    greet_short_d: 'Enter the greeting and select the prompts',
    timeout: 'Timeout',
    timeout_d: 'Enter the timeout ',
    exit_action: 'Exit Action',
    exit_action_d: 'Select the exit action from the list',
    exit_name: 'Exit Name',
    exit_name_d: 'Select the exit name from the list',
    enabled: 'Enabled',
    enabled_d: 'select ivr as enabled or disabled',
    description: 'Description',
    description_d: 'Enter the ivr description',
    direct_dial: 'Direct Dial',
    direct_dial_d: 'select the call setting direct dial as true or false',
    ring_back: 'Ring Back',
    ring_back_d: 'A caller ringback tone (RBT) is the sound a caller hears while waiting for a phone to be answered.Select the caller tune for ringback',
    caller_id_name_prefix: 'Caller ID Name Prefix',
    caller_id_name_prefix_d: 'Provides a way to tag certain calls, achieved by changing the caller ID that is shown on your VoIP device.',
    invalid_sound: 'Invalid Sound',
    invalid_sound_d: 'Select Invalid Sound from the list',
    exit_sound: 'Exit Sound',
    exit_sound_d: 'Select Exit Sound from the list of sounds.',
    call_back_type: 'Call Back Type',
    call_back_type_d: 'Selects the callback type true or false.',
    max_failures:'Max Failures',
    max_failures_d:'It  shows the maximum number of failures ',
    Max_Timeout:'Max Timeout',
    Max_Timeout_d:' The maximum amount of time a user session can be active before the Agent challenges the user to reauthenticate.',
    
  }

  // Call Queue
  call_queue = {
    position: 'top',
    queue_name: 'Queue Name',
    queue_name_d: 'A queue is one line of callers who are waiting for an agent to receive the call.A call can only be in one queue at a time',
    description: 'Description',
    description_d: 'Queue Description',
    strategy: 'Strategy',
    strategy_d: 'Selects the type of startegy (round-robin, ring all or something else).',
    agent_skill: 'Agent Skills',
    agent_skill_d: 'Set the agent skills according to the call type.',
    music_on_hold: 'Music on Hold',
    music_on_hold_d: 'Music On Hold (MOH) is an audio service that plays music to callers while they are placed on hold. ',
    record: 'Record',
    record_d: '',
    queue_wrap_time: 'Queue Wrap Time',
    queue_wrap_time_d: '',
    max_wait_time: 'Max Wait Time',
    max_wait_time_d: 'Maximun waiting time when an agent is available.',
    max_wait_time_with_no_agent: 'Max Wait Time with No Agent',
    max_wait_time_with_no_agent_d: 'Maximun waiting time when an agent is not available.',
    max_wait_time_with_no_agent_reached: 'Max Wait Time with No Agent Time Reached',
    max_wait_time_with_no_agent_reached_d: 'Max Wait Time with No Agent Time Reached',
    callback: 'Callback',
    callback_d: 'Select automatic callback scheduled option when agent is not available ',
    callback_ivr: 'Callback IVR',
    callback_ivr_d: 'Callback IVR is used by contact centers to give callers the option to save their place in the call queue and receive a call back when an agent becomes available',
    welcome_music :'Welcome Music',
    welcome_music_d :'',
    exit_action_d : 'Select the exit action from the list',
    exit_action : 'Exit Action',
    exit_name_d : 'Select the exit name from the list',
    exit_name : 'Exit Name',

  }
  // EMail/chat
  Email_queues = {
    position: 'top',
    name: 'Name',
    name_d: 'Name of the queue.',
    description: 'Description',
    description_d: 'Describe the reason for making the queue.',
    header_color: 'Header Color',
    header_color_d: 'This is used to set the header color of email.',
    border_color: 'Border Color',
    border_color_d: 'This is used to set the border color of email.',
    header_font_color: 'Header Font Color',
    header_font_color_d: 'This is used to set the text color of email.',
    horizontal_widget_position: 'Horizontal Widget Position',
    horizontal_widget_position_d: 'Horizontal Widget Position',
    vertical_widget_position: 'Vertical Widget Position',
    vertical_widget_position_d: 'Vertical Widget Position.',
    allowed_ip: 'Allowed IP/Domain',
    allowed_ip_d: 'Ip/Domain.',
    button_name: 'Button Name',
    button_name_d: 'Button Name.',
    header_name: 'Header Name',
    header_name_d: 'Headder Name.',
    list: 'List',
    list_d: 'List',
    email:'Email',
    email_d:'Email',
    default_strategy :'Default Strategy',
    default_strategy_d :'Select default strategy',
    mail_credentials:'Mail Credentials ',
    mail_credentials_d:'Enter mail smtp',
    default_template:'Default Template',
    default_template_d:'Enter default template',
    max_allowed_emails_d:'Select  the maximum number of allowed emails ',
    max_allowed_emails :'Maximum Allowed Emails',

  }
  Ticket_category = {
    position: 'top',
    category: 'Category',
    category_d: 'Enter Ticket Category',
    description: 'Description',
    description_d: 'Enter Description',
    due: 'Due Day',
    due_d: 'Enter due day',
    priority:'Priority',
    priority_d:'',
    priority_level:'Priority Level',
    priority_level_d:'Lower Value means High Priority',
    name_d : 'Enter name',
    name : 'Name'
  }

  // SMS Queue
  sms_queue = {
    position: 'top',
    name: 'Name',
    name_d: 'Sets the Queue name.',
    description: 'Description',
    description_d: 'Subject for making the queue.',
    gateway: 'Gateway',
    gateway_d: 'Select the gateway from dropdown ',
    status: 'Status',
    status_d: 'SMS Queues Status.'
  }

  // social Queues
  social_queues = {
    name:'Name',
    name_d:'Queue Name',
    position: 'top',
    queue_name: 'Social Campaign Name',
    queue_name_d: 'Used to define the social Queue name.',
    map_with: 'Map With',
    map_with_d: 'This is used to attach the queue with (facebook, whatsapp or twitter).',
    integration_id: 'Integration ID',
    integration_id_d: 'Select integration id  ',
    queue: 'Queue',
    queue_d: 'This option allows you to select a queue',
    startegy: 'Strategy',
    startegy_d: '',
    script: 'Script',
    script_d: '',
    survey: 'Strategy',
    survey_d: '',
    disposition: 'Disposition',
    disposition_d: 'To give feedback on the interaction between the agent and the customer ',
    break: 'Break',
    break_d: 'Break are often used when agent are out of service for a particular reason',
    break_req_approval: 'Break Request Approval',
    break_req_approval_d: 'Before going on break agent need to to take approval from supervisor.',
    crm: 'CRM',
    crm_d: 'This option allows you to select CRM from the list.',
    timeout: "Timeout",
    timeout_d: "Timeout",
    description:'Description',
    description_d:'Describes the internal detailing of social queue for what reason the queue is made .',
    sys_auto_wrap:'System Auto Wrap-up',
    sys_auto_wrap_d:'Queue startegy based on which chat is assigned to the available agent in queue',
    strategy:'Strategy',
    strategy_d:'Strategy',
    number_masking: 'Number Masking',
    number_masking_d:'Masked phone numbers-also referred to as proxy phone numbers-are used to hide the private telephone numbers of people participating in a conversation via voice calls or text messages.',
    camp_wrap_up_time: 'ACW time',
    camp_wrap_up_time_d: "'amount of time needed by an agent to complete any extra tasks that are directly associated with the call that was just completed' '*ACW Time  should be entered in seconds(5 sec)'"
  }

  // tickets
  ticket_info = {
    position: 'top',
    customer_number: 'Customer Number',
    customer_number_d: 'This is used to check the ticket according to the customer number.',
    status: 'Ticket Status',
    status_d: 'Ticket status is each stage a ticket goes through in its life cycle (open or closed).',
    priority: 'Priority',
    priority_d: 'Ticket priority is the level of urgency assigned to a customer support ticket.',
    decription: 'Description',
    description_d: 'This is used to ellaborate the query of the customer / ticket .',
    asigne_to: 'Asigne To',
    asigne_to_d: 'This is used to assign a ticket to a particular agent.',
    comment:'Comment',
    comment_d:'Open Ticket Comment.',
  }
  // Campaign--

  // voice broadcast
  voice_broadcast = {
    position: 'top',
    positionRight: 'right',
    name: 'Name',
    name_d: 'Voice Broadcast name',
    description: 'Description',
    description_d: 'Broadcast description name',
    trunk: 'Trunk',
    trunk_d: 'Trunks are phone lines that transmit calls over the Internet',
    trunk_position:'right',
    prefix: 'Prefix',
    prefix_d: 'Prefix used before Voice Broadcast number',
    amd: 'AMD',
    amd_d:'Answering Machine Detection (AMD) can be used by an outbound contact center to increase the efficiency of your agents by avoiding consuming agent time with calls answered by a machine',
    broadcast_frequency: 'Broadcast Frequency',
    broadcast_frequency_d:'Broadcast Frequency in telephony refers to the rate at which calls or messages are transmitted or delivered within a specific time frame. ',
    broadcast_max_concurrent_calls: 'Broadcast Max Concurrent Calls ',
    broadcast_max_concurrent_calls_d: 'concurrent calls  are the amount of simultaneous calls you would like to send while the campaign is running. ',
    caller_id: 'Caller ID ',
    caller_id_d: 'Contactlist shows the list of contact .',
    caller_name: 'Caller Name',
    caller_name_d: 'Caller name refers to the identification of the person or entity associated with a specific phone numb',
    no_answer_timeout: 'No Answer Timeout ',
    no_answer_timeout_d: 'The "Answer Timeout" refers to the maximum duration within which an incoming call must be answered before it is automatically terminated or redirected.',
    max_call_retries: 'Max Call Retries',
    max_call_retries_d: 'Max Call Retries in telephony refers to the maximum number of attempts made by a telephony system to establish a successful connection with a called party or destination.',
    delay_before_retries: 'Delay Before Retries',
    delay_before_retries_d:" 'Delay Before Retries in telephony refers to the duration of time that the telephony system waits before making a retry attempt after a failed call initiation.  '*Delay before retries should be mentioned in seconds (10 secs)'",
    time_condition: 'Time Condition',
    time_condition_d: 'Time conditions is used to manage call flow based time and date',
    broadcast_with: 'Broadcast With ',
    broadcast_with_d: 'Broadcast With',
    time_zone:'Time Zone',
    time_zone_d: 'Fill the Timezone according to tour country/Place',
    contact_list: 'Contact List',
    contact_list_d: 'Contact Lists are tables that contain customer contact information, including telephone numbers to be called during an Voice Brodcasts.',
    weekday_start: 'Weekday Start',
    weekday_start_d: 'Weekday Start Broadcasts ',
    weekday_end: 'Weekday End',
    weekday_end_d: 'Weekday End',
    start_date: 'Start Date',
    start_date_d: 'Broadcast Start Date',
    end_date: 'End Date',
    end_date_d: 'Broadcast End Date',
    start_time: 'Start Time',
    start_time_d: 'Broadcast Start TIme',
    end_time: 'End Time',
    end_time_d: 'Broadcast End TIme',
    prompts:'Prompts',
    prompts_d:'Prompts',
    ivr:'IVR',
    ivr_d: 'IVR',
    frequency:'Frequency',
    frequency_d: 'Frequency',
    schedule_d :'Schedule voice broadcast.',
    schedule :'Schedule',
    Voice_Queue_info:'Voice Queue',
    Voice_Queue_info_d:'',
  }

  // web survey
  web_survey = {
    position: 'top',
    survey_name: 'Survey Name',
    survey_name_d: 'Sets the name of web survey.',
    campaign: 'Campaign',
    campaign_d: 'This shows list of campaigns and users can select from it.',
    survey_template: 'Survey Template',
    survey_template_d: 'Survey Template',
    start_date: 'Start Date',
    start_date_d: 'Sets the Survey start date.',
    expiry: 'Expiry',
    expiry_p :'right',
    expiry_d: 'Sets the expiration time of the survey.',
    survey_url_d: 'This shows survey url',
    survey_url :'Survey url',
    email_template_d:'Select Email Template',
    email_template :'Email Template'

  }

  // Email Campiagn
  mail_campaign = {
    position: 'top',
    name: 'Name',
    name_d: '',
    decription: 'Description',
    decription_d: 'Enter Email campaign.',
    contact: 'Contact',
    contact_d: '',
    enabled: 'Enabled',
    enabled_d: '',
    time_zone: 'Time Zone',
    time_zone_d: 'Selects the time-zone according to the map',
    template: 'Template',
    template_d: '',
    start_date: 'Start Date',
    start_date_d: '',
    end_date: 'End Date',
    end_date_d: '',
    start_time: 'Start Time',
    start_time_d: '',
    mail_frequency:'Mail Frequency',
    mail_frequency_d:'',
    camp_wrap_up_time: 'ACW time',
    camp_wrap_up_time_d: "'amount of time needed by an agent to complete any extra tasks that are directly associated with the call that was just completed' '*ACW Time  should be entered in seconds(5 sec)'",
    campaign_name :'Campaign Name',
    campaign_name_d :'This shows list of campaigns and users can select from it',
    email_queue_uuid_d :'Email Queue is a system or mechanism that manages and organizes   Email from customers or users',
    email_queue_uuid : 'Email Queue',
    time_condition_d:'Time conditions is used to manage call flow based on time and date',
    time_condition : 'Time Condition',
    break: 'Break',
    break_p :"right",
    break_d: 'Break are often used when agent are out of service for a particular reason',
    break_req_approval: 'Break Request Approval',
    break_req_approval_d: 'Before going on break agent need to to take approval from supervisor.',
    disposition: 'Disposition',
    disposition_d: 'To give feedback on the interaction between the agent and the customer ',
  }

  webchat_campaign = {
    position: 'top',
    name: 'Campaign Name',
    name_d: 'This shows name of associated campaign',
    decription: 'Description',
    description_d: 'This field shows additional information about webchat',
    queue: 'Webchat Queue',
    queue_d: 'Select webchat queue from the list',
    disposition_map: 'Disposition',
    disposition_map_d: 'To give feedback on the interaction between the agent and the customer',
    break: 'Break',
    break_p :"right",
    break_d: 'Break are often used when agent are out of service for a particular reason',
    break_req_approval: 'Break Request Approval',
    break_req_approval_d: 'Before going on break agent need to to take approval from supervisor.',
    crm :'CRM',
    crm_d :'This option allows you to select CRM from the list.',
    wrap_up_time: 'ACW time',
    wrap_up_time_d: "'amount of time needed by an agent to complete any extra tasks that are directly associated with the call that was just completed' '*ACW Time  should be entered in seconds(5 sec)'",
    
  }


  webchat_queue={
    position: 'top',
    name: 'Campaign Name',
    name_d: '',
    decription: 'Description',
    description: 'Enter Description',
    integration_id: 'Integration Id',
    integration_id_d: 'Select integration id  ',
    timeout: 'Auto Dispose',
    timeout_d: 'in seconds',
    strategy:'Strategy',
    strategy_d:''
  }

  webchat_bot={
    position: 'top',
    condition: 'Condition',
    condition_d: '',
    condition_data: 'Condition Data',
    condition_data_d: '',
    condition_type: 'Condition Type',
    condition_type_d: '',
    queue_d:'',
    queue:'Queue',
  }

  email_broadcast = {
    position: 'top',
    name: 'Name',
    name_d: 'Enter Email Broadcast',
    decription: 'Description',
    description: 'This shows about email broadcast',
    contact: 'contact',
    contact_d: 'Select required contact from the list',
    enabled: 'Enabled',
    enabled_d: '',
    time_zone: 'Time Zone',
    time_zone_d: 'Selects the time-zone according to the map',
    template: 'Template',
    template_d: 'Enter the template ',
    start_date: 'Start Date',
    start_date_d: 'This shows start date',
    end_date: 'End Date',
    end_date_d: 'This shows end date',
    start_time: 'Start Time',
    start_time_d: 'This shows start time',
    mail_frequency:'Mail Frequency',
    mail_frequency_d:'Sets the number of emails per second ',

  }

  // SMS Campaign
  sms_campaign = {
    position: 'top',
    Rightposition: 'right',
    name: 'Name',
    name_d: 'Name of the SMS campaign.',
    decription: 'Description',
    decription_d: 'Ellaborates the need for making the campaign.',
    contact: 'Contact',
    contact_d: 'Select required contact from the list',
    enabled: 'Enabled',
    enabled_d: 'Selects the campaign status true or false.',
    sms_frequency: 'SMS Frequency',
    sms_frequency_d: 'Sets the number of messages per second.',
    time_zone:'Time Zone',
    time_zone_d: 'Selects the time-zone according to the map.',
    type: 'Type',
    type_d: 'This is used to set campaign type either outbound or inbound.',
    sms_api: 'SMS Api',
    sms_api_d: 'This is used to select the SMS gateway.',
    weekday_start: 'Weekday Start',
    weekday_start_d: 'Weekday Start',
    weekday_end: 'Weekday End',
    weekday_end_d: 'Weekday End',
    start_date: 'Start Date',
    start_date_d: 'Sets the start date of campaign.',
    end_date: 'End Date',
    end_date_d: 'Sets the end date of camapaign.',
    start_time: 'Start Time',
    start_time_d: 'Start Time',
    msg: 'Message',
    msg_d: 'Campigns Message',
  }

  // Typification
  // classification
  classification_info = {
    position: 'top',
    classification: 'Classification',
    classification_d: ''
  }

  // Typification
  typification_info = {
    position: 'top',
    classification: 'Classification Name',
    classification_d: '',
    level_one: 'Level One',
    level_one_d: 'Level One',
    level_two: 'Level Two',
    level_two_d: 'Level Two',
    level_three: 'Level Three',
    level_three_d: 'Level Three',
    level_four: 'Level Four',
    level_four_d: 'Level Four',
    level_five: 'Level Five',
    level_five_d: 'Level Five',
    import_file: 'Import File',
    import_file_d: 'Import File',
  }

  // Template
  template_typification = {
    position: 'top',
    template_name: 'Template Name',
    template_name_d: '',
    map_with: 'Map With',
    map_with_d: '',
    campaign: 'Campaign',
    campaign_d: '',
    whatsapp: 'Whats App Chat',
    whatsapp_d: '',
    facebook: 'Facebook Chat',
    facebook_d: '',
    twitter: 'Twitter Chat',
    twitter_d: '',
    classifi: 'Classification',
    classifi_d: '',
  }

  // disposition
  disposition_info = {
    position: 'top',
    disposition_name: 'Disposition Name',
    disposition_name_d: 'Name of the disposition.',
    description: 'Description',
    description_d: 'Description of the disposition.',
    human_ans: 'Human Answer',
    human_ans_d: '',
    sales: 'Sales',
    sales_d: '',
    dnc: 'DNC',
    dnc_d: 'Do Not Call (DNC) lists against which the calling records to be dialed during the given campaign will be checked',
    customer_cont: 'Customer Contact',
    customer_cont_d: '',
    not_interest: 'Not Interested',
    not_interest_d: '',
    unworkable: 'Unworkable',
    unworkable_d: '',
    callback: 'Callback',
    callback_d: 'Select the callback .',
    completed: 'Completed',
    completed_d: '',
    ans_machine: 'Answering Machine',
    ans_machine_d: '',
    call: 'Call',
    call_d: '',
    chat: 'Chat',
    chat_d: '',
    sms: 'SMS',
    sms_d: '',
    facebook: 'Facebook',
    facebook_d: '',
    whatsapp: 'Whatsapp',
    whatsapp_d: '',
    button_color: 'Button Color',
    button_color_d: '',
    button_type: 'Button Type',
    button_type_d: '',
    smstemplate_d:'',
    smstemplate:'SMS Template',
    smstemplate_status_d:'',
    smstemplate_status:'SMS Template Status',
  }

  // JOB
  job_info = {
    position: 'top',
    active: 'Active/Inactive',
    active_d: '',
    campaign: 'Campaign',
    campaign_d: '',
    email_id: 'Email ID',
    email_id_d: '',
    concurrent_calls: 'Concurrent Calls',
    concurrent_calls_d: 'Concurrent calls is the total number of customer calls that your users are allowed to receive concurrently at the same time',
    ans_timeout: 'Answer Timeout',
    ans_timeout_d: 'The "Answer Timeout" refers to the maximum duration within which an incoming call must be answered before it is automatically terminated or redirected.',
    ring_timeout: 'Ring Timeout',
    ring_timeout_d: '',
    dial_ratio: 'Dial Ratio',
    dial_ratio_d: '',
    max_try: 'Max Try',
    max_try_d: '',
    start_time: 'Start Time',
    start_time_d: '',
    end_time: 'End Time',
    end_time_d: '',
    start_day: 'Start Day',
    start_day_d: '',
    end_day: 'End Day',
    end_day_d: '',
    start_date: 'Start Date',
    start_date_d: '',
    end_date: 'End Date',
    end_date_d: '',
    start_month: 'Start Month',
    start_month_d: '',
    end_month: 'End Month',
    end_month_d: '',
  }

  // duplicate Campaign
  duplicate_camp = {
    position: 'top',
    source_camp: 'Source Campaign',
    source_camp_d: '',
    name: 'Name',
    name_d: '',
    trunk: 'Trunk',
    trunk_d: 'Trunks are phone lines that transmit calls over the Internet',
  }

  // outbound/inbound/blended Campaign
  campaigns_info = {
    position: 'top',
    name: 'Name',
    name_d: 'Name of the campaign.',
    description: 'Description',
    description_d: 'Complete description of campaign.',
    hopper: 'Hopper',
    hopper_p : 'right',
    hopper_d: 'The hopper is a container for the leads that are in line to be dialed by the system.',
    amd: 'AMD',
    amd_d: 'Answering Machine Detection (AMD) can be used by an outbound contact center to increase the efficiency of your agents by avoiding consuming agent time with calls answered by a machine',
    script: 'Script',
    script_d: 'A script is carefully designed document that guides call center representatives as they interact with customers.',
    web_survey: 'Web Survey ' ,
    web_survey_d :'Select web survey',
    typification:'Typification',
    typification_d: 'Select Typification',
    camp_status : "Campaign Status",
    camp_status_d : 'This shows status of campaign',
    time_zone:'Time Zone',
    time_zone_d: 'Fill the Timezone according to your country/Place',
    failover_trunk:'Failover Trunk',
    failover_trunk_d: 'If trunk fails then it can use fail over tunk.',
    outbound_caller_id:'Outbound Caller ID',
    outbound_caller_id_d: 'Outbound caller ID refers to the phone number or identity displayed on the recipient `s caller ID display when a call is made from a specific phone or line',
    time_between_retries:'Time Between Retries',
    time_between_retries_d:'The time between retries refers to the duration or interval between successive attempts to establish a connection or complete a call when an initial attempt fails. ',
    max_abandon_timeout:'Max Abandon Timeout',
    max_abandon_timeout_d:'The "Max Abandon Timeout" refers to the maximum duration that a caller is allowed to wait in a queue or on hold before they abandon the call. ',
    abandon_action:'Abandon Action',
    abandon_action_d:'The term "Abandon Action" refers to the action taken by the system when a caller chooses to abandon or hang up a call while waiting in a queue or on hold.',
    caller_id_group:'Caller ID Group',
    caller_id_group_d:'',
    input_caller_id:'Input Caller ID',
    input_caller_id_d:'',
    use_agent_caller_id:'Use Agent Caller ID',
    use_agent_caller_id_d:'',
    use_trunk_caller_id:'Use Trunk Caller ID',
    use_trunk_caller_id_d:'',
    ivr:'IVR',
    ivr_d:'',
    voicemail:'Voicemail',
    voicemail_d:'',
    routing_strategy:'Routing Strategy',
    routing_strategy_d: ' Select routing strategy.',
    caller_id_group_name:'Caller ID Group Name',
    caller_id_group_name_d:'',
    add_number:'Add Number',
    add_number_d: 'This is used to add the number to the group',
    number:'Number',
    number_d:'Select numbers ',
    priority:'Priority',
    priority_d:'Enter the priority number such that it will appear first ',
    destination_condition:'Destination Condition',
    destination_condition_d:'This shows destination condition',
    value:'Value',
    dialer_status_d:'This shows dialer status',
    dialer_status:'Dialer Status',
    value_d:'Enter value',
    select_cli:'Select CLI',
    select_cli_d:'Select CLI from here',
    strategy:'Strategy',
    strategy_d:'Selects the type of startegy (round-robin, ring all or something else).',
    contact_list:'Contact List',
    contact_list_d:'Contact Lists are tables that contain customer contact information, including telephone numbers to be called during an outbound campaign',
    template_name:'Template Name',
    template_name_d:'Enter the template name according to your need.',
    call_response:'Call Response',
    call_response_d:'',
    after_attempts:'After # of Attempts',
    after_attempts_d:'',
    actions:'Actions',
    actions_d:'',
    after:'After',
    after_d:'',
    dropped_call_percentage:'Dropped Calls Percentage',
    dropped_call_percentage_d:'',
    preview_time:'Preview Time(sec)',
    preview_time_d:'',
    time_condition: 'Time Condition',
    time_condition_d: 'Time conditions is used to manage call flow based on time and date.',
    dnc_check: 'DNC Check',
    dnc_check_d: 'Do Not Call (DNC) lists will check the calling records to be dialed during the given campaign ',
    crm: 'CRM',
    crm_d: 'This option allows you to select CRM from the list.',
    crm_Name: 'CRM Name',
    crm_Name_d: '',
    crm_url: 'URL',
    crm_url_d: '',
    custom_crm: 'Custom CRM',
    custom_crm_d: 'This option allows you to select custom CRM from the list.',
    preference_crm: 'Preference CRM',
    preference_crm_d: 'This option allows you to select preference CRM from the list.',
    contact_filter: 'Contact Filter',
    contact_filter_d: 'A contact list filter lets you select accounts based on data elements found in the contact list.',
    list_order: 'List Order',
    list_order_d: 'Contact Lists are tables that contain customer contact information, including telephone numbers to be called during an outbound campaign',
    contact_order: 'Contact Order',
    contact_order_d: 'This is used to select the order of the contact number (ascending , descending , random or based on priority).',
    appointments: 'Appointments',
    appointments_d: '',
    cont_count_alert: 'Contact Count ALert',
    cont_count_alert_d: '',
    dialer: 'Dialer',
    dialer_p :'right',
    dialer_d: 'Dialer feature basically enables users to input and transmit phone numbers or dialing commands to establish telephone calls',
    allow_manual_dial: 'Allow Manual Dial',
    allow_manual_dial_d: 'The basic dialer where the agents dial the customer number manually one by one from the contact list of the customers.',
    manual_dial_list: 'Manual Dial List',
    manual_dial_list_d: 'This is used to select  a manual list of customer contacts.',
    camp_dial_list: 'Dial List',
    camp_dial_list_d: '',
    status: 'Status',
    status_d: 'This shows status of campaign',
    trunk_position:'right',
    trunk: 'Trunk',
    trunk_d: 'Trunks are phone lines that transmit calls over the Internet',
    caller_id: 'Caller ID',
    caller_id_d: '',
    caller_name: 'Caller Name',
    caller_name_d: ' caller name refers to the identification of the person or entity associated with a specific phone number',
    number_masking: 'Number Masking',
    number_masking_d: 'Call masking is a telephone feature where Callers can only see the caller ID that you set and not your actual phone number or extension',
    recording: 'Call Recording',
    recording_d: 'Call Recording basically refers to recording of the particular calls',
    call_retries: 'Call Retries',
    call_retries_d: ' Call Retries in telephony refers to the maximum number of attempts made by a telephony system to establish a successful connection with a called party or destination',
    disposition: 'Disposition',
    disposition_d: ' A method of logging the outcomes of calls—both inbound and outbound—by assigning each a particular category.',
    wrap_up_time: 'ACW Time',
    wrap_up_time_d: "'amount of time needed by an agent to complete any extra tasks that are directly associated with the call that was just completed' '*ACW Time  should be entered in seconds(5 sec)'",
    callback_type: 'Callback Type',
    callback_type_d: 'callback refers to a feature or process that allows a caller who has been unable to connect with the desired party to request a return call once the called party becomes available.',
    callback_notifi: 'Callback Notification',
    callback_notifi_d: 'callback notification refers to the process of informing the caller that their requested callback is about to be initiated or has been initiated by the telephony system. ',
    callback_notifi_time: 'Callback Notification Time',
    callback_notifi_time_d: 'callback notification time refers to the duration between the requested callback and the actual initiation of the callback by the telephony system.',
    break: 'Break',
    break_p :'right',
    break_d: 'Break are often used when agent are out of service for a particular reason.',
    with_confirmation: 'With Confirmation',
    with_confirmation_d: '',
    duration: 'Duration',
    duration_d: '',
    break_req_approval: 'Break Request Approval',
    break_req_approval_d: 'Before going on break agent need to to take approval from supervisor.',
    disposition_redial: 'Disposition',
    disposition_redial_d: '',
    time_cond_recall: 'Time Condition Recall',
    time_cond_recall_d: '',
    call_retries_redial: 'Call Retries',
    call_retries_redial_d: '',
    time_cond_redial: 'Time Condition',
    time_cond_redial_d: '',
    chat_queue: 'Web Chat',
    chat_queue_d: 'A web chat, in the context of customer service or support, refers to a system or mechanism that manages and organizes incoming chat requests from customers or website visitors. ',
    sms_queue: 'SMS Queue',
    sms_queue_d: 'SMS Queue is a system or mechanism that manages and organizes incoming SMS (Short Message Service) messages from customers or users.',
    email_queue: 'Email Queue',
    email_queue_d: 'Email Queue is a system or mechanism that manages and organizes   Email from customers or users',
    fb_queue: 'Facebook Queue',
    fb_queue_d: '',
    ticket_queue: 'Ticket Queue',
    ticket_queue_d: '',
    twitter_queue: 'Twitter Queue',
    twitter_queue_d: '',
    whatsapp_queue: 'Whatsapp Queue',
    whatsapp_queue_d: '',
    dial_list: 'Dial List',
    dial_list_d: '',
    dial_ratio: 'Dial Ratio',
    dial_ratio_d: '',
    sticky_agent: 'Sticky Agent',
    sticky_agent_d: '',
    auto_ans: 'Auto Answer',
    auto_ans_d: 'Select auto answer.',
    on_activity_type: 'On Activity Type',
    on_activity_type_d: 'Select On Activity type.',
    on_activity_name: 'On Activity Name',
    on_activity_name_d: 'Select On Activity name',
    off_activity_type: 'Off Activity Type',
    off_activity_type_d: 'Select Off Activity type',
    off_activity_name: 'Off Activity Name',
    off_activity_name_d: 'Select Off Activity name',
    activity_type: 'Activity Type',
    activity_type_d: '',
    activity_name: 'Activity Name',
    activity_name_d: '',
    concurrent_calls: 'Concurrent Calls',
    concurrent_calls_d: 'Concurrent calls is the total number of customer calls that your users are allowed to receive concurrently at the same time',
    ans_timeout: 'Answer Timeout',
    ans_timeout_d: 'The "Answer Timeout" refers to the maximum duration within which an incoming call must be answered before it is automatically terminated or redirected.',
    ring_timeout: 'Ring Timeout',
    ring_timeout_d: 'The ring timeout refers to the maximum duration that a telephone or device will ring before it stops ringing and either routes the call to voicemail or terminates the call',
    lists: 'List',
    lists_d: '',
    Voice_Queue_info:'Voice Queue',
    Voice_Queue_info_d:'',
  }
  // -----------------Security----------

  // DNC List
  dnc_list = {
    position: 'top',
    campaign: 'Campaign',
    campaign_d: 'This option allows you to set the campaign in a DNC',
    entry_type: 'Entry Type',
    entry_type_d: 'This is used to select the type of data you want to store /  fetch.',
    import_file:'Import File',
    import_file_d:'This is used to import the file from the device.',
    dnc_num:'DNC Number',
    dnc_num_d:'DNC Number',
    dnc_check: 'DNC Check',
    dnc_check_d: 'This option allows you to set the DNC type'
  }

  // Black List----
  black_list = {
    position: 'top',
    customer_name: 'Customer Name',
    customer_name_d: 'This is used to enter the name of customer to block.',
    customer_number: 'Customer Number',
    customer_number_d: 'This is used to enter the number of customer to block.',
    campaign: 'Campaign',
    campaign_d: 'This is used to select the campaign in which customer present.',
    import_file:'Import File',
    import_file_d:'This is used to import the file from the device.',
    entry_type: 'Entry Type',
    entry_type_d: 'This is used to select the type of data you want to store /  fetch.',
    black_list_check: 'Black List Check',
    black_list_check_d: 'This option allows you to set the Black List Type'
  }

  // Fraud Detection---
  fraud_detection = {
    position: 'right',
    allow_block_prefix: 'Prefix',
    allow_block_prefix_d: '',
    allow_block_status: 'Allow/Block Prefix',
    allow_block_status_d: '',
    allow_setting: 'Allow Anonymous Caller Id',
    allow_setting_d: '',
    notification_email: 'Notification Email',
    notification_email_d: '',
    status_on_off: 'Status',
    status_on_off_d: '',
  }


  //----------- Contact Management----

  // CRM
  crm_info = {
    position: 'top',
    template_name: 'Template Name',
    template_name_d: 'Enter the template name.',
    contact_list: 'Contact List',
    contact_list_d: 'Contactlist shows the list of contact .',
    queues: 'Queues',
    queues_d: '',
    whatsapp_chat: 'Whatsapp Chat',
    whatsapp_chat_d: '',
    fb_chat: 'Facebook Chat',
    fb_chat_d: ''
  }

  // contact Management
  contact_manage_info = {
    position: 'top',
    tenant: 'Tenant',
    tenant_d: '',
    start_date_time: 'Start Date & Time',
    start_date_time_d: '',
    end_date_time: 'End Date & Time',
    end_date_time_d: 'This shows end date and time.',
    first_name: 'First Name',
    first_name_d: '',
    last_name: 'Last Name',
    last_name_d: '',
    phone_num1: 'Phone Number 1',
    phone_num1_d: '',
    phone_num2: 'Phone Number 2',
    phone_num2_d: '',
    phone_num3: 'Phone Number 3',
    phone_num3_d: '',
    mobile_num: 'Mobile Number',
    mobile_num_d: '',
    phone_code: 'Phone Code',
    phone_code_d: '',
    contact_list: 'Contact List',
    contact_list_d: '',
    email: 'Email',
    email_d: '',
    address_1: 'Address 1',
    address_1_d: '',
    address_2: 'Address 2',
    address_2_d: '',
    address_3: 'Address 3',
    address_3_d: '',
    city: 'City',
    city_d: '',
    state: 'State',
    state_d: '',
    country: 'Country',
    country_d: '',
    country_code: 'Country Code',
    country_code_d: '',
    postal_code: 'Postal Code',
    postal_code_d: '',
    company_type: 'Company Type',
    company_type_d: '',
    campaign: 'Campaign',
    campaign_d: '',
    department: 'Department',
    department_d: '',
    commercial_name: 'Commercial Name',
    commercial_name_d: '',
    company_name: 'Company Name',
    company_name_d: '',
    company_reg_num: 'Company Reg Number',
    company_reg_num_d: '',
    website: 'Website',
    website_d: '',
    facebook: 'Facebook',
    facebook_d: '',
    twitter: 'Twitter',
    twitter_d: '',
    linkedin: 'Linkedin',
    linkedin_d: '',
    whatsapp: 'Whatsapp',
    whatsapp_d: '',
    gender: 'Gender',
    gender_d: '',
    head_office: 'Head Office',
    head_office_d: '',
    function: 'Function',
    function_d: '',
    vat_num: 'Vat Number',
    vat_num_d: '',
    share_capital: 'Share Capital',
    share_capital_d: '',
    no_of_employees: 'No of Employees',
    no_of_employees_d: '',
    activity: 'Activity',
    activity_d: '',
    comment: 'Comment',
    comment_d: '',
    dob: 'DOB',
    dob_d: ''
  }

  // Contact List
  contact_list = {
    position: 'top',
    name: 'Name',
    name_d: '',
    campaign: 'Campaign',
    campaign_d: ''
  }

  // filter
  filter_list = {
    position: 'right',
    name: 'Name',
    name_d: '',
    description: 'Description',
    description_d: '',
    status: 'Status',
    status_d: '',
  }
  // ----------------Omnichannel-------------

  // Smooch
  smooch_info = {
    position: 'top',
    config_name: 'Configuration Name',
    config_name_d: '',
    app_id: 'App ID',
    app_id_d: '',
    app_key: 'Chat Limit',
    app_key_d: '',
    app_secret: 'App Secret',
    app_secret_d: '',
  }

  // Facebook/whatsapp/twitter
  omnichanner_info = {
    position: 'top',
    app_name: 'App Name',
    app_name_d: 'Enter App Name',
    config_name: 'Configuration Name',
    config_name_d: 'Enter Configuration name',
    app_id: 'App ID',
    app_id_d: 'Enter App ID',
    app_key: 'Chat Limit',
    app_key_d: 'Enter Chat Limit.',
    app_secret: 'App Secret',
    app_secret_d: 'Enter App Secret',
    max_file_size: 'Max File Size In MB',
    max_file_size_d: 'Select maximum file size in mb',
    out_of_service_hour: 'Out Of Service Hour Message',
    out_of_service_hour_d: 'Enter out of service hours.',
    welcome_msg: 'Welcome Message',
    welcome_msg_d: 'Enter Welcome message.',
    whatsapp_integ_id: 'Whatsapp Integration ID ',
    whatsapp_integ_id_d: 'Enter Whatsapp Integration ID',
    facebook_integ_id: 'Facebook Integration ID ',
    facebook_integ_id_d: 'Enter Facebook Integration ID',
    twitter_integ_id: 'Twitter Integration ID ',
    twitter_integ_id_d: 'Enter Twitter Integration ID',
    monday: 'Monday',
    monday_d: '',
    tuesday: 'Tuesday',
    tuesday_d: '',
    wednesday: 'Wednesday',
    wednesday_d: '',
    thursday: 'Thursday',
    thursday_d: '',
    friday: 'Friday',
    friday_d: '',
    saturday: 'Saturday',
    saturday_d: '',
    sunday: 'Sunday',
    sunday_d: '',
    queue_d:'User can select queue from the list of queues.',
    queue:'Queue',
    Telegram_Integr_id:'Telegram Integration ID',
    Telegram_Integr_id_d:'',
    viber_integra_id:'Viber Integration ID',
    viber_integra_id_d:'',
    condition_type_d :'Select condition type from list.',
    condition_type:'Condition type',
    condition : 'Condition',
    condition_d :'Enter condition ',
    condition_data:'Condition Data',
    condition_data_d: 'Enter condition data.',
    action_type : 'Action Type',
    action_type_d : 'Select action type from the list',
    map_variable : 'Map Variable',
    map_variable_d : 'User can select map variables from the list',
    condition_api :'Condition API',
    condition_api_d:'Enter condition api',
    method :'Method',
    method_d :'Select method feom the list of methods.',
    payload_type :'Payload Type',
    payload_type_d :'Select payload type from the list',
    payload : 'Payload',
    payload_d : 'Enter payload.',
    layout_d : 'Select layout option',
    layout : 'Layout',
    button : 'Button' ,
    button_d : 'User can add buttons here. ',
    gateway_name_d: 'User can select map variables from the list',
    gateway_name : 'Map Variables'
  }

  // facebook Account
  facebook_acc = {
    position: 'right',
    app_id: 'App ID',
    app_id_d: '',
    app_name: 'App Name',
    app_name_d: '',
    app_secret: 'App Secret',
    app_secret_d: '',
  }

  // Twitter Account
  twitter_acc = {
    name: 'name',
    description: 'description',
    status: 'status',
    position: 'right',
    app_key: 'Api Key',
    app_key_d: '',
    bearer_token: 'Bearer Token',
    bearer_token_d: '',
    app_secret: 'Api Secret',
    app_secret_d: '',
    access_token: 'Access Token',
    access_token_d: '',
    token_secret: 'Token Secret',
    token_secret_d: '',
  }

  // --------------Integration------------------

  // integration_info={
  //   position:'right',
  //   loa
  // }

  // --------------------------ADMIN-------------------------

  // Scheduled callback

  scheduled_callback = {
    position: 'right',
    name: 'Name',
    name_d: '',
    number: 'Number',
    number_d: '',
    schedule_date: 'Schedule Date',
    schedule_date_d: '',
    queue: 'Queue',
    queue_d: '',
    campaign: 'Campaign',
    campaign_d: '',
    start_time: 'Start Time',
    start_time_d: '',
    end_time_d:'',
    end_time:'End Time', 
    description: 'Description',
    description_d: ''
  }

  recording_settings = {
    position: 'right',
    backup_name: 'Backup Name',
    backup_name_d: 'Set the backup name.',
    backup_location: 'Backup location',
    backup_location_d: 'This identifies the Path for the backup .',
    backup_time: 'Backup Time',
    backup_time_d: 'This is used to set the time at when the backup occur.',
    backup_frequency: 'Backup Frequency',
    backup_frequency_d: 'This specifies the number of (days, weeks or month) that must have elapsed since the last backup before the data set can be backed up again automatically.',
    backup_for_last: 'Backup For Last',
    backup_for_last_d: 'Backup For Last',
    email_address: 'Email Address',
    email_address_d: 'This specifies the email to for which the backup will occur.',
    email_notification: 'Email Notification',
    email_notification_d: 'This is used to enable the notification on your email when the backup will occur.',
    status: 'Status',
    status_d: 'Status can be selected from this option',
    // Recording Cleaner
    Clr_name:'Cleaner Name',
    Clr_name_d:'This specify the backup cleaner name.',
    Exe_frequency:'Execution Frequency',
    Exe_frequency_d:'This specifies the number of (days, weeks or month) that must have elapsed since the last backup before the data set can be backed up again automatically.',
    Exe_time:'Execution Time',
    Exe_time_d:'This is used to set the time at when the backup execution occur.',
    tenant:'Tenant',
    tenant_d:'User can select tenant from the list of  tenants',
    Rem_Reco_Olr_than:'Remove Recordings Older Than',
    Rem_Reco_Olr_than_d:'This is used to delete the backup older than the defined date(yy-mm-dd).',
    Email_Add:'Email Address',
    Email_Add_d:'This specifies the email to for which the backup will occur.',
    Email_Notifi:'Email Notification',
    Email_Notifi_d:'This is used to enable the notification on your email when the backup will occur.',
  }

  ftp_sftp = {
    position: 'right',
    account_name: 'Account Name',
    account_name_d: 'Account Holder Name',
    password: 'Password',
    password_d: 'Account Password',
    server_domain: 'Server IP/Domain',
    server_domain_d: 'Server IP/Domain',
    destination_directory: 'Destination Directory',
    destination_directory_d: 'Destination Directory'
  }

  google_drive = {
    position: 'right',
    account_name: 'Account Name',
    account_name_d: 'Account Holder Name',
    password: 'Password',
    password_d: 'Account Password',
    destination_directory_d: 'Destination Directory',
    destination_directory: 'Destination Directory',
    auth_d :'Auth Key',
    auth : 'Auth Key',
  }

  one_drive = {
    position: 'right',
    account_name: 'Account Name',
    account_name_d: 'Account Holder Name',
    password: 'Password',
    password_d: 'Account Password',
    destination_directory: 'Destination Directory',
    destination_directory_d: 'Destination Directory'
  }

  drop_box = {
    position: 'right',
    account_name: 'Account Name',
    account_name_d: 'Account Holder Name',
    password: 'Password',
    password_d: 'Account Password',
    destination_directory: 'Destination Directory',
    destination_directory_d: 'Destination Directory'
  }

  reporter_cleaner = {
    position: 'right',
    cleaner_name: 'Cleaner Name',
    cleaner_name_d: 'This specify the backup cleaner name.',
    execution_frequency: 'Execution Frequency',
    execution_frequency_d: 'This specifies the number of (days, weeks or month) that must have elapsed since the last backup before the data set can be backed up again automatically.',
    execution_time: 'Execution Time',
    execution_time_d: 'This is used to set the time at when the backup execution occur.',
    account_name: 'Account Name',
    account_name_d: 'Account Name',
    password: 'Password',
    password_d: 'Password',
    destination_directory: 'Destination Directory',
    destination_directory_d: 'Destination Directory'
  }
  // whatsapp Account
  whatsapp_acc = {
    position: 'top',
    app_id: 'App ID',
    app_id_d: 'Whatsapp ID',
    app_name: 'App Name',
    app_name_d: 'App Name',
    app_secret: 'App Secret',
    app_secret_d: '',
    chat_limit:'Chat Limit',
    chat_limit_d: 'Enter Chat limit',
    config_name:'Configuration Name',
    config_name_d: 'Enter Configuration Name',
    app_Secret: 'App Secret',
    app_Secret_d: '',
    whatsapp_integ_Id :'Whatsapp Integration Id',
    whatsapp_integ_Id_d:'Whatsapp Integration Id',
    max_file_size: 'Max File Size In MB',
    max_file_size_d: 'Max File Size.',
    out_of_service_hour: 'Out Of Service Hour Message',
    out_of_service_hour_d: 'Out Of Service Hour Message',
    welcome_mess: 'Welcome Message',
    welcome_mess_d: 'Welcome Message',
    condition: 'Condition',
    condition_d: '',
    condition_type:'Condition Type',
    condition_type_d: '',
    condition_data: 'Condition Data ', 
    condition_data_d: '',
    template_name: 'Template Name',
    template_name_d: 'Select template name',
    type:'Type',
    type_d: 'Select type of Template',
    url: 'URL',
    url_d: 'Enter url here.',
    language:'Language',
    language_d: 'Select language according to your choice.',
    from_id: 'From ID',
    from_id_d: 'Enter From ID',
    name:'Name',
    name_d: 'Sets the name of broadcast.',
    description:'Description',
    description_d: 'Description of whatsapp broadcast',
    template:'Template',
    template_d: 'Create whatsapp template',
    contact_list:'Contact List',
    contact_list_d: 'Contactlist shows the list of contact .',
    frequency: 'Frequency',
    frequency_d: 'This shows message frequency.',
    time_zone:'Time Zone',
    time_zone_d: 'Select the time-zone according to your need.',
    start_date: 'Start Date',
    start_date_d: 'Broadcast Start Date.',
    end_date: 'End Date',
    end_date_d: 'Broadcast End Date.',
    start_time: 'Start Time',
    start_time_d: 'Broadcast Start TIme',
    description_web : 'Description',
    description_web_d : 'Description of Webchat.',
    sender_domain_name : 'Sender Domain Name',
    sender_domain_name_d : 'Enter sender domain name'

  }
  // ###  Typification  ###
  typification_acc = {
    position: 'top',
    // ###  Typification  ###
    // Add New Typificaion
    classification_name:'Classification Name',
    classification_name_d:'',
    level_one:'Level One',
    level_one_d:'' ,
    level_two:'Level Two',
    level_tow_d:'',
    level_three:'Level Three',
    level_three_d:'',
    level_four:'Level Four',
    level_four_d:'',
    level_five:'Level Five',
    level_five_d:'',
    // ###Template###
    template_name:'Template Name',
    template_name_d:'',
    map_with:'Map With',
    map_with_d:'',
    campaign:'Campaign',
    campaign_d:'',
    whats_app_chat:'Whats App Chat',
    whats_app_chat_d:'',
    facebook_chat:'Facebook Chat',
    facebook_chat_d:'',
    twitter_chat:'Twitter Chat',
    twitter_chat_d:'',
    // ###Template in Modify Template#


  }
  typification_pop_acc = {
    position: 'right',
    //### Import Typification
    import_file:'Import File',
    import_file_d:'',
    // ###Classification
    classification:'Classification',
    classification_d:'',
  }
  // Script
  script_pop = {
    position: 'right',
    script_Name:'Script Name',
    script_Name_d:'',
    deflt_page:'Default Page',
    deflt_page_d:'',
  }
  // WhatsApp Bot Subscription
  WApp_Bot_Sub = {
    position:'top',
    tenant:'Tenant',
    tenant_d:'User can select tenant from the list of  tenants ',
    app_id:'Configueation Name',
    app_id_d:'Enter configuration name',
    status:'Status',
    status_d:'Status can be selected from this option',
  }
  //telegram bot subs
  whatsapp_bot={
    position: 'top',
    tenant:'Tenant',
    tenant_d:'User can select tenant from the list of  tenants',
    app_id:'App ID',
    app_id_id:'Enter app id',
    status:'Status',
    status_d:'Status can be selected from this option',
  }
  super_webchat_bot={
    position: 'top',
    tenant:'Tenant',
    tenant_d:'User can select tenant from the list of  tenants',
    app_id:'Integration ID',
    app_id_d:'Enter integration id',
    session:'Session',
    session_d:'Enter session.',
    status:'Status',
    status_d:'Status can be selected from this option',
  }
  // Proxy Nodes
  proxy_nodes ={
    position:'top',
    ip_add:'IP Address',
    ip_add_d:'An Internet Protocol  address is a unique numerical identifier for every device or network that connects to the internet',
    Port:'Port',
    Port_d:'',
    last_Chek:'Last Checked',
    last_Chek_d:'',
    location:'Location',
    location_d:'',
    speed_rating:'Speed Rating',
    speed_rating_d:'',
    protocol:'Protocol',
    protocol_d:'',
  }
  // Databases Management
  dbms_setting={
    position:'top',
    server_name:'Server Name',
    server_name_d:'Select the database server name.',
    host:'Host',
    host_d:'',
    port:'Port',
    port_d:'',
    database:'Database',
    database_d:'',
    uname:'User name',
    uname_d:'',
    Pass_int:'Password',
    Pass_int_d:'',
  }
  // Access Log
  access_log={
    position:'top',
    name:'Name',
    name_d:'',
    path:'Path',
    path_d:'',
    Modi_Date:'Modification Date',
    Modi_Date_d:'',
    size:'Size',
    size_d:'',
  }
  //live-dashboard
  live_dashboard={
    position:'right',
    agents:'Only Supervisor Team Data.' 
  }

  ticket_campaign = {
    position:'top',
    ticket_camp_name:'Ticket Campaign Name',
    ticket_camp_name_d:'This shows ticket campaign name',
    description:'Description',
    description_d:'This shows additinal information about Ticket',
    ticket_limit:'Ticket Limit',
    ticket_limit_d:'Max Number of tickets assigned to Agents',
    mail_template:'Open Ticket Template',
    mail_template_d:'Select open ticket template',
    close_mail_template:'Close Ticket Template',
    close_mail_template_d:'Select close ticket template',
    due_in:'Due Days',
    due_in_d:'Max Due Days',
    status:'Status',
    status_d:'',
    category:'Category',
    category_d:'Select category',
    timezone:'Timezone',
    timezone_d:'Fill the Timezone according to tour country/Place',
    email_credentials:'Email Credentials',
    email_credentials_d:'Select Email Credentials'
  }
//  Supports
suports_info = {
  position : 'right',
  subject_d : '',
  subject : 'Subject',
  category_d : '',
  category : 'Category',
  description : 'Description',
  description_d : '',
}
fbwall = {
  position:'right',
  post_content: 'Post Content',
  post_content_d : '',
}
};

