import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private imgData: any;
  data = new Subject();
  agentReadyStateSubject = new Subject();
  previewStatusSubject = new Subject();
  private messageSource = new BehaviorSubject<string>("");
  currentMessage = this.messageSource.asObservable();
  public userLoginType: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  public widgetContactSubject: BehaviorSubject<object> = new BehaviorSubject<object>(null);

  ref = firebase.firestore().collection('users');
  public adminLoginType: BehaviorSubject<object> = new BehaviorSubject<object>(null);

  constructor(private firestore: AngularFirestore) {
  }
  // For widget contact list
  setWidgetContact(value){
    this.widgetContactSubject.next(value);
  }
  // For dynamic tenant menus
  setTenantPermission(value) {
    this.userLoginType.next(value);
  }
  setAdminLogin(value) {
    this.adminLoginType.next(value);
  }
  getUsers() {
    return this.firestore.collection('users').snapshotChanges();
  }
  getCurrentUser(userId): Observable<any> {
    return this.firestore.collection('users', ref => ref.where('uid', '==', userId)).snapshotChanges();
  }
  createUser(user) {
    return this.ref.add(user);
  }

  // for profile pic
  changeMessage(message: string) {
    this.messageSource.next(message)
  }

}
