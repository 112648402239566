import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    account_type: any;

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const array = [ "/reports-statistics/whatsapp-broadcast-report","/reports-statistics/whatsapp-report"]

            this.account_type = JSON.parse(localStorage.getItem('currentUser'));
            if(this.account_type === null) {
                this.router.navigate(['/login']);
                return false;
            }
            // console.log(JSON.parse(localStorage.getItem('currentUser')).account_type    , "acount_datatatata")
        // if(this.account_type && array.includes(state.url))

        // {
        //  console.log('Found')
        // }else{
        //  console.log("found not")
        // }
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
       
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
