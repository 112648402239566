import { Injectable } from '@angular/core';
import { Howl} from 'howler';
@Injectable({
  providedIn: 'root'
})
export class HowlerService {
  constructor() { }
  
  sound = new Howl({
    src : ['assets/receivesound.mp3'],
  });

 notificationSound = new Howl({
    src: ['assets/audio2.wav'],
  });

   notification = new Howl({
    src : ['assets/notification.mp3']
  });

  audio = new Howl({
    src : ['assets/audio.wav']
  });

  gamingLock = new Howl({
    src : ['assets/gaming-lock.wav']
  });

  interfaceAlert  = new Howl({
    src : ['assets/interface-option.wav']
  });
  longpop = new Howl({
    src : ['assets/long-pop.wav']
  });
  mixinterface = new Howl({
    src : ['assets/mixinterface.wav']
  });
  softwareInterface = new Howl({
    src : ['assets/software-interface.mp3']
  });
}
