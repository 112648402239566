export const environment = {
  production: false,
  disableConsoleOverride:true,
  PROJECT_NAME:'Sparrow',
  PROJECT_FOOTER:'converse',
  API_URL: 'https://tripfactory.callibry.com:1001/',
  AGENT_QUEUE_URL: 'https://tripfactory.callibry.com:1002/',
  RECORDING_URL :'wss://test-telephony.callibry.com/main', 
  SOCIAL_MEDIA_URL: 'https://tripfactory.callibry.com:1004/',
  TWITTER_BASE_URL: 'https://tripfactory.callibry.com:1005/',
  WHATSAPP_BASE_URL: 'https://tripfactory.callibry.com:1003/',
  FACEBOOK_BASE_URL: 'https://tripfactory.callibry.com:1004/',
  VIBER_BASE_URL: 'https://tripfactory.callibry.com:1007/',
  DOMAIN_URL: 'https://tripfactory.callibry.com',
  TELEGRAM_BASE_URL: 'https://tripfactory.callibry.com:1006/',
  SMS_URL:'https://tripfactory.callibry.com:1010',
  wsEndpoint: 'wss://test-telephony.callibry.com/main',
  ProxyUrl: 'test-proxy.callibry.com',
  ProxyPort:'7443',
  SocketIOChatUrl: 'https://tripfactory.callibry.com:9000/',
  AgentSessionSocketUrl:'wss://tripfactory.callibry.com:9889',
  voicemailurl: 'https://telephony.tripfactory.callibry.com/',
  version:'v1.0.0',
  version_status:'false',
  debugMode: false,
  LOG: (e) => '',
  ERROR: (e) => '',
  firebase: {
    apiKey: 'AIzaSyAvHtu5rrqKSZQg75R2MYqlNgGdMKy2YR4',
    projectId: 'modern-admin-8453e',
    authDomain: 'modern-admin-8453e.firebaseapp.com',
    appId: '1:300550201463:web:96a271cc309ac5aea86672'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU'
};
