import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
if (!environment.disableConsoleOverride && typeof console !== 'undefined') {
  console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  // console.info = function () {};
  // console.debug = function () {};
}else{
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
