import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { APIService } from '../_services/api.service';
import { UserService } from '../_api/user.service';
import { DatePipe } from "@angular/common";
import { ToastrService } from 'ngx-toastr';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import { environment } from '../../../src/environments/environment';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    project_url = environment.DOMAIN_URL;
    loginForm: FormGroup;
    submitted = false;
    loading = false;
    returnUrl: string;
    token: string | undefined;
    captchaStatus: any = null;
    captchaConfig: any = {
        length: 6,
        cssClass: 'custom',
        back: {
            stroke: "#ef3d39",
            solid: "#f2efd2"
        },
        font: {
            color: "#000000",
            size: "35px"
        }
    };

    constructor(
        private captchaService: NgxCaptchaService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private UserService: UserService,
        private apiService: APIService,
        private alertService: AlertService,
        public datepipe: DatePipe,
        public authService: AuthService) {
        this.token = undefined;
    }
    

    ngOnInit() {
        this.captchaService.captchStatus.subscribe((status) => {
            this.captchaStatus = status;
            if (status == false) {
            } else if (status == true) {
            }
        });
        // this.recaptchaV3Service.execute('importantAction')
        // .subscribe((token) => this.handleToken(token));
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            recaptchaReactive: ['']
        });
        if (localStorage.getItem('currentUser')) {
            this.authService.doLogout();
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        const data = {
            username: this.f.email.value,
            password: this.f.password.value,
        };
        this.apiService.login(data).subscribe((response:any) => {
            if (response['Status'] === '1') {
                // get agent roles 
                if(response['account_type'] == "Tenant"){
                    localStorage.setItem('profile_url' ,response['profile_pic'] )
                    localStorage.setItem('profile_image' , this.project_url + response['profile_pic']);
                    localStorage.setItem('lastmenuData',JSON.parse(response['role_permission']).items)
                    localStorage.setItem('tenantPermission', response['role_permission'])
                    this.UserService.setTenantPermission(response['role_permission']);

                 }
               else if (response['account_type'] == "Admin"){
                    localStorage.setItem('editAdmin',response['edit_admin']);
                    localStorage.setItem('deleteAdmin',response['delete_admin']);
                    localStorage.setItem('AdminPermission', response['role_permission']);
                    this.UserService.setAdminLogin(response['role_permission']);
                }
               else if (response['account_type'] == "Agent" || response['account_type'] == "Supervisor") {
                    localStorage.setItem('break_status', 'loggedin');
                    localStorage.setItem('profile_url' ,response['profile_pic'] )
                    localStorage.setItem('profile_image' , this.project_url + response['profile_pic']);
                    sessionStorage.setItem('selected_agent_uuid', response['agent_domain_uuid'])
                     sessionStorage.setItem('agent_break_statu',"loggedIn")
                    sessionStorage.setItem('selected_data_domain_uuid', response['domain_uuid'])
                    sessionStorage.setItem('session_auth_id', response['auth_token'])
                    sessionStorage.setItem('selected_agent_role_id', response['agent_role_uuid'])
                    // this.AgentLoginStatus('login');
                    this.apiService.getAgentRole(response['agent_role_uuid']).subscribe((res) => {
                        const roleData = res['GetAgentRoles'];
                        const data_check = roleData[0];
                        sessionStorage.setItem('superAdminData', (data_check.role_datas));
                        this.UserService.userLoginType.next(res['GetAgentRoles']);
                    });
                }
                // domainuuid
                response = { ...response, email: this.f.email.value };
                this.setUserInStorage(response);
                localStorage.removeItem('currentLayoutStyle');
                let returnUrl = '/dashboard/sales';
                if (this.returnUrl) {
                    returnUrl = this.returnUrl;
                }
                this.router.navigate([returnUrl]);
            } else {
                this.loading = false;
                this.submitted = false;
                this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
            }
        });
    }

    AgentLoginStatus(data){
        const date = new Date();
        const param = {
            domain_uuid: sessionStorage.getItem('selected_data_domain_uuid'),
            agent_uuid: sessionStorage.getItem('selected_agent_uuid'),
            login_session_id: sessionStorage.getItem('session_auth_id'),
            login_time: this.datepipe.transform(date, "yyyy-MM-ddTHH:mm:ss"),
            logout_time: "",
            session_type: "login",
            camp_uuid: ''
        }
        this.apiService.updateAgentLoginSession(param).subscribe(() => {});
    }
    setUserInStorage(res) {
        if (res.account_type === 'Tenant') {
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        } else if (res.account_type === 'Superuser') {
            localStorage.setItem('superuser_user', JSON.stringify(res));
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        } else if (res.account_type === 'Admin') {
            localStorage.setItem('superuser_user', JSON.stringify(res));
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        } else {
            localStorage.setItem('currentUser', JSON.stringify(res));
            sessionStorage.removeItem('show_superuser_login_icon');
        }
    }
}
