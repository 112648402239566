import { Component, OnInit, Renderer2, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from '../../../app/_services/navbar.service';
import { ThemeSettingsService } from '../settings/theme-settings.service';
import { MenuSettingsService } from '../settings/menu-settings.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../../../app/_helpers/app.constants';
import { APIService } from '../../../app/_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';
import { VerticalComponent } from '../../_layout/header/vertical/vertical.component';
import { UserService } from "../../../../src/app/_api/user.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
declare var outGoingCallEvent: Subject<any>;
import { AuthService } from "src/app/_services/auth.service";
import { WebsocketService } from "../../_services/websocket.service";
import { environment } from '../../../environments/environment';
import { DatePipe } from "@angular/common";
import { HowlerService } from "../../_services/howler.service";

import * as uuid from 'uuid';
declare var $: any;
declare var Telemo: any;
declare var window: any;
declare var require: any;
const colors = require('../../../assets/data/customizer/customizerColor.json');

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {
  bydefaultPhoneNo = '';
  call_status: any;
  mobileNumber: any;
  previewIntervalData: any = 0;
  preview_dialer_contact: any = [];
  job_start_time: any;
  scheule_date_day: any;
  scheule_date_month: any;
  scheule_date_year: any;
  job_end_time: any;
  disposition_uuid: any;
  disposition_callback: any;
  // disposition_dnc:any;
  showOnCallback = false;
  meridian = true;
  starttime: NgbTimeStruct = { hour: 13, minute: 30, second: 0 };
  endtime: NgbTimeStruct = { hour: 14, minute: 30, second: 0 };
  hourStep = 1;
  minuteStep = 15;
  secondStep = 30;
  endHourStep = 2;
  endMinuteStep = 15;
  endSecondStep = 30;
  externalNumberClear: any;
  externalNumberTransfer = true;
  campaignTransfer = true;
  agentTransfer = true;
  queueTransfer = true;
  ivrTransfer = true;
  transfer_icon_non_clickable = true;
  parked_call_show = true;
  UTCStartDateTime: any;
  public basicColorAccordation1 = false;
  public basicColorAccordation2 = false;
  public basicColorAccordation3 = false;
  public basicColorAccordation4 = false;
  public basicColorAccordation5 = false;

  public agentConferenceAccordian = false;
  public externalConferenceAccordian = false;
  transferAgentList: any;
  transferQueueList: any;
  transferCampaignList: any;
  transferIVRList: any;

  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public _themeCustomizerConfig: any;
  public _menuCustomizerConfig: any;
  public _themeSettingsConfig: any;
  public selectColorClass: string;
  public activebutton: any;

  public isCollapsedMenu = false;
  public isfixChecked = false;
  public isboxChecked = false;
  public isStaticLayout = false;

  public isRightSideIcons = false;
  public isBorderedNavigation = false;
  public isFlippedNavigation = false;
  public isCollapsibleNavigation = false;
  public isStaticNavigation = false;
  resumeBtn: boolean = false;
  pauseBtn: boolean = false;
  interval: any = 0;
  time: number = 0;
  public isStaticTop = false;
  telNumber = '';
  telNumberAfter = '';
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  collapsed = true;
  userType: any;
  showDialer = false;
  moduleLoading: boolean;
  allCustomerData: any;
  addCustomerForm: FormGroup;
  timerTime: any;
  getTime: any;
  outgoingGetTime: any;
  noAgentAvailable = false;
  noDataFound = false;
  dataOfUser: any;
  selectedItem: any;
  allAvailableAgentList: any;
  allAvailableParkCall: any;
  showDialBtn: boolean = false;
  holdMuteBtn: boolean = true;
  conferenceRecordBtn: boolean = true;
  callingAgentUuid: any;
  AgentAvailable = false;
  transerAgentContinousTest: any = 0;
  wrap_up_time_over: any;
  counter: any = 0;
  counter_value: any;
  // timer var start 
  currentState: String = "Default";
  calTime: any = 0;
  timerInterval: any;
  displayTime: any = "00:00:00";
  acwTimer: any = "00:00:00";
  // timer var ennds 
  acwInterval: any;
  AllWallboardData: any
  dispositionForm: FormGroup;
  currentRate = 5;
  minEndDate: string;
  dispositions: any;
  dispositionSubmitted = false;

  // Preview

  preview_time_over: any;
  preview_num: string;
  preview_id: any;
  dataReceived = false;
  previewInterval: any;
  preview_counter: any = 0;
  @ViewChild(VerticalComponent) verticalData: VerticalComponent;
  @ViewChild('previewModal') previewModal: ElementRef;
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  userPhoneNumber: any;
  xmlCdrUuid: any;
  selected_camp_data: any;
  channel_name: any;
  echoParam: any;
  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private _renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    private dataService: UserService,
    private authService: AuthService,
    private websocket: WebsocketService,
    public datepipe: DatePipe,
    private configs: NgbDatepickerConfig,
    private howlerService: HowlerService,
  ) {
    // disable past date start
    const current = new Date();
    configs.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate()
    };
    configs.outsideDays = 'hidden';
    // disable past date end

    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    outGoingCallEvent = new Subject();
    this.minEndDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit() {
    this._themeSettingsService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this._themeCustomizerConfig = config;
    });
    this._menuSettingsService.config.pipe(takeUntil(this._unsubscribeAllMenu)).subscribe((config) => {
      this._menuCustomizerConfig = config;
    });
    this._themeSettingsService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this._themeSettingsConfig = config;
    });
    setTimeout(() => {
      this.setColor('bg-gradient-x-grey-blue');
    }, 1000);
    // make the theme color select default 

    this.dataOfUser = JSON.parse(localStorage.getItem('currentUser'));
    this.listenSocketEvent();

    // permissiton related to dialer option toggle start 
    $("#dailPadConTainer").show();
    $("#hideDialPadBtn").show();
    $("#makeAudioCallBtnContainer").show();
    $("#mute_container").hide();
    this.userType = JSON.parse(localStorage.getItem('currentUser')).account_type;
    if (this.userType === 'Agent' || this.userType === 'Supervisor') {
      this.showDialer = true;
    } else {
      this.showDialer = false;
    }
    // permissiton related to dialer option toggle ends 

    // register agent on sip start from here 
    if (this.userType === 'Agent' || this.userType === 'Supervisor') {
      const telemoSdk = new Telemo();
      window.addEventListener('beforeunload', function (event) {
        telemoSdk.terminateCall();
      });
      const storedDatas = JSON.parse(localStorage.getItem('currentUser'));
      // var domainPart1 = "wss://";
      // var domainPart2 = "proxy.cexlabapps.com";
      // var domainPart3 = ":7443";
      // var domain = domainPart1.concat(domainPart2, domainPart3);
      const SipUrl = environment.ProxyUrl;
      const SipPort = environment.ProxyPort;
      const domain = "wss://" + SipUrl + ":" + SipPort;
      var options = {
        username: storedDatas['extension'],
        password: storedDatas['extension_password'],
        display_name: storedDatas['extension'],
        trace_sip: 'true',
        turnServerURL: 'turn:numb.viagenie.ca',
        turnUsername: 'hariom@startelelogic.com',
        turnPassword: 'q1w2e3r4',
        subDomain: storedDatas['domain_name']
      };
      telemoSdk.login(options, domain);
      // unregister sip 
      telemoSdk.unRegisterUser();
    }
    // register agents to sip ends from here 

    // numpad press
    setTimeout(() => {
      $('.input_container').click(function () {
        var num = $(this);
        var text = $.trim(num.find('.press_input').clone().children().remove().end().text());
        var telNumber = $('#telNumber');
        $(telNumber).val(telNumber.val() + text);
      });
    }, 2500);

    // remove outbound campaign uuid 
    sessionStorage.removeItem('get_outbound_camp_uuid');
    setTimeout(() => {
      $('.other_input_contain').click(function () {
        var num = $(this);
        var text = $.trim(num.find('.press_input').clone().children().remove().end().text());
        var telNumberAfter = $('#telNumberAfter');
        $(telNumberAfter).val(telNumberAfter.val() + text);
      });
    }, 2500);
    // status events code start from here
    this.dataService.agentReadyStateSubject.subscribe((cause) => {
      this.showAgentStatusTimer(cause);
    })
    outGoingCallEvent.subscribe((cause) => {
      this.authService.nextSubjectForCrm(cause);
      this.showAgentStatusTimer(cause);
    })
    this.dataService.previewStatusSubject.subscribe((response) => {
        this.getPreviewDialer();
    })
    // status/events code ends here
    sessionStorage.removeItem('wrapup_counter');

    this.parkedExtensionList();
  }




  // agent various status timer start from here
  showAgentStatusTimer(cause) {
    if (cause === "Ready") {
      this.currentState = "Ready";
      $("#agentStatusHead").css({ "margin-bottom": "2px" });
      this.resumeBtn = false;
      this.pauseBtn = true;

      this.resetTimer();
      this.startStatusTimer();
    } else if (cause === "Waiting") {
      this.currentState = "Waiting";
      this.resumeBtn = false;
      this.pauseBtn = true;
      $("#agentStatusHead").css({ "margin-bottom": "2px" });
      this.resetTimer();
      this.startStatusTimer();
    } else if (cause === "Progress" || cause === "Connecting" || cause === `Connecting to ${sessionStorage.getItem('outcome_caller_number')}`) {
      $("#previewDial").hide();
      $("#customerNo").css({ "display": "none" });
      this.currentState = "Ringing";
      this.AgentAvailableStatus("Ringing");
      this.pauseBtn = false;
      this.resumeBtn = false;
      const param = {
        "phone_number": sessionStorage.getItem('caller_number')
      }
      if (cause === 'Progress') {
        if (this.userPhoneNumber == undefined) {
          if (this.dataOfUser.domain_name == 'tripfactory.com') {
            this.apiService.externalCRMUrl(param).subscribe((response) => {
              if (response['Status'] == 0) {
              } else {
                window.open(response['Url'])
              }
            })
          } else {
          }

        } else {

        }

      }

      $("#agentStatusHead").css({ "margin-bottom": "7px" });
      this.resetTimer();
      this.startStatusTimer();
    } else if (cause === "Busy") {
      this.wrap_up_time_over = sessionStorage.getItem('wraup_up_time');
      $("#agentStatusHead").css({ "margin-bottom": "2px" });
      this.wrapUpTimeCounter();
      if (sessionStorage.getItem('camp_type') === "OUTBOUND" || sessionStorage.getItem('camp_type') === "BLENDED") {
        this.removeActiveCalls();
        this.currentState = "Ready";
        this.resumeBtn = false;
        this.pauseBtn = true;
      } else if (sessionStorage.getItem('camp_type') === "INBOUND") {
        this.currentState = "Waiting";
        this.resumeBtn = false;
        this.pauseBtn = true;
      }
    } else if (cause === "Terminated" || cause === "Canceled" || cause === "Not Found" || cause === "No Answer" || cause === "SIP Failure Code" || cause === "Incompatible SDP" || cause === "Rejected") {
      this.showOnCallback = false;
      this.bydefaultPhoneNo = '';
      $("#previewDial").hide();
      $("#customerNo").css({ "display": "none" });
      $("#agentStatusHead").css({ "margin-bottom": "2px" });
      sessionStorage.removeItem('camp_uuid_for_outcome');

      if (sessionStorage.getItem('camp_type') === "OUTBOUND" || sessionStorage.getItem('camp_type') === "BLENDED") {
        // this.removeActiveCalls();
        this.currentState = "Ready";
      } else if (sessionStorage.getItem('camp_type') === "INBOUND") {
        this.currentState = "Waiting";
      }
      this.wrap_up_time_over = sessionStorage.getItem('wraup_up_time');
      $("#agentStatusHead").css({ "margin-bottom": "2px" });
      this.telNumberAfter = '';
      this.AgentAvailableStatus("OnPostCall");
      if (sessionStorage.getItem('echoNumber') === '9196' || sessionStorage.getItem('monitor_ext') === '*33') {
      } else {
        this.wrapUpTimeCounter();
      }
      sessionStorage.removeItem('monitor_ext')
      sessionStorage.removeItem('echoNumber');



      // parked call start[This is used to remove parked call from Table]
      if (localStorage.getItem('park_uuid') != null) {
        const param = {
          call_park_uuid: localStorage.getItem('park_uuid')
        }
        this.apiService.deleteParkedCall(param).subscribe();
        localStorage.removeItem('park_uuid');
      } else {
      }
      this.telNumber = '';
      this.userPhoneNumber = undefined;
      // parked call end 
    }
    else if (cause === "Accepted") {
      this.resetTimer();
      this.startStatusTimer();
      this.AgentAvailableStatus("INCALL");

      $("#agentStatusHead").css({ "margin-bottom": "7px" });
      this.currentState = "On Call";
      this.pauseBtn = false;
      this.resumeBtn = false;
    } else if (cause === "Available") {
      this.resetTimer();
      this.startStatusTimer();

    }
    else if (cause === "Break") {
      this.currentState = "Break";
      this.resetTimer();
      this.startStatusTimer();
    }
  }

  removeActiveCalls() {
    const update_agent_status_data = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      domain_uuid: this.dataOfUser.domain_uuid,
    };
    this.apiService.removeActiveCalls(update_agent_status_data).subscribe((response) => { });
  }

  AgentAvailableStatus(CALL_STATUS_DATA) {
    const update_agent_status_data = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      // status: "Available",
      status: CALL_STATUS_DATA,

    };
    this.apiService.forceLogout(update_agent_status_data).subscribe((response) => { });
  }

  resetTimer() {
    clearInterval(this.timerInterval);
    this.interval = setTimeout(() => {
      this.calTime = 0;
    }, 0);
  }
  startStatusTimer() {
    this.timerInterval = setInterval(() => {
      if (this.calTime === 0) {
        this.calTime++;
      } else {
        this.calTime++;
      }
      this.displayTime = this.transform(this.calTime);
    }, 1000);
  }

  transform(value: number): string {
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;
    return (
      ("00" + hours).slice(-2) +
      ":" +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + seconds).slice(-2)
    );
  }

  wrapUpTimeCounter() {
    clearInterval(this.timerInterval);
    if (this.wrap_up_time_over != "") {
      this.loadDisposition();
      this.wrap_up_time_over -= 1;
      this.counter = this.wrap_up_time_over;
      this.acwInterval = setInterval(() => {
        this.counter = this.counter - 1;
        this.displayTime = this.counter;
        this.currentState = "ACW";
        this.pauseBtn = false;
        this.resumeBtn = false;
        $("#agentStatusHead").css({ "margin-bottom": "7px" });
        sessionStorage.setItem("wrapup_counter", this.counter);
        if (this.counter === 0) {
          this.resetTimer();
          clearInterval(this.acwInterval);
          this.afterCallStatus();

          // this.startStatusTimer();
          this.modalService.dismissAll();
          const call_disposition_data = {
            agent_uuid: this.dataOfUser.agent_domain_uuid,
            camp_uuid: sessionStorage.getItem('get_outbound_camp_uuid'),
            domain_uuid: this.dataOfUser.domain_uuid,
            disposition_cause: 'Wrapup Timeout',
            description: '',
            call_quality: '',
            acw_time: sessionStorage.getItem('wraup_up_time'),
            mobile_no: sessionStorage.getItem('outcome_caller_number'),
            xml_cdr_uuid: sessionStorage.getItem("current_call_uuid"),
            start_time: '',
            end_time: '',
            date: '',
            day: '',
            month: '',
            year: '',
            start_utc_time: '',
            ticket_status: '',
            preview: 'true'
          }
          this.apiService.storeDispositionOutcome(call_disposition_data).subscribe((response) => {
            // this.afterCallStatus();
          });
        }
      }, 1000);
    } else {
      this.afterCallStatus();
      this.modalService.dismissAll();
    }
  }

  afterCallStatus() {
    $("#agentStatusHead").css({ "margin-bottom": "2px" });
    sessionStorage.removeItem('camp_uuid_for_outcome');
    // this is used to set status of break (START)
    if (localStorage.getItem('break_status') === 'pause') {
      this.pauseBtn = false;
      this.resumeBtn = true;
      this.pauseAPI();
    } else {
      this.resumeBtn = false;
      this.pauseBtn = true;
    }
    // this is used to set status of break (END)

    if (sessionStorage.getItem('camp_type') === "OUTBOUND" || sessionStorage.getItem('camp_type') === "BLENDED") {
      this.currentState = "Ready";
      this.removeActiveCalls();
    } else if (sessionStorage.getItem('camp_type') === "INBOUND") {
      this.currentState = "Waiting"
    }
    this.startStatusTimer();
    this.AgentAvailableStatus("Available");

  }
  // agent various status timer ends from here

  // Disposition section start from here 

  dispositionChange(data) {
    const dispositionData = data.split(",")
    this.disposition_uuid = dispositionData[0];
    this.disposition_callback = dispositionData[1];
    if (this.disposition_callback === 'true') {
      this.showOnCallback = true;
      this.dispositionForm.get('schedule_date').updateValueAndValidity();
      this.dispositionForm.controls['schedule_date'].setValidators([Validators.required]);
    } else {
      this.dispositionForm.get('schedule_date').clearValidators();
      this.dispositionForm.controls['schedule_date'].setValue('');
      this.dispositionForm.get('schedule_date').updateValueAndValidity();
      this.job_start_time = '';
      this.job_end_time = '';
      this.scheule_date_day = '';
      this.showOnCallback = false;
    }
  }

  @ViewChild('longContent') longContent: any;
  loadDisposition() {
    this.dispositionForm = this.fb.group({
      dispose_message: ['', Validators.required],
      disposition_cause: ['', Validators.required],
      start_time: [''],
      end_time: [''],
      schedule_date: [''],
    });
    if (sessionStorage.getItem('get_outbound_camp_uuid') !== null || sessionStorage.getItem('get_outbound_camp_uuid') !== 'undefined') {
      this.apiService.getCampaignDisposition(sessionStorage.getItem('get_outbound_camp_uuid')).subscribe((response) => {
        if (response['Status'] === String(1)) {
          this.dispositions = (response['camp_disposition_datas']);
          this.modalService.open(this.longContent, { backdrop: 'static', keyboard: false });
        }
      });
    }
  }

  get formalert() { return this.dispositionForm.controls; }

  saveAndCall() {
    sessionStorage.getItem('Dialer_Type') === 'Preview' ? this.submitDisposition('saveCall', 'false') : this.submitDisposition('saveCall', 'true');
  }
  convertToUTC(inputDateString: string): string {
    const date = new Date(inputDateString);
    const utcDateString = this.datepipe.transform(date, 'yyyy-MM-ddTHH:mm', 'UTC');
    return utcDateString;
  }

  submitDisposition(event, preview_data) {
    this.dispositionSubmitted = true;
    if (this.dispositionForm.invalid) {
      this.toastr.warning('Please fill all mandatory fields', 'Warning!');
      return;
    }
    if (this.disposition_callback === 'true') {
      this.job_start_time = this.dispositionForm.value["start_time"].hour.toString().padStart(2, "0") + ":" + this.dispositionForm.value["start_time"].minute.toString().padStart(2, "0") + ":" + this.dispositionForm.value["start_time"].second.toString().padStart(2, "0");
      this.job_end_time = this.dispositionForm.value["end_time"].hour.toString().padStart(2, "0") + ":" + this.dispositionForm.value["end_time"].minute.toString().padStart(2, "0") + ":" + this.dispositionForm.value["end_time"].second.toString().padStart(2, "0");
      const parts = this.dispositionForm.value['schedule_date'].split('-'); // Split the input value into parts
      const [year, month, day] = parts;
      this.scheule_date_day = day;
      this.scheule_date_month = month;
      this.scheule_date_year = year;
      var camp_start_date = this.dispositionForm.value['schedule_date'];
      const data = camp_start_date + 'T' + this.job_start_time;
      this.UTCStartDateTime = this.convertToUTC(data);
    } else {
      this.job_start_time = '';
      this.job_end_time = '';
      this.scheule_date_day = '';
      this.scheule_date_month = '';
      this.scheule_date_year = '';
      this.UTCStartDateTime = '';
    }


    const totalWrapup = sessionStorage.getItem('wraup_up_time')
    const spend_time = Number(totalWrapup) - Number(this.counter);
    const call_disposition_data = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      camp_uuid: sessionStorage.getItem('get_outbound_camp_uuid'),
      domain_uuid: this.dataOfUser.domain_uuid,
      disposition_cause: this.disposition_uuid,
      description: this.dispositionForm.value['dispose_message'],
      call_quality: String(this.currentRate),
      acw_time: String(spend_time),
      mobile_no: sessionStorage.getItem('outcome_caller_number'),
      xml_cdr_uuid: sessionStorage.getItem("current_call_uuid"),
      start_time: this.job_start_time,
      end_time: this.job_end_time,
      date: this.scheule_date_day,
      day: this.scheule_date_day,
      month: this.scheule_date_month,
      year: this.scheule_date_year,
      start_utc_time: this.UTCStartDateTime,
      ticket_status: '',
      preview: preview_data,
    }
    this.apiService.storeDispositionOutcome(call_disposition_data).subscribe((response) => {
      if (response['Status'] === String(1)) {
        // this.call_status = 'Available';
        this.toastr.success(response['Msg'], 'Success!');
        this.resetTimer();
        this.modalService.dismissAll();
        this.disposition_uuid = '';
        this.disposition_callback = '';
        this.showOnCallback = false;
        this.starttime = { hour: 13, minute: 30, second: 0 };
        this.endtime = { hour: 14, minute: 30, second: 0 };
        clearInterval(this.previewInterval);
        clearInterval(this.acwInterval);

        this.afterCallStatus();
        sessionStorage.removeItem('wrapup_counter');
        if (event != 'save') {
          $("#telNumber").val(sessionStorage.getItem('outcome_caller_number'))
          setTimeout(() => {
            this.makeAudioCall();
          }, 1000)
        } else {
          $("#telNumber").val('')
        }
        this.modalService.dismissAll();
      } else {
        sessionStorage.removeItem('wrapup_counter');
        this.toastr.error(response['Msg'], 'Error!');
      }
    });
  }
  // Disposition end here
  //  get data related requestto show in dialer start from here 
  getAvailableAgent() {
    const requestDta = {
      camp_uuid: sessionStorage.getItem("get_outbound_camp_uuid")
    };
    this.apiService.getTransferAgentList(requestDta).subscribe((response) => {
      if (response['Status'] == 0) {
        this.noDataFound = true;
        this.AgentAvailable = false;
        this.noAgentAvailable = true;
      } else {
        this.noDataFound = false;
        this.AgentAvailable = true;
        this.noAgentAvailable = false;
        this.allAvailableAgentList = response['transfer_agents'];
        this.transferAgentList = this.allAvailableAgentList.agent;
        this.transferQueueList = this.allAvailableAgentList.queue;
        this.transferCampaignList = this.allAvailableAgentList.camp;
        this.transferIVRList = this.allAvailableAgentList.ivr;
      }
    });
  }
  AvailableAgentTransfer() {
    this.apiService.getAgentRole(sessionStorage.getItem('selected_agent_role_id')).subscribe((res) => {
      const roleData = res['GetAgentRoles'];
      const agent_transfer_data = roleData[0].role_datas
      const agent_transfer_features = roleData[0]
      const parked_call_data = JSON.parse(agent_transfer_data)
      parked_call_data.items
      this.parked_call_show = parked_call_data.items;
      this.parked_call_show[4].show
      if (agent_transfer_features.external_transfer === "false" && agent_transfer_features.agent_transfer === "false" && agent_transfer_features.queue_transfer === "false" && agent_transfer_features.ivr_transfer === "false" && agent_transfer_features.external_group_transfer === "false") {
        this.transfer_icon_non_clickable = false;
      } else {
        this.transfer_icon_non_clickable = true;
      }
      // if (agent_transfer_features.web_phone === "true") {
      //   $('#agent_is_registered').css({ 'display': 'block' })
      // } else {
      //   $('#agent_is_registered').css({ 'display': 'none' });
      // }
      if (agent_transfer_features.external_transfer === "true") {
        this.externalNumberTransfer = true;
      } else {
        this.externalNumberTransfer = false;
      }
      if (agent_transfer_features.agent_transfer === "true") {
        this.agentTransfer = true;
      } else {
        this.agentTransfer = false;
      }
      if (agent_transfer_features.queue_transfer === "true") {
        this.queueTransfer = true;
      } else {
        this.queueTransfer = false;
      }
      if (agent_transfer_features.ivr_transfer === "true") {
        this.ivrTransfer = true;
      } else {
        this.ivrTransfer = false;
      }
      if (agent_transfer_features.external_group_transfer === "true") {
        this.campaignTransfer = true;
      } else {
        this.campaignTransfer = false;
      }
    });
  }
  AddSelectedAgentToConference(conference_number) {
    if (conference_number != "") {
      const param = {
        domain_uuid: this.dataOfUser.domain_uuid,
        domain_name: this.dataOfUser.domain_name,
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        callie_number: this.dataOfUser.agent_id,
        caller_number: sessionStorage.getItem('outcome_caller_number'),
        to_conference_number: conference_number,
        xml_cdr_id: sessionStorage.getItem("current_call_uuid")
      }
      this.apiService.addConferenceNumberToCall(param).subscribe((response) => {
        if (response['Status'] === '1') {
          this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
          this.externalNumberClear = '';
          $("#conference-list").hide();
        } else {
          this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
        }
      });
    }
  }
  // get data related to show in dialer ends from here 

  // dialer event / toogles code start from here 
  conferenceCall() {
    $("#cnt-list").hide();
    $("#park-list").hide();
    $('#conference-list').toggle(200);
    this.getAvailableAgent();
  }
  showRecieveDialpad() {
    $("#dailPadConTainerAfterRecieved, #conferenceCall , .holdMuteBtn , .unhold_container , .unmute_container , .recording_container , #conferenceRecordBtn , .transfer_container , .mute_hold_call_containe , .hold_container , .mute_container , #mute_container , #parkCall , #holdCall , #unholdCall").toggle();
  }
  openTransferToAgent() {
    $("#conference-list").hide();
    $("#park-list").hide();
    if (this.transfer_icon_non_clickable === false) {
    } else {
      $('#cnt-list').toggle(200);
      this.getAvailableAgent();
    }
  }
  AgentTransfer(type, agent_extension, model) {
    if (agent_extension != "") {
      clearInterval(this.transerAgentContinousTest)
      const param = {
        domain_uuid: this.dataOfUser.domain_uuid,
        domain_name: this.dataOfUser.domain_name,
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        callie_number: this.dataOfUser.agent_id,
        caller_number: sessionStorage.getItem('outcome_caller_number'),
        to_transfer_number: agent_extension,
        xml_cdr_id: sessionStorage.getItem("current_call_uuid")
      }
      this.apiService.addWarmTransferCall(param).subscribe((response) => {
        if (response['Status'] === '1') {
          this.modalService.open(model, { backdrop: 'static', scrollable: true, centered: true, windowClass: 'animated fadeInDown' });
        } else {
          this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
        }
      });
    }
  }
  transferAgentCall() {
    const param = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      is_transfer: "yes"
    }
    this.apiService.updateWarmTransferCall(param).subscribe((response) => {
      if (response['Status'] === '1') {
        this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
        this.modalService.dismissAll();
        $('#cnt-list').hide();
      } else {
        this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
      }
    });
  }
  closeTransferAgent() {
    const param = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      is_transfer: "no"
    }
    this.apiService.updateWarmTransferCall(param).subscribe((response) => {
      if (response['Status'] === '1') {
        this.toastr.success(response['Msg'], 'Success!', { progressBar: true });
        this.modalService.dismissAll();
      } else {
        this.toastr.error(response['Msg'], 'Error!', { progressBar: true });
      }
    });
  }

  // parked extension list start

  parkedExtensionList() {
    const param = {
      domain_uuid: this.dataOfUser.domain_uuid
    }
    this.apiService.parkedCallExtension(param).subscribe((response) => {
      if (response['Status'] === '1') {
        this.allAvailableParkCall = response['park_ext'];
      } else {
        this.allAvailableParkCall = '';
      }
    })
  }
  openParkTransfer() {
    this.parkedExtensionList();
    $("#cnt-list").hide();
    $("#conference-list").hide();
    if (this.parked_call_show[4].show === "false") {
    } else {
      $('#park-list').toggle(200);
    }
  }

  callParkTransfer(type, agent_extension) {
    clearInterval(this.transerAgentContinousTest);
    const telemoSdk = new Telemo();
    telemoSdk.transferCall(type, agent_extension);
    $('#park-list').hide();
  }

  // parked extension list start
  pauseClick() {
    this.pauseAPI();

  }
  pauseAPI() {
    if (localStorage.getItem('break_status') === 'available') {
      const requestObject = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        break_name: 'Pause',
        domain_uuid: this.dataOfUser.domain_uuid,
        session_id: this.dataOfUser.session_id
      }
      this.apiService.requestBreak(requestObject).subscribe((response) => {
        if (response['Status'] == 1) {
          this.toastr.success('Incoming call paused.', 'Success!');
          localStorage.setItem('break_status', 'pause');
          this.resumeBtn = true;
          this.pauseBtn = false;
        }
      })
    } else {
      localStorage.setItem('break_status', 'onbreak');
      this.toastr.error('You are not in available state so you can`t pause the dialer.', 'Success!');
    }
  }
  resumeClick() {
    const requestObject = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      break_name: 'Available',
      domain_uuid: this.dataOfUser.domain_uuid,
      session_id: this.dataOfUser.session_id
    }
    this.apiService.requestBreak(requestObject).subscribe((response) => {
      if (response['Status'] == 1) {
        localStorage.setItem('break_status', 'available');
        this.toastr.success('Incoming call resume.', 'Success!');
        this.pauseBtn = true;
        this.resumeBtn = false;
      }
    })
  }
  // dialer event / toggle code ends from here 

  // webrtc features code start from here 
  makeAudioCall() {
    this.selected_camp_data = JSON.parse(sessionStorage.getItem('campaign_all_data'))
    var getOption = <HTMLInputElement>document.getElementById('avialability_check');
    const telemoSdk = new Telemo();

    var getcallInput: any;

    if (this.userPhoneNumber == undefined) {
      getcallInput = $("#telNumber").val()
    } else {
      getcallInput = this.userPhoneNumber;
    }
    sessionStorage.setItem('outcome_caller_number', getcallInput);
    sessionStorage.setItem("caller_number", getcallInput);

    if (getcallInput === undefined || getcallInput == null || getcallInput.length <= 0) {
      this.toastr.error('Please provide a Number.', 'Error!', { progressBar: true });
    } else if (getOption.innerText != "Available") {
      this.toastr.error('You are on a break', 'Error!', { progressBar: true });
    } else if (sessionStorage.getItem("media_status") != null) {
      this.toastr.error('You have selected another agent status,so you cannot make a call', 'Error!', { progressBar: true });
    }
    else {
      sessionStorage.removeItem('media_status')
      const campUUID = sessionStorage.getItem('get_outbound_camp_uuid');
      const outBoundSipTrunkUUID = sessionStorage.getItem('get_outbound_siptrunk_siptrunk_uuid');
      const agentUUID = this.dataOfUser.agent_domain_uuid;
      if (sessionStorage.getItem('get_outbound_camp_uuid') === null || sessionStorage.getItem('get_outbound_camp_uuid') === 'undefined') {
        this.toastr.error('No outbound campaign selected', 'Error!', { progressBar: true });
      } else if (sessionStorage.getItem('get_outbound_camp_uuid') !== null || sessionStorage.getItem('get_outbound_camp_uuid') !== 'undefined') {
        //  load transferable agent 
        this.AvailableAgentTransfer();

        if (this.userPhoneNumber == undefined) {
          var randomCDRUUID = uuid.v4();
          telemoSdk.audioCall(getcallInput, campUUID, outBoundSipTrunkUUID, '', agentUUID, randomCDRUUID, '', '');
        } else {
          var randomCDRUUID = this.xmlCdrUuid;
          telemoSdk.audioCall(getcallInput, campUUID, outBoundSipTrunkUUID, '', agentUUID, randomCDRUUID, this.echoParam, '');
        }

        // API for updating trunk's flag start from here
        const param = { siptrunk_uuid: outBoundSipTrunkUUID }
        this.apiService.TrunkForFlag(param).subscribe(() => { })
        // API for updating trunk's flag start end here

        sessionStorage.setItem("current_call_uuid", randomCDRUUID);
        sessionStorage.setItem('camp_uuid_for_call_tab', sessionStorage.getItem('get_outbound_camp_uuid'));
        sessionStorage.setItem("camp_uuid_for_outcome", sessionStorage.getItem('get_outbound_camp_uuid'));
        $("#terminateCall").show();
        $("#makeAudioCall").hide();
        // new controls placed below
        $("#makeAudioCallBtnContainer").hide();
        $("#dailPadConTainer").hide();
        $("#outgoingResetTimer").hide();
        $("#resetTime").hide();
        $("#outgoingResetTimer").hide();
        $("#dailPadConTainer").hide();
        this.selected_camp_data = JSON.parse(sessionStorage.getItem("campaign_all_data"))
        if (this.selected_camp_data.camp_type != 'social') {
          this.channel_name = 'voice'
        }
        if (this.selected_camp_data.ticket_status != 'false') {
          const params = {
            domain_uuid: this.dataOfUser.domain_uuid,
            camp_uuid: this.selected_camp_data.camp_uuid,
            camp_name: this.selected_camp_data.camp_name,
            ticket_camp_uuid: this.selected_camp_data.ticket_camp_uuid,
            from_id: sessionStorage.getItem('outcome_caller_number'),
            channel: this.channel_name,
            thread_id: sessionStorage.getItem("current_call_uuid"),
            customer_name: '',
            customer_email: ''
          }
          this.apiService.ticketUpdateAtCall(params).subscribe((response) => { });
        }
        this.loadRouteRandom();
      }
    }
  }

  callTransfer(type, agent_extension) {
    if (agent_extension != "") {
      const telemoSdk = new Telemo();
      telemoSdk.transferCall(type, agent_extension);
      $('#cnt-list').hide();
    } else {
    }
  }

  acceptCall() {
    this.AvailableAgentTransfer();
    const telemoSdk = new Telemo();
    telemoSdk.acceptCall();
    $("#makeAudioCall").hide();
    $("#resetTime").css("display", "block");
    this.loadRouteRandom();
  }

  holdCall() {
    const telemoSdk = new Telemo();
    telemoSdk.holdLocal();
    $("#holdCall").hide();
    $("#unholdCall").show();
  }

  callUnHold() {
    const telemoSdk = new Telemo();
    telemoSdk.holdLocal();
    $("#holdCall").show();
    $("#unholdCall").hide();
  }

  onPressDtmf(dtmf) {
    const telemoSdk = new Telemo();
    telemoSdk.sendIVRDTMF(dtmf, this.dataOfUser.domain_uuid);
  }

  terminateCall() {
    clearInterval(this.transerAgentContinousTest)
    sessionStorage.removeItem("caller_number");
    sessionStorage.removeItem('camp_uuid_for_call_tab');
    $("#makeAudioCallBtnContainer").show();
    $("#dailPadConTainer").show();
    $("#holdCall").hide();
    $("#unholdCall").hide();
    $("#muteCall").hide();
    $("#unmuteCall").hide();
    $("#parkCall").hide();
    $("#volControl").hide();
    $("#conferenceCall").hide();
    $("#recordCall").hide();
    $("#transferCall").hide();
    $("#dialpadCall").hide();
    $("#outgoingResetTimer").hide();
    $("#resetTime").hide();
    $(".mute_hold_call_container").hide();
    $(".mute_hold_call_container").removeClass("displayFlex");
    $("#mute_container").hide();
    const telemoSdk = new Telemo();
    $("#terminateCall").hide();
    telemoSdk.terminateCall();
    $("#wrapper").show();
    this.loadRouteRandom();
    $("#cnt-list").hide();
  }

  muteCall() {
    const telemoSdk = new Telemo();
    telemoSdk.muteCall();
    $("#muteCall").hide();
    $("#unmuteCall").show();
  }

  unmuteCall() {
    const telemoSdk = new Telemo();
    telemoSdk.unmuteCall();
    $("#unmuteCall").hide();
    $("#muteCall").show();
  }

  onDemandClick() {
    const telemoSdk = new Telemo();
    telemoSdk.sendIVRDTMF('*');
  }

  listenSocketEvent() {

    this.websocket.listen('click-to-call').subscribe((data) => {
      this.userPhoneNumber = data['pn'].phone_number;
      this.xmlCdrUuid = data['pn'].xml_cdr_uuid;
      this.echoParam = data['pn'].echo_param;

      this.makeAudioCall();
    });
  }


  onDemandRecord() {
    $("#recordStartCall").hide();
    $("#recordCall").show();
    const telemoSdk = new Telemo();
    telemoSdk.sendIVRDTMF('#');
  }

  // webrtc features code ends from here 

  // dialer native functanility code start from here 
  isNumber(evt: KeyboardEvent) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isNumberCall(evt: KeyboardEvent) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  backspace() {
    var input, num;
    input = document.getElementById('telNumber');
    num = input.value;
    input.value = num.slice(0, num.length - 1);
    return false;
  }

  backspaceAfteRecieved() {
    var input, num;
    input = document.getElementById('telNumberAfter');
    num = input.value;
    input.value = num.slice(0, num.length - 1);
    return false;
  }
  // dialer native functanility code ends from here 

  // load random router and open crm code start from here 
  loadRouteRandom() {
    if (this.router.url === '/agent/active-call') {
      this.router.navigateByUrl('/agent/call');
    } else if (this.router.url === '/agent/call') {
      this.router.navigateByUrl('/agent/active-call');
    } else {
      this.router.navigateByUrl('/agent/call');
    }
  }
  // load random router and open crm code ends from here 

  // theme related code start from here 
  toggleCustomizer(event) {
    $("#cnt-list").hide();
    $("#park-list").hide();
    $("#conference-list").hide();
    const element = document.getElementById('customizer');
    if (element && element.classList.contains('open')) {
      this._renderer.removeClass(element, 'open');
    } else {
      this._renderer.addClass(element, 'open');
    }
  }

  selectChangeHandler(sel: any) {
    this.selectedItem = sel.target.value;
  }

  setLayout(layout, e) {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    const mainMenu = document.getElementsByClassName('main-menu');
    if (layout === 'static' && e.currentTarget.checked === true) {
      this.staticLayout(e);
    } else if (layout === 'static' && e.currentTarget.checked === false) {
      this._themeSettingsConfig.layout.pattern = '';
      this._renderer.removeClass(headerNavbar.item(0), 'navbar-static-top');
      this._renderer.removeClass(footer, 'footer-static');
      this._renderer.addClass(headerNavbar.item(0), 'fixed-top');
      this._renderer.addClass(document.body, 'fixed-navbar');
      this._renderer.addClass(mainMenu.item(0), 'menu-fixed');
      this.isStaticLayout = false;
    }
  }

  toggleFixMenu(e) {
    const toggleIcon = document.getElementsByClassName('toggle-icon');
    if (this.document.body.classList.contains('menu-expanded') && e.currentTarget.checked === true) {
      this._themeCustomizerConfig.navbar = 'collapse';
      // show the left aside menu
      this.navbarService.setFixedMenu(false);
      this.document.body.classList.remove('menu-expanded');
      this.document.body.classList.add('menu-collapsed');
      // Change switch icon
      this._renderer.removeClass(toggleIcon.item(0), 'ft-toggle-right');
      this._renderer.addClass(toggleIcon.item(0), 'ft-toggle-left');
      this.isCollapsedMenu = true;
    } else {
      this._themeCustomizerConfig.navbar = 'expand';
      this.navbarService.setFixedMenu(true);
      this.document.body.classList.remove('menu-collapsed');
      this.document.body.classList.add('menu-expanded');
      // Change switch icon
      this._renderer.removeClass(toggleIcon.item(0), 'ft-toggle-left');
      this._renderer.addClass(toggleIcon.item(0), 'ft-toggle-right');
      this.isCollapsedMenu = false;
    }
    setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    this.resetOpenMenu();
  }

  resetOpenMenu() {
    for (let i = 0; i < this._menuCustomizerConfig.vertical_menu.items.length; i++) {
      const menu = this._menuCustomizerConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu['isOpen'] = false;
        menu['isActive'] = false;
        menu['hover'] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu['isOpen'] = false;
          menu['isActive'] = false;
          menu['hover'] = false;
          menu.submenu.items[j]['isOpen'] = false;
        }
      }
    }
  }

  changeNavbarFontColor($event: NgbTabChangeEvent) {
    const headerElement = document.getElementsByClassName('header-navbar');
    const navbarHeaderElement = document.getElementsByClassName('navbar-header');
    if ($event.nextId === 'semi-dark') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-light');
      this._renderer.removeClass(headerElement.item(0), 'navbar-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-semi-dark');
      this._themeCustomizerConfig.colorTheme = 'semi-dark';
    } else if ($event.nextId === 'semi-light') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-semi-light');
      this._themeCustomizerConfig.colorTheme = 'semi-light';
    } else if ($event.nextId === 'dark') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-light');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-dark');
      this._themeCustomizerConfig.colorTheme = 'dark';
    } else if ($event.nextId === 'light') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-light');
      this._themeCustomizerConfig.colorTheme = 'light';
    }
    this._themeSettingsService.config = {
      color: ''
    };
  }

  setMenuColor(colorClass, event) {
    const darkMenuButton = document.getElementById('dark-menu');
    const lightMenuButton = document.getElementById('light-menu');
    if (event.currentTarget.className === 'btn btn-outline-info _dark') {
      this._renderer.removeClass(lightMenuButton, 'active');
      this._renderer.addClass(darkMenuButton, 'active');
    } else if (event.currentTarget.className === 'btn btn-outline-info _light') {
      this._renderer.removeClass(darkMenuButton, 'active');
      this._renderer.addClass(lightMenuButton, 'active');
    }
    this._themeSettingsService.config = {
      menuColor: colorClass, // light-menu, dark-menu
    };
  }

  setNavigation(navigationClass, event) {
    const navigationElement = document.getElementById('main-menu');
    const element = document.getElementById('customizer');
    if (event.target.checked === true && navigationClass !== 'menu-collapsible') {
      this._themeSettingsService.config = { navigation: navigationClass };
      if (navigationClass === 'menu-flipped') {
        this._renderer.removeClass(element, 'open');
      } else if (navigationClass === 'menu-static') {
        this._renderer.removeClass(navigationElement, 'menu-fixed');
      }
    } else if (event.target.checked === false && navigationClass !== 'menu-collapsible') {
      this._themeSettingsConfig.navigation = AppConstants.NAVIGATION_TYPE_ACCORDATION;
      this._renderer.removeClass(navigationElement, navigationClass);
      this._renderer.removeClass(document.body, navigationClass);
      if (navigationClass === 'menu-static') {
        this._renderer.addClass(navigationElement, 'menu-fixed');
      }
    }
    if (event.target.checked === true && navigationClass === 'menu-collapsible') {
      this._themeSettingsConfig.navigation = AppConstants.NAVIGATION_TYPE_ACCORDATION;
      this._renderer.removeClass(navigationElement, navigationClass);
    } else if (event.target.checked === false && navigationClass === 'menu-collapsible') {
      this._themeSettingsService.config = {
        navigation: navigationClass
      };
    }
    if (navigationClass === 'menu-icon-right' && event.currentTarget.checked === true) {
      this.isRightSideIcons = true;
    } else if (navigationClass === 'menu-icon-right' && event.currentTarget.checked === false) {
      this.isRightSideIcons = false;
    }
    if (navigationClass === 'menu-bordered' && event.currentTarget.checked === true) {
      this.isBorderedNavigation = true;
    } else if (navigationClass === 'menu-bordered' && event.currentTarget.checked === false) {
      this.isBorderedNavigation = false;
    }
    if (navigationClass === 'menu-flipped' && event.currentTarget.checked === true) {
      this.isFlippedNavigation = true;
    } else if (navigationClass === 'menu-flipped' && event.currentTarget.checked === false) {
      this.isFlippedNavigation = false;
    }
    if (navigationClass === 'menu-collapsible' && event.currentTarget.checked === true) {
      this.isCollapsibleNavigation = true;
    } else if (navigationClass === 'menu-collapsible' && event.currentTarget.checked === false) {
      this.isCollapsibleNavigation = false;
    }
    if (navigationClass === 'menu-static' && event.currentTarget.checked === true) {
      this.isStaticNavigation = true;
    } else if (navigationClass === 'menu-static' && event.currentTarget.checked === false) {
      this.isStaticNavigation = false;
    }
  }

  setNavbar(event) {
    const navbarElement = document.getElementsByClassName('header-navbar');
    const navigationElement = document.getElementById('main-menu');
    if (event.target.checked === true) {
      this._renderer.removeClass(document.body, 'fixed-navbar');
      this._renderer.removeClass(navbarElement.item(0), 'fixed-top');
      this._renderer.removeClass(navigationElement, 'menu-fixed');
      this._renderer.addClass(navbarElement.item(0), 'navbar-static-top');
      this._renderer.addClass(navigationElement, 'menu-static');
      this.isStaticTop = true;
    } else if (event.target.checked === false) {
      this._renderer.removeClass(navbarElement.item(0), 'navbar-static-top');
      this._renderer.removeClass(navigationElement, 'menu-static');
      this._renderer.addClass(document.body, 'fixed-navbar');
      this._renderer.addClass(navbarElement.item(0), 'fixed-top');
      this._renderer.addClass(navigationElement, 'menu-fixed');
      this.isStaticTop = false;
    }
  }

  setColor(colorClass) {
    for (let i = 0; i <= colors.colorArray.length; i++) {
      if (colorClass === colors.colorArray[i].cssClass) {
        this.selectColorClass = colorClass;
        break;
      }
    }
    this._themeSettingsService.config = {
      color: this.selectColorClass
    };
  }

  toggleLayout(layout) {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    if (layout === 'boxed' && this.isboxChecked === false) {
      this.boxedLayout();
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    } else if (layout === 'boxed' && this.isboxChecked === true) {
      this._renderer.removeClass(headerNavbar.item(0), 'container');
      this._renderer.removeClass(headerNavbar.item(0), 'boxed-layout');
      this._renderer.removeClass(document.body, 'boxed-layout');
      this._renderer.removeClass(document.body, 'container');
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
      this._themeSettingsConfig.layout.pattern = '';
    } else if (layout === 'fixed' && this.isfixChecked === false) {
      this.fixedLayout();
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    } else if (layout === 'fixed' && this.isfixChecked === true) {
      this._renderer.removeClass(footer, 'fixed-bottom');
      this._themeSettingsConfig.layout.pattern = '';
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    }
  }

  fixedLayout() {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    this._renderer.removeClass(headerNavbar.item(0), 'container');
    this._renderer.removeClass(headerNavbar.item(0), 'boxed-layout');
    this._renderer.removeClass(document.body, 'boxed-layout');
    this._renderer.removeClass(document.body, 'container');
    this._renderer.addClass(footer, 'fixed-bottom');
    if (this.isStaticLayout === true) {
      this._renderer.addClass(headerNavbar.item(0), 'fixed-top');
      this._renderer.addClass(document.body, 'fixed-navbar');
      this._renderer.removeClass(footer, 'fixed-bottom');
    }
    this.isfixChecked = true;
    this.isboxChecked = false;
    this._themeSettingsConfig.layout.pattern = 'fixed';
  }

  boxedLayout() {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    this._renderer.removeClass(footer, 'fixed-bottom');
    this._renderer.addClass(headerNavbar.item(0), 'container');
    this._renderer.addClass(headerNavbar.item(0), 'boxed-layout');
    this._renderer.addClass(headerNavbar.item(0), 'fixed-top');
    this._renderer.addClass(document.body, 'boxed-layout');
    this._renderer.addClass(document.body, 'container');
    if (this.isStaticLayout === true) {
      this._renderer.removeClass(headerNavbar.item(0), 'fixed-top');
      this._renderer.removeClass(document.body, 'fixed-navbar');
    }
    this.isboxChecked = true;
    this.isfixChecked = false;
    this._themeSettingsConfig.layout.pattern = 'boxed';
  }

  staticLayout(e) {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    const mainMenu = document.getElementsByClassName('main-menu');
    this._renderer.removeClass(document.body, 'fixed-navbar');
    this._renderer.removeClass(headerNavbar.item(0), 'fixed-top');
    this._renderer.removeClass(mainMenu.item(0), 'menu-fixed');
    this._renderer.addClass(headerNavbar.item(0), 'navbar-static-top');
    this._renderer.addClass(footer, 'footer-static');
    this._renderer.removeClass(footer, 'fixed-bottom');
    this.isStaticLayout = true;
    this._themeSettingsConfig.layout.pattern = 'static';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.document.body.classList.contains('menu-expanded')) {
      this.collapsed = false;
    } else {
      this.collapsed = true;
    }
  }
  // theme related code ends from here 

  // preview dialer code start from here 
  getPreviewDialerContacts() {
    const params = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      camp_uuid: sessionStorage.getItem('get_outbound_camp_uuid')
    }
    this.apiService.getPreviewDialerDetails(params).subscribe((response) => {
    });
  }

  getPreviewDialer() {
    this.websocket.listen("preview-dialer-notify").subscribe((res: any) => {
      setTimeout(() => {
        // Data received, handle it
        this.preview_num = res?.customer_number;
        this.preview_time_over = JSON.parse(sessionStorage.getItem("campaign_all_data")).preview_time;
        if (this.preview_time_over !== '') {
          this.preview_id = res?.id;
          this.howlerService.softwareInterface.play();
          this.previewCounter(res?.customer_number, res?.customer_name);
          this.dataReceived = true;
        }
      }, 3000);
    }, error => {
      this.dataReceived = false;
    });

  }

  clearIntervalForPreview() {
    clearInterval(this.previewIntervalData);
  }

  previewCounter(number, name) {
    this.resetTimer();
    if (this.preview_time_over != "") {
      // this.loadDisposition();
      this.preview_time_over -= 1;
      this.preview_counter = this.preview_time_over;
      $(".customizer").addClass("open");
      $("#dailPadConTainer").hide();
      // ("#customerNo").hide();
      $("#makeAudioCallBtnContainer").hide();
      $("#previewDial").show();
      $("#outgoingResetTimer").css({
        "display": "none"
      });
      $("#callieNumber").css({ "display": "block" });
      $("#customerNo").css({ "display": "block" });
      $("#customerNo").text(name);
      $("#callieNumber").text(number);
      $("#floatingCause").text(number);
      $("#break_call_timxer").css({
        "display": "none"
      });
      $("#outgoingResetTimer").css({
        "display": "none"
      });
      this.previewInterval = setInterval(() => {
        this.preview_counter = this.preview_counter - 1;
        this.displayTime = this.preview_counter;
        this.currentState = "Preview";
        this.pauseBtn = false;
        this.resumeBtn = false;
        $("#agentStatusHead").css({ "margin-bottom": "7px" });
        sessionStorage.setItem("preview_counter", this.preview_counter);
        if (this.preview_counter === 0) {
          clearInterval(this.previewInterval);
          this.UpdatePreviewStatus('DIALED', this.preview_id);
          this.makeAudioCallForPreview(number);
        }
      }, 1000);
    } else {
      this.afterCallStatus();
    }
  }


  updatePreviewDialerContact(state) {
    this.resetTimer();
    clearInterval(this.previewInterval);
    if (state === 'SKIP') {
      $("#previewDial").hide();
      $("#customerNo").css({ "display": "none" });
      $("#callieNumber").hide();
      this.afterCallStatus();
      $("#dailPadConTainer").show();
      $("#makeAudioCallBtnContainer").show();
      this.UpdatePreviewStatus(state, this.preview_id)
    } else {
      this.UpdatePreviewStatus(state, this.preview_id)
      this.makeAudioCallForPreview(this.preview_num)
    }


  }

  UpdatePreviewStatus(state, id) {
    const spend_time = Number(JSON.parse(sessionStorage.getItem("campaign_all_data")).preview_time) - Number(this.preview_counter);
    this.apiService.updatePreviewDialerState({
      id: id,
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      state: state,
      preview_time: String(spend_time),
    }).subscribe((response) => {
    });
  }

  makeAudioCallForPreview(callback_mobile) {
    $("#previewDial").hide();
    $("#customerNo").css({ "display": "none" });

    sessionStorage.setItem('outcome_caller_number', callback_mobile);
    sessionStorage.setItem('caller_number', callback_mobile);
    const telemoSdk = new Telemo();
    const getcallInput = callback_mobile;
    if (getcallInput === undefined || getcallInput == null || getcallInput.length <= 0) {

    } else {
      const campUUID = sessionStorage.getItem("get_outbound_camp_uuid");
      const outBoundSipTrunkUUID = sessionStorage.getItem("get_outbound_siptrunk_siptrunk_uuid");
      const queueUUID = sessionStorage.getItem("outbound_campaign_queue_uuid");
      const agentUUID = this.dataOfUser.agent_domain_uuid;
      const randomCDRUUID = uuid.v4();
      sessionStorage.setItem("current_call_uuid", randomCDRUUID);
      $("#terminateCall").show();
      telemoSdk.audioCall(callback_mobile, campUUID, outBoundSipTrunkUUID, '', agentUUID, randomCDRUUID, '', 'true');
      $("#callieNumber").text(callback_mobile);
      document.getElementById("callieNumber").innerHTML = callback_mobile;
      $("#callieNumber").css({ "display": "block" });
      $("#makeAudioCall").hide();
      // }
    }
  }
  // peview dialer code ends from 
}